<template>
    <div class="loading-indicator-my">
        <svg
            class="spinner"
            viewBox="0 0 48 48"
        >
            <circle
                class="path"
                cx="24"
                cy="24"
                r="19"
                fill="none"
                stroke-width="2"
            />
        </svg>
        <h5
            class="fw-light title mt-3"
            v-html="title"
        />
    </div>
</template>

<script>
    export default {
        name: 'LoadingIndicator',
        props: {
            title: String,
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/loadingIndicator.scss';
    .loading-indicator-my {
        width: 100%;
        flex-direction: column;
        overflow: hidden;
        height: calc(100vh - 90px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        img {
            max-width: 100px;
            max-height: 100px;
        }
    }
</style>
