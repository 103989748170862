"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncomeType = void 0;
var IncomeType;
(function (IncomeType) {
    IncomeType["UNKNOWN"] = "UNKNOWN";
    IncomeType["GROSS_MONTHLY"] = "GROSS_MONTHLY";
    IncomeType["NET_MONTHLY"] = "NET_MONTHLY";
    IncomeType["NET_ANNUAL"] = "NET_ANNUAL";
    IncomeType["GROSS_ANNUAL"] = "GROSS_ANNUAL";
})(IncomeType = exports.IncomeType || (exports.IncomeType = {}));
