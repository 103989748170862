/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * because to restrict the usage of Global window storage throughtout the application
 * Default, this is just using the LocalStorage
 */
import { logger } from '@/utils/logger'

class AppStorage {
    constructor(private readonly storage: Storage) {
        this.storage = storage || window.localStorage

        /** Is storage is supported or not */
        if (!this.isSupported()) {
            throw new Error('Storage is not supported by browser!')
        }
    }

    setItem(key: string, value: string) {
        this.storage.setItem(key, value)
    }

    getItem(key: string) {
        return this.storage.getItem(key)
    }

    removeItem(key: string) {
        this.storage.removeItem(key)
    }

    clear() {
        logger.info(`clear session storage`)
        this.storage.clear()
    }

    /**
     * @description Check for storage support
     * @returns {boolean} supported
     * * */
    isSupported() {
        let supported = true

        if (!this.storage) {
            supported = false
        }

        return supported
    }
}

/**
 * Creating the instance of storage. Default will be localStorage
 * but if you want to create instance for session storage then pass window.sessionStorage as parameter
 */
const appLocalStorage = new AppStorage(window.localStorage)
const appSessionStorage = new AppStorage(window.sessionStorage)

const localStorageKey = {
    applicantId: 'applicantId',
    loanApplicationId: 'loanApplicationId',
    coreCardCustomerId: 'coreCardCustomerId',
    creditCardCustomerId: 'creditCardCustomerId',
    plaidLinkToken: 'plaidLinkToken',
    displayPhoneNumber: 'displayPhoneNumber',
    accountPhoneNumber: 'accountPhoneNumber',
    displayEmail: 'displayEmail',
    customerFullName: 'customerFullName',
    jwtToken: 'jwtToken',
    tokenId: 'token-id',
    fundingSourceSubType: 'fundingSourceSubType',
    fundingNickName: 'fundingNickName',
    autopayCategory: 'payCategory',
    maskCard: 'maskCard',
    platform: 'platform',
    completedAccounts: 'completedAccounts',
    sessionRecordingUrl: 'sessionRecordingUrl',
    routerLog: 'routerLog',
    nativeInjectionLog: 'nativeInjectionLog',
    pifContactedFriends: 'pifContactedFriends',

    // Hopefully temporary session storage keys to get OAuth working on CashOut.vue
    cashOutId: 'cashOutId',
    cashOutAmount: 'cashOutAmount',
    cashOutFeeRatio: 'cashOutFeeRatio',
    last4AccountNumber: 'last4AccountNumber',
    uploadBankStatementId: 'uploadBankStatementId',
    amortizationLoanId: 'amortizationLoanId',
    amortizationAprPercent: 'amortizationAprPercent',
    amortizationTerm: 'amortizationTerm',
    amortizationTotalMonthlyPayment: 'amortizationTotalMonthlyPayment',
    simpleInterestLoanId: 'simpleInterestLoanId',
    loanType: 'loanType',
    productType: 'productType',
    selectedPaymentOption: 'selectedPaymentOption',
    estimatedStandardCreditFirstMonthPayment: 'estimatedStandardCreditFirstMonthPayment',
    preActivationCashOutFlow: 'preActivationCashOutFlow',
    teaserPromoType: 'teaserPromoType',
    teaserPromoApr: 'teaserPromoApr',
    teaserPromoInMonths: 'teaserPromoInMonths',
}

// We should move to using this session storage key when we fulling implement HMDA
const sessionStorageKey = {
    loanApplicationId: 'loanApplicationId',
    alreadySubmittedHMDA: 'alreadySubmittedHMDA',
    uniformResidentialLoanApplication: 'uniformResidentialLoanApplication',
    uniformResidentialLoanApplicants: 'uniformResidentialLoanApplicants',
    experimentsOverrides: 'experimentsOverrides',
}

export enum platformType {
    android = 'android',
    web = 'web',
    iOS = 'iOS',
}

const saveDebugLog = (key: string, log: string) => {
    const logs = appSessionStorage.getItem(key) ? JSON.parse(appSessionStorage.getItem(key)) : []
    logs.push(log)
    appSessionStorage.setItem(key, JSON.stringify(logs))
}

export { appLocalStorage, appSessionStorage, localStorageKey, sessionStorageKey, saveDebugLog }
