<template>
    <div>
        <app-header
            :title="$t('pages.statements.title')"
            class="mobile-header"
        />
        <div class="container position-relative px-0 px-md-3">
            <small-loading-indicator v-if="loading" />
            <!-- CURRENT DUE CARD -->
            <card-block
                :title="hasRemainingBalance ? 'Payment' : ''"
                v-show="!accountIsClosed"
                class="mb-2"
                data-testid="statements-container"
            >
                <div v-if="hasRemainingBalance">
                    <!-- MINIMUM DUE -->
                    <div class="d-flex justify-content-between">
                        <p class="mb-0">
                            {{ $t('pages.statements.minimumDue') }}
                        </p>
                        <p class="fw-bold mb-0">
                            {{ minimumDue | dollar }}
                        </p>
                    </div>

                    <!-- STATEMENT BALANCE -->
                    <div class="d-flex justify-content-between">
                        <p class="mb-0">
                            {{ $t('pages.statements.StatementBalance') }}
                        </p>
                        <p class="fw-bold mb-0">
                            {{ statementRemainingBalance | dollar }}
                        </p>
                    </div>

                    <!-- DUE DATE -->
                    <div class="d-flex justify-content-between">
                        <p class="mb-0">
                            {{ $t('pages.statements.paymentDueBy') }}
                        </p>
                        <p class="fw-bold mb-0">
                            {{ paymentDueDateFormatted }}
                        </p>
                    </div>

                    <!-- AUTOPAY -->
                    <div v-if="autoPay">
                        <hr class="my-2">
                        <div class="d-flex justify-content-between mb-2">
                            <p class="mb-0">
                                {{ $t('pages.statements.autoPay') }}
                            </p>
                            <p class="fw-bold mb-0">
                                {{ autoPayCategoryString }}
                            </p>
                        </div>

                        <div class="d-flex justify-content-between text-muted">
                            <p>
                                {{ $t('pages.statements.scheduledOn') }}
                            </p>
                            <p>
                                {{ paymentDueDateFormatted }}
                            </p>
                        </div>
                    </div>

                    <!-- MAKE A PAYMENT BUTTON -->
                    <router-link
                        to="makePayment"
                        class="btn btn-primary w-100 mt-3 text-white"
                    >
                        {{ $t('pages.statements.makeAPayment') }}
                    </router-link>
                </div>
                <div
                    v-else
                    class="d-flex flex-column align-items-center text-center"
                >
                    <img
                        src="../assets/images/pages/statements/NothingDue.svg"
                        width="112"
                        height="112"
                        :alt="$t('pages.statements.nothingCurrentlyDue')"
                    >
                    <p class="fw-bold mt-1 mb-0">
                        {{ $t('pages.statements.nothingCurrentlyDue') }}
                    </p>
                    <p class="text-muted">
                        {{ $t('pages.statements.nothingDueNow', { date: nextStateDateFormatted }) }}
                    </p>
                    <!-- MAKE A PAYMENT BUTTON -->
                    <router-link
                        to="makePayment"
                        class="btn btn-primary mt-1 w-100"
                    >
                        {{ $t('pages.statements.makeAPayment') }}
                    </router-link>
                </div>
            </card-block>

            <payment-breakdown-card
                v-if="!!paymentBreakdownByPlan"
                :payment-breakdown-by-plan="paymentBreakdownByPlan"
                :total-minimum-due="minimumDue"
                class="mb-2"
            />

            <!-- Pay It Forward Card -->
            <pay-it-forward-card
                @open-pif-info-dialog="openPifInfoModal"
                v-if="payItForwardMaxAmount > 0"
                class="mb-2"
                :screen-name="PifScreen.STATEMENTS"
            />

            <!-- LIST OF STATEMENTS -->
            <card-block
                v-if="statements.length > 0"
                :title="$t('pages.statements.title')"
                class="mb-2"
            >
                <div
                    v-for="(statement, index) in statements"
                    :key="statement.id"
                    @click="handleStatementClick(statement, index)"
                    @keydown.enter="handleStatementClick(statement, index)"
                    :data-testid="`statement-item-${index}`"
                >
                    <list-row
                        :title="statement.name"
                        :show-arrow="true"
                        :submitting="index === statementToLoadIndex"
                        :ref="`statement_list_item_${index}`"
                        row-icon="navigation-menu.svg"
                        class="mx-0 px-0"
                    />
                </div>
            </card-block>
        </div>

        <!-- MODALS -->
        <modal
            :show="showErrorModal"
            :title="$t('pages.statements.viewStatementError.title')"
            @close="showErrorModal = false"
        >
            <p
                class="text-center mb-3"
                v-html="errorModalText"
            />
            <base-button
                class="mt-3"
                @click="showErrorModal = false"
                button-classes="btn btn-primary"
            >
                {{ $t('global.cta.dismiss') }}
            </base-button>
        </modal>
        <modal
            :show="showPifInfoModal"
            :title="$t('pages.payItForwardContactList.infoModalTitle')"
            @close="closePifInfoModal"
        >
            <p
                class="text-center mb-3"
                v-html="$t(`components.pifFooter.${rewardType}.content`)"
            />
            <base-button @click="closePifInfoModal">
                {{ $t('pages.payItForwardContactList.infoModalClose') }}
            </base-button>
        </modal>
    </div>
</template>

<script>
    import Header from '../components/AppHeader'
    import { DebtProductAndMetaDataSet, getStatementPdf } from '@/services/avenAppApi'
    import { avenAutopayDetails, getPaymentBreakdownByPlan } from '@/services/api'
    import modal from '@/components/Modal'
    import BaseButton from '@/components/base/BaseButton'
    import SmallLoadingIndicator from '@/components/SmallLoadingIndicator'
    import ListRow from '@/components/ListRow'
    import generic from '@/utils/generic'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import { NYC_TIMEZONE, DISPLAY_DATE_FORMAT } from '@/data/constants'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc'
    import timezone from 'dayjs/plugin/timezone'
    import { isSafari } from '@/utils/parseUserAgents'
    import { i18n } from '@/utils/i18n'
    import CardBlock from '@/components/CardBlock'
    import { AvenAccountStanding } from '@/store/overviewStore.types'
    import { useGlobalUiStore } from '@/store/globalUiStore'
    import { useOverviewStore } from '@/store/overviewStore'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import { useDocumentsStore } from '@/store/documentsStore'
    import PayItForwardCard from '@/components/PayItForwardCard.vue'
    import payItForwardMixin from '@/mixins/payItForwardMixin'
    import { PifScreen } from '@/utils/pifManager'
    import PaymentBreakdownCard from '@/components/PaymentBreakdown.vue'

    dayjs.extend(utc)
    dayjs.extend(timezone)

    export default {
        name: 'Statements',
        components: {
            PaymentBreakdownCard,
            PayItForwardCard,
            CardBlock,
            ListRow,
            BaseButton,
            'app-header': Header,
            SmallLoadingIndicator,
            modal,
        },
        mixins: [payItForwardMixin],
        data() {
            return {
                autoPay: false,
                autoPayCategory: null,
                hasFundingSource: true,
                statementToLoadIndex: null,
                showErrorModal: false,
                showPifInfoModal: false,
                errorModalText: i18n.t('pages.statements.viewStatementError.description'),
                paymentBreakdownByPlan: null,
            }
        },
        computed: {
            PifScreen() {
                return PifScreen
            },
            statements() {
                return useDocumentsStore().statements
            },
            isWebView() {
                return useDeviceInfoStore().isWebView
            },
            accountIsClosed() {
                return useOverviewStore().accountIsClosed
            },
            paymentDueDate() {
                return useOverviewStore().paymentDueDate
            },
            minimumDue() {
                return useOverviewStore().minimumDue
            },
            apr() {
                return useOverviewStore().apr
            },
            nextStatementDate() {
                return useOverviewStore().nextStatementDate
            },
            avenAccountStanding() {
                return useOverviewStore().avenAccountStanding
            },
            statementRemainingBalance() {
                return useOverviewStore().statementRemainingBalance
            },
            loading() {
                return useGlobalUiStore().loading
            },
            hasRemainingBalance() {
                return this.statementRemainingBalance > 0
            },
            hasMinimumDue() {
                return this.minimumDue > 0
            },
            isPastDue() {
                return this.avenAccountStanding === AvenAccountStanding.pastDue || this.avenAccountStanding === AvenAccountStanding.delinquent
            },
            autoPayCategoryString() {
                switch (this.autoPayCategory) {
                    case 'MINIMUM_DUE':
                        return 'Minimum Due'
                    case 'STATEMENT_BALANCE':
                        return 'Statement Balance'
                    case 'CUSTOM_AMOUNT':
                        return 'Fixed Amount'
                    default:
                        return ''
                }
            },
            paymentDueDateFormatted() {
                if (this.paymentDueDate) {
                    logger.info('convert payment due date')
                    return generic.utcToTimeZone(this.paymentDueDate, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
                }
                return ''
            },
            nextStateDateFormatted() {
                if (this.nextStatementDate) {
                    logger.info('convert next statement date')
                    return generic.utcToTimeZone(this.nextStatementDate)
                }
                return ''
            },
        },
        mounted: async function () {
            this.$logEvent('view_statements')
            logger.info(`------ Statements Mounted ------`)
            window.refreshData = this.refreshData
            await Promise.all([this.loadStatements(), this.getAutopayDetails(), this.getPayItForwardData(PifScreen.STATEMENTS), this.getPaymentBreakdown()])
        },
        methods: {
            refreshData: async function () {
                logger.log('Refreshing on screen data')
                try {
                    await useOverviewStore().updateAccountOverview({
                        debtProductAndMetaDataSet: DebtProductAndMetaDataSet.all,
                    })
                    await useDocumentsStore().getStatements()
                } catch (error) {
                    ApiErrorHandler(error)
                }
            },
            loadStatements: async function () {
                if (this.statements.length === 0) {
                    try {
                        await useDocumentsStore().getStatements()
                    } catch (error) {
                        ApiErrorHandler(error)
                    }
                }
            },
            getAutopayDetails: async function () {
                logger.info('Get autopay details')
                try {
                    const autopayResponse = await avenAutopayDetails()
                    if (!autopayResponse.data?.success) {
                        logger.info(`Autopay Error Details: ${JSON.stringify(autopayResponse.data.payload)}`)
                        return
                    }
                    logger.info(`Get autopay details response.data ${JSON.stringify(autopayResponse.data)}`)
                    this.autoPay = autopayResponse.data.payload.autopayStatus
                    this.autoPayCategory = autopayResponse.data.payload.autopayCategory
                } catch (error) {
                    ApiErrorHandler(error)
                }
            },
            getPaymentBreakdown: async function () {
                logger.info('Get payment breakdown by plan')
                const paymentBreakdownResponse = await getPaymentBreakdownByPlan()
                if (!paymentBreakdownResponse.data.success) {
                    logger.info(`Payment breakdown error details: ${JSON.stringify(paymentBreakdownResponse.data.error)}`)
                    return
                }
                logger.info(`Received payment breakdown details: ${JSON.stringify(paymentBreakdownResponse.data.payload)}`)
                this.paymentBreakdownByPlan = paymentBreakdownResponse.data.payload
            },
            handleStatementClick: async function (selectedStatement, index) {
                if (process.env.VUE_APP_NODE_ENV !== 'production') {
                    logger.info(`selected statement ${JSON.stringify(selectedStatement)}, make sure it is in aven_statement table and the actual file is on s3`)
                }
                this.$logEvent('event_selected_statement', selectedStatement)
                if (this.isWebView) {
                    this.viewStatementInMobileApp(selectedStatement)
                } else {
                    // only show spinner when running in browser
                    this.statementToLoadIndex = index
                    logger.info(`mSite requested statement ${selectedStatement.path}`)
                    await this.showStatement(selectedStatement)
                }
            },
            showStatement: async function (statement) {
                let response
                try {
                    logger.info(`Show Statement ${statement.name}, ${statement.id}`)
                    response = await getStatementPdf(statement.id)
                    this.errorModalText = i18n.t('pages.statements.viewStatementError.description')
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {
                        type: 'application/pdf',
                    })
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file)
                    this.statementToLoadIndex = null
                    logger.info(`File URL: ${fileURL}`)
                    if (isSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                } catch (error) {
                    this.statementToLoadIndex = null
                    this.showErrorModal = true
                    this.errorModalText =
                        error.response.status === 422 ? i18n.t('pages.statements.viewStatementError.unavailable', { name: statement.name }) : i18n.t('pages.statements.viewStatementError.description')
                }
            },
            viewStatementInMobileApp: function (selectedStatement) {
                logger.info(`viewStatementInMobileApp, ${JSON.stringify(selectedStatement)}`)
                let statementPath = `mobileApp/statement?statementId=${selectedStatement.id}&name=${selectedStatement.name}`
                logger.info(`mobileApp webView, requested statement ${statementPath}`)
                window.location.href = statementPath
            },
            openPifInfoModal: function () {
                this.showPifInfoModal = true
            },
            closePifInfoModal: function () {
                this.showPifInfoModal = false
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appHeader';
</style>
