import startCase from 'lodash/startCase'
import { logger } from '@/utils/logger'
import floor from 'lodash/floor'
import round from 'lodash/round'
import ceil from 'lodash/ceil'
import { hasFourDigits } from '@/utils/text'

export const toFormattedUSD = function (number: number): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)
}

export const toFormattedUSDStripTrailingZeroCents = (number: number): string => {
    let stringNum = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)

    stringNum = stringNum.replace(/\.00$/, '')

    return stringNum
}

export const toFormattedUSDNoCents = function (number: number): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number)
}

export const toFormattedUSDKs = function (number: number): string {
    return `$${number / 1000}K`
}

export const capitalize = function (value = ''): string {
    return startCase(value.toLowerCase())
}

// Todo This should just be toFormattedPercentStripTrailingZerosAfterDecimalPoint.
//  It is more general than just APRs.
export const toFormattedAprStripTrailingZerosAfterDecimalPoint = function (value: number): string {
    const stringNum = parseFloat(String(value * 100)).toFixed(2)
    if (stringNum.endsWith('.00')) {
        return stringNum.slice(0, stringNum.length - 3) + '%'
    }
    if (/\..0$/.test(stringNum)) {
        // match XX.X0 to drop the last 0
        return stringNum.slice(0, stringNum.length - 1) + '%'
    }
    return stringNum + '%'
}

// Please keep it consistent with the backend
// https://github.com/heraclescorp/heracles/blob/5607e5c6b0d0323da2b633a449edeb8afd2227fc/aven_backend/src/util/nameFormat.ts#L1
export const toTitleCase = (str: string): string => {
    const titledCase = str.replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    // Specifically don't capitalize the possessive `'S` (as in `Margo'S`). Could handle in
    return titledCase.replace(/\w'S(?=\s|$)/g, (txt) => txt.substr(0).toLowerCase())
}

// Use when creating a new payment source. To display the payment source nickname use displayPaymentSourceNickName
export const computedNewPaymentSourceNickName = function (nickName, accountType, accountNumber): string {
    const nickNameIsTruth = !!nickName
    const coalescedNickname = nickNameIsTruth ? nickName : `${startCase(accountType?.toLowerCase())} (${accountNumber?.slice(-4)})`
    return capitalize(coalescedNickname)
}

// Use when displaying a payment source. To create a new payment source nickname use computedNewPaymentSourceNickName
export const displayPaymentSourceNickName = function (nickName, accountType, accountNumber): string {
    const nickNameIsTruth = !!nickName
    if (nickNameIsTruth) {
        return hasFourDigits(nickName) ? nickName : `${nickName} (${accountNumber?.slice(-4)})`
    }

    return capitalize(`${startCase(accountType?.toLowerCase())} (${accountNumber?.slice(-4)})`)
}

export const formatMonthsToYearsAndMonths = function (numOfMonths: number): string {
    const years = Math.floor(numOfMonths / 12)
    const remainingMonths = numOfMonths % 12
    if (years > 0 && remainingMonths > 0) {
        return `${years} Year${years > 1 ? 's' : ''} ${remainingMonths} Month${remainingMonths > 1 ? 's' : ''}`
    } else if (years > 0) {
        return `${years} Year${years > 1 ? 's' : ''}`
    } else {
        // remainingMonths > 0
        return `${remainingMonths} Month${remainingMonths > 1 ? 's' : ''}`
    }
}

export const toFormattedNumberStripTrailingZerosAfterDecimalPoint = (value: number): string => {
    let stringNum = value.toString()
    if (value % 1 === 0) {
        return stringNum
    }
    const stringNumLength = stringNum.length
    for (let i = stringNumLength - 1; i >= 0; i--) {
        const theChar = stringNum.charAt(i)
        // The last char is a 0, so slice it off
        if (theChar === '0') {
            stringNum = stringNum.slice(0, -1)
        }
        // The last char is decimal point, so slice it off, then we're done truncating!
        else if (theChar === '.') {
            stringNum = stringNum.slice(0, -1)
            break
        }
        // The last char is a non-zero number, meaning we're done truncating!
        else {
            break
        }
    }
    return stringNum
}

export const toPrettyPhoneNumber = (value: number, delimiter: string = ' ') => {
    const cleaned = ('' + value).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        const intlCode = match[1] ? '1 ' : ''
        return [intlCode, '(', match[2], `)${delimiter}`, match[3], `${delimiter}`, match[4]].join('')
    } else {
        return value
    }
}

export const formatApr = (value): string => {
    return `${parseFloat(String(value * 100)).toFixed(2)}%`
}

export default {
    methods: {
        toThousands: function (val: number): string {
            return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        toFormattedUSD,
        toFormattedUSDStripTrailingZeroCents,
        toFormattedUSDNoCents,
        toFormattedUSDKs,
        formatApr,
        toPrettyPhoneNumber,
        toFormattedAprStripTrailingZerosAfterDecimalPoint,
        capitalize,
        toTitleCase,
        last4Chars(value = '') {
            return value.substr(value.length - 4)
        },
        // Make sure this one is exactly the same with backend!!
        // https://github.com/heraclescorp/heracles/blob/ad756aec4a39f1c606c369eaf40d3b9ccea97598/aven_backend/src/util/numberFormatting.ts#L20
        round(value: number, numberOfDecimals: number): number {
            const formattedNumber = round(value, numberOfDecimals)
            logger.info(`round(${value}) = ${formattedNumber}`)
            return formattedNumber
        },
        // https://github.com/heraclescorp/heracles/blob/ad756aec4a39f1c606c369eaf40d3b9ccea97598/aven_backend/src/util/numberFormatting.ts#L20
        floor(value: number, numberOfDecimals: number): number {
            const formattedNumber = floor(value, numberOfDecimals)
            logger.info(`floor(${value}) = ${formattedNumber}`)
            return formattedNumber
        },
        ceil(value: number, numberOfDecimals: number): number {
            const formattedNumber = ceil(value, numberOfDecimals)
            logger.info(`ceil(${value}) = ${formattedNumber}`)
            return formattedNumber
        },
        computedNewPaymentSourceNickName,
        displayPaymentSourceNickName,
        formatMonthsToYearsAndMonths,
    },
}
