"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardImageId = void 0;
/**
 * Defines the CardImageIDs we send to CoreCard such that the cardholder gets different types of
 * cards.
 * @TranslateToPython
 */
var CardImageId;
(function (CardImageId) {
    /**
     * There is no ID for the default plastic card; we just don't sent the CardImageID
     * attribute w/ card creation/replacement requests.
     */
    CardImageId["CORE_CARD_BASIC_PLASTIC_CARD_IMAGE_ID"] = "";
    /** The metal card */
    CardImageId["CORE_CARD_METAL_CARD_CARD_IMAGE_ID"] = "41127503";
})(CardImageId = exports.CardImageId || (exports.CardImageId = {}));
