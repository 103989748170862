import { logger } from '@/utils/logger'
import assert from 'assert'
import { generateFingerprint } from '@/utils/fingerprint'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { DeviceCapabilities } from '@/utils/deviceCapabilities'

// Keep in sync with aven_backend/src/entity/aven/avenDevice.ts
export enum AvenDeviceRuntimeType {
    ANDROID = 'android',
    IOS = 'ios',
    WEB = 'web',
}

export type DeviceCapabilitiesMap = { [key in DeviceCapabilities]: any }

export interface DeviceInfo {
    deviceGuid: string | undefined
    deviceName: string | undefined
    deviceModel: string | undefined
    browser: string | undefined
    resolution: string | undefined
    os: string | undefined
    appVersion: string | undefined
    runtimeType: AvenDeviceRuntimeType | undefined
    platform: string | undefined
    deviceCapabilities: DeviceCapabilitiesMap | undefined
    webHasAskedForPushPermission: boolean
    isWebView: boolean
    isSingleWebView: boolean
}

export const getDeviceInfo = async (): Promise<DeviceInfo> => {
    const deviceInfoStore = useDeviceInfoStore()
    if (deviceInfoStore.deviceGuid) {
        logger.info(`got valid deviceInfo from vuex store, ${JSON.stringify(deviceInfoStore.$state)}`)
        return deviceInfoStore.$state
    }

    const deviceGuid = await generateFingerprint()
    assert(deviceGuid, 'call getDeviceInfo after deviceGuid has been set')
    return {
        deviceGuid: deviceGuid,
        // DO NOT CHANGE! Backend hack to determine whether a session is from a browser
        // or mobile app uses the deviceName field.
        deviceName: 'N/A',
        deviceModel: window.navigator.platform,
        browser: window.navigator.userAgent,
        resolution: `${window.screen.availWidth} x ${window.screen.availHeight}`,
        os: getOS(),
        appVersion: process.env.VUE_APP_VERSION,
        runtimeType: AvenDeviceRuntimeType.WEB,
        platform: 'browser',
        deviceCapabilities: deviceInfoStore.deviceCapabilities,
        webHasAskedForPushPermission: deviceInfoStore.webHasAskedForPushPermission,
        isWebView: deviceInfoStore.isWebView,
        isSingleWebView: deviceInfoStore.isSingleWebView,
    }
}

export const getOS = (): string => {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = 'unidentified'
    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
    }
    logger.info(`Device OS: ${os}`)
    return os
}

export const isSmallScreen = (): boolean => {
    return window.innerWidth < 500
}
