"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GOOGLE_TAG_MANAGER_EVENT_NAME = void 0;
var GOOGLE_TAG_MANAGER_EVENT_NAME;
(function (GOOGLE_TAG_MANAGER_EVENT_NAME) {
    GOOGLE_TAG_MANAGER_EVENT_NAME["VIEW_PREQUAL"] = "viewPrequal";
    GOOGLE_TAG_MANAGER_EVENT_NAME["VIEW_AUTO_PREQUAL"] = "viewAutoPrequal";
    GOOGLE_TAG_MANAGER_EVENT_NAME["ACCEPT_PREQUAL_OFFER"] = "acceptPrequalOffer";
    GOOGLE_TAG_MANAGER_EVENT_NAME["VIEW_FINAL_OFFER"] = "viewFinalOffer";
    GOOGLE_TAG_MANAGER_EVENT_NAME["ACCEPT_FINAL_OFFER"] = "acceptFinalOffer";
    GOOGLE_TAG_MANAGER_EVENT_NAME["PRIMARY_CREDIT_CARD_CREATED"] = "primaryCreditCardCreated";
    GOOGLE_TAG_MANAGER_EVENT_NAME["SCHEDULED_NOTARIZATION"] = "scheduledNotarization";
    GOOGLE_TAG_MANAGER_EVENT_NAME["APP_OFFER_SUBMIT_PHONE_NUMBER"] = "appOfferSubmitPhoneNumber";
    GOOGLE_TAG_MANAGER_EVENT_NAME["AVEN_CASH_PQ"] = "avenCashPQ";
    GOOGLE_TAG_MANAGER_EVENT_NAME["VIEW_PREQUAL_COMPARE_HELOC_RATES"] = "viewPrequalCompareHelocRates";
    GOOGLE_TAG_MANAGER_EVENT_NAME["SUBMIT_AVEN_ADVISOR_HOMEOWNER_PHONE_NUMBER"] = "submitAvenAdvisorHomeownerPhoneNumber";
})(GOOGLE_TAG_MANAGER_EVENT_NAME = exports.GOOGLE_TAG_MANAGER_EVENT_NAME || (exports.GOOGLE_TAG_MANAGER_EVENT_NAME = {}));
