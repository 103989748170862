"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaidFlowEnum = void 0;
var PlaidFlowEnum;
(function (PlaidFlowEnum) {
    PlaidFlowEnum["CASHOUT"] = "CASHOUT";
    PlaidFlowEnum["INCOME_VERIFICATION"] = "INCOME_VERIFICATION";
    PlaidFlowEnum["ADVISOR_NETWORTH"] = "ADVISOR_NETWORTH";
    PlaidFlowEnum["ADVISOR_NETWORTH_RECONNECT"] = "ADVISOR_NETWORTH_RECONNECT";
    PlaidFlowEnum["ASSET_VERIFICATION"] = "ASSET_VERIFICATION";
})(PlaidFlowEnum = exports.PlaidFlowEnum || (exports.PlaidFlowEnum = {}));
