"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SweepstakesSocketEventType = void 0;
var SweepstakesSocketEventType;
(function (SweepstakesSocketEventType) {
    SweepstakesSocketEventType["BroadcastChat"] = "sweepstakes-broadcast-chat";
    SweepstakesSocketEventType["ReceiveChat"] = "sweepstakes-receive-chat";
    SweepstakesSocketEventType["EnterLobby"] = "sweepstakes-enter-lobby";
    SweepstakesSocketEventType["LeaveLobby"] = "sweepstakes-leave-lobby";
    SweepstakesSocketEventType["ReceiveCandidates"] = "sweepstakes-receive-candidates";
    SweepstakesSocketEventType["ReceiveStatus"] = "sweepstakes-receive-status";
    SweepstakesSocketEventType["BroadcastPlayerCount"] = "sweepstakes-broadcast-player-count";
    SweepstakesSocketEventType["ReceivePlayerCount"] = "sweepstakes-receive-player-count";
})(SweepstakesSocketEventType = exports.SweepstakesSocketEventType || (exports.SweepstakesSocketEventType = {}));
