<template>
    <header>
        <div
            class="container"
            data-testid="app-header-title"
        >
            <h3 class="heading-3 mb-0">
                {{ title }}
            </h3>
            <div class="d-flex align-items-center">
                <div
                    v-if="payItForwardMaxAmount > 0 && rewardType === 'classicPif'"
                    @click="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    @keydown.enter="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    class="pay-it-forward-btn text-btn-shared"
                >
                    Share {{ payItForwardAmount }}
                </div>
                <img
                    v-if="!useProminentHelpButton"
                    class="help-btn"
                    src="@/assets/images/global/Help.svg"
                    width="24"
                    height="24"
                    @click="showSupportPage"
                    @keydown.enter="showSupportPage"
                    alt="Help"
                    data-testid="button-help-icon-header"
                >
                <div
                    v-else
                    @click="showSupportPage"
                    @keydown.enter="showSupportPage"
                    class="help-text-btn text-btn-shared"
                    data-testid="button-help-text-header"
                >
                    Help
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import zendeskMixin from '@/mixins/zendeskMixin'
    import goToPayItForward, { GoToPayItForwardCaller } from '@/mixins/goToPayItForward'
    import format from '@/mixins/format'
    import payItForwardMixin from '@/mixins/payItForwardMixin'
    import { PifScreen } from '@/utils/pifManager'
    import { RouteNames } from '@/routes/router.types'
    import { EXPERIMENT_SPEC_GROUP_NAME, EXPERIMENT_TYPE, isExperimentEnabled } from '@/experiments/getOverrideForExperiment'

    const pathToLabel = [
        {
            name: RouteNames.STATEMENTS,
            label: 'statements',
        },
        {
            name: RouteNames.REWARDS,
            label: 'rewards',
        },
        {
            name: RouteNames.CARD,
            label: 'activity',
        },
        {
            name: RouteNames.ACTIVITY,
            label: 'activity',
        },
    ]

    export default {
        // TODO: This is a reserved component name. Please rename it.
        // eslint-disable-next-line vue/no-reserved-component-names
        name: 'Header',
        mixins: [zendeskMixin, goToPayItForward, format, payItForwardMixin],
        props: {
            title: {
                type: String,
                required: true,
            },
        },
        async mounted() {
            await this.getPayItForwardData(PifScreen.HEADER)
        },
        data() {
            return {
                showingSupport: true,
            }
        },
        computed: {
            GoToPayItForwardCaller() {
                return GoToPayItForwardCaller
            },
            label: function () {
                const obj = pathToLabel.find((o) => o.name === this.$route.name)
                return obj ? obj.label : ''
            },
            payItForwardAmount: function () {
                return this.toFormattedUSDNoCents(this.payItForwardMaxAmount)
            },
            useProminentHelpButton: function () {
                return isExperimentEnabled(EXPERIMENT_TYPE.support, EXPERIMENT_SPEC_GROUP_NAME.avenAppEnableProminentHelpButtons)
            },
        },
        methods: {
            showSupportPage: async function () {
                this.$logEvent('click_button_aven_my_help', { abAssignment: this.useProminentHelpButton })
                return await this.$router.push({ path: '/support', query: { label: this.label } })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appHeader';
</style>
