import { defineStore } from 'pinia'
import { logger } from '@/utils/logger'
import { initializeSessionRecording } from '@/utils/crashlyticsUtils'
import { sessionId, SessionIdPayload } from '@/services/avenAppApi'
import { DeviceInfo } from '@/utils/deviceUtils'
import { useSessionStorage } from '@vueuse/core'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { useClickTrackingStore } from '@/store/clickTrackingStore'
import { openReplay } from '@/utils/openReplay'

export const useSessionStore = () => {
    const sessionStore = defineStore('session', {
        state: () => {
            return {
                sessionId: useSessionStorage<string | null>('sessionId', null),
                sessionAccessJWT: useSessionStorage<string | null>('sessionAccessJWT', null),
                sessionRequestInProgress: false,
            }
        },
        getters: {
            hasSession: (state): boolean => {
                return !!state.sessionId && !!state.sessionAccessJWT
            },
        },
        actions: {
            async inflateSession(sessionId: string, sessionAccessJWT: string, deviceInfo: DeviceInfo, creditCardCustomerId?: number) {
                this.$patch({
                    sessionId,
                    sessionAccessJWT,
                })
                logger.info(`Stored new session with sessionId: ${sessionId}`)
                await useClickTrackingStore().maybeTryTrackClick()
                const userTraits = {
                    ...deviceInfo,
                    creditCardCustomerId,
                }
                initializeSessionRecording(sessionId, userTraits)
            },
            async beginSession() {
                if (this.sessionRequestInProgress) {
                    logger.info(`Session request already in progress, skipping duplicate request`)
                    return
                }
                this.sessionRequestInProgress = true
                const log: string[] = []
                try {
                    const deviceInfoStore = useDeviceInfoStore()
                    const sessionIdPayload: SessionIdPayload = {
                        deviceGuid: deviceInfoStore.deviceGuid,
                        deviceName: deviceInfoStore.deviceName,
                        deviceModel: deviceInfoStore.deviceModel,
                        browser: deviceInfoStore.browser,
                        resolution: deviceInfoStore.resolution,
                        os: deviceInfoStore.os,
                        appVersion: deviceInfoStore.appVersion,
                        runtimeType: deviceInfoStore.runtimeType,
                        platform: deviceInfoStore.platform,
                    }
                    log.push(`Preparing to get Session w/ payload: ${JSON.stringify(sessionIdPayload)}`)
                    const response = await sessionId(sessionIdPayload)
                    log.push(`Get session response.data: ${JSON.stringify(response.data)}`)
                    if (response.data.success && response.data.payload.sessionId) {
                        const deviceInfo = deviceInfoStore.$state
                        logger.info(`Inflating Aven My session with device info ${JSON.stringify(deviceInfo)}`)
                        await this.inflateSession(response.data.payload.sessionId, response.data.payload.sessionAccessJWT, deviceInfo)
                    }
                    logger.info(`Session bootstrap log: ${log.map((l, i) => `${i}: ${l}`).join('\n')}`)
                } catch (error) {
                    logger.error(`Error beginning session: ${error.message}`, error)
                    throw error
                } finally {
                    this.sessionRequestInProgress = false
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(sessionStore)
    return sessionStore
}

// This is exposed so our Playwright tests can determine whether the app has retrieved a session
window.setSession = (session: { sessionId: string; sessionAccessJWT: string }, deviceInfo: DeviceInfo): void => {
    useSessionStore()
        .inflateSession(session.sessionId, session.sessionAccessJWT, deviceInfo)
        .then(() => logger.info(`Session inflated via window.setSession()`))
        .catch((e) => logger.fatal(`Error inflating session via window.setSession()`, e))
    return
}

// This is exposed so our Playwright tests can determine whether the app has retrieved a session
window.hasSession = (): boolean => {
    return useSessionStore().hasSession
}
