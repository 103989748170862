// Set logging prototype as soon as possible
import Vue from 'vue'
import { logger } from '@/utils/logger'

Vue.prototype.$logger = logger // we add logger to the prototype so that we can access it in aven_shared
Vue.prototype.$logEvent = window.logEvent

import { i18n } from './utils/i18n'
import './utils/validation'
import './utils/exception-handler'
import './utils/filters'
import VueMask from 'v-mask'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './routes/router'
import './utils/validation'
import { ValidationProvider } from 'vee-validate'
import './utils/exception-handler'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import { DeviceInfo } from '@/utils/deviceUtils'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { initGooglePlacesClient } from '@/services/googlePlaces'
import { PlaidLinkOptions } from 'react-plaid-link/src/types'

dayjs.extend(isSameOrAfter)

Vue.component('./component/BaseButton')

// we need to register this component globally so that we can use it in aven_shared templates
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

Vue.use(VueCookies)

Vue.use(VueMask, {
    // This allows us to use the 'X' character as a regular char.
    // https://github.com/probil/v-mask#default-placeholders
    placeholders: {
        X: null,
    },
})

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueRouter)

declare global {
    interface Window {
        handleDeepLinkFromNative: any | undefined
        updateDeviceCapabilities: any | undefined
        logEvent: any
        previousPath: string
        zE: any | undefined
        // This will be set when we're in prerender mode
        prerender: any
        // This is the android/iOS native bridge (if available)
        native: any | undefined
        // This is exposed so our Playwright tests can login w/ out using the UI then set a session in our sessionStore
        setSession: (session: { sessionId: string; sessionAccessJWT: string }, deviceInfo: DeviceInfo) => void
        // This is exposed so our Playwright tests can determine whether the app has retrieved a session
        hasSession: () => boolean
        plaidOptions: PlaidLinkOptions
    }
}

new Vue({
    router,
    i18n,
    pinia,
    render: (h) => h(App),
}).$mount('#app')

if (!window.prerender) {
    initGooglePlacesClient()
}
