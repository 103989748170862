<template>
    <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        :class="show ? 'show' : ''"
        :style="{ display: show ? 'block' : 'none' }"
    >
        <div
            class="modal-dialog modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <img
                        v-show="!disableClose"
                        class="close"
                        src="../assets/images/global/close.svg"
                        data-dismiss="modal"
                        @click="$emit('close')"
                        @keydown.enter="$emit('close')"
                        :alt="$t('accessibility.close')"
                    >
                    <h5 class="title">
                        {{ title }}
                    </h5>
                </div>
                <div class="modal-body pt-1">
                    <slot />
                </div>
            </div>
        </div>
    </div>
    <!-- NOTE: modal-backdrop is in App.vue to maintain scrollability on desktop -->
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            title: { type: String },
            show: { type: Boolean, default: false },
            disableClose: { type: Boolean, default: false },
        },
        destroyed: function () {
            // if a user times out, we want to remove these classes on destroyed
            document.body.classList.remove('modal-open')
            document.getElementById('modalBackdrop').classList.remove('show')
            document.getElementById('modalBackdrop').classList.remove('d-block')
        },
        watch: {
            show: function (value) {
                if (value) {
                    document.body.classList.add('modal-open')
                    document.getElementById('modalBackdrop').classList.add('show')
                    document.getElementById('modalBackdrop').classList.add('d-block')
                } else {
                    document.body.classList.remove('modal-open')
                    document.getElementById('modalBackdrop').classList.remove('show')
                    document.getElementById('modalBackdrop').classList.remove('d-block')
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/modal';
</style>
