<template>
    <card-block
        v-if="isCardLoaded"
        :title="$t('pages.balanceTransfer.title')"
        class="mb-2"
    >
        <!-- Show top 2 options -->
        <balance-transfer-instant-options
            :instant-balance-transfer-options="instantBalanceTransferOptions.slice(0, 2)"
            @selectOption="handleSelectLiability"
        />

        <div
            v-if="instantBalanceTransferOptions.length > 2"
            class="text-center"
        >
            <button
                @click="handleClickShowMore"
                class="fw-bold show-more-btn"
            >
                {{ $t('components.balanceTransfer.instantSelect.showMore') }}
            </button>
        </div>
    </card-block>
</template>

<script>
    import { logger } from '@/utils/logger'
    import BalanceTransferInstantOptions from '@/components/BalanceTransferInstantOptions.vue'
    import { RouteNames } from '@/routes/router.types'
    import { useOverviewStore } from '@/store/overviewStore'
    import CardBlock from '@/components/CardBlock.vue'
    import { usePersonalFinancialLiabilityStore } from '@/store/personalFinancialLiabilityStore'

    export default {
        name: 'BalanceTransferCard',
        components: { CardBlock, BalanceTransferInstantOptions },
        data: function () {
            return {
                isCardLoaded: false,
            }
        },
        computed: {
            instantBalanceTransferOptions() {
                return usePersonalFinancialLiabilityStore().instantBalanceTransferOptions
            },
            canShowInstantBalanceTransferOptionsCard() {
                return useOverviewStore().canShowInstantBalanceTransferOptionsCard
            },
        },
        watch: {
            canShowInstantBalanceTransferOptionsCard: async function (newValue) {
                logger.info(`canShowInstantBalanceTransferOptionsCard changed to ${JSON.stringify(newValue)}`)
                if (newValue?.canShow) {
                    await this.loadCard()
                } else {
                    logger.info(`not loading instant balance transfer card: ${JSON.stringify(newValue)}`)
                }
            },
        },
        mounted: async function () {
            const canShowInstantBtOptionsCard = this.canShowInstantBalanceTransferOptionsCard
            if (canShowInstantBtOptionsCard.canShow) {
                logger.info(`Showing instant balance transfer card: ${JSON.stringify(canShowInstantBtOptionsCard)}`)
                await this.loadCard()
            } else {
                logger.info(`Not showing instant balance transfer card: ${JSON.stringify(canShowInstantBtOptionsCard)}`)
            }
        },
        methods: {
            loadCard: async function () {
                if (this.isCardLoaded) {
                    logger.info(`already loaded balance transfer card`)
                    return
                }

                await usePersonalFinancialLiabilityStore().getInstantBalanceTransferOptions()

                if (this.instantBalanceTransferOptions.length === 0) {
                    logger.info(`no available instant balance transfer options`)
                    return
                }

                this.isCardLoaded = true
                this.$logEvent('view_balance_transfer_card')
            },
            handleSelectLiability: function (selectionId) {
                this.$router.push({
                    name: RouteNames.BALANCE_TRANSFER_REQUEST,
                    params: {
                        viewingInstantBTScreen: true,
                        initialSelectionId: selectionId,
                    },
                })

                this.$logEvent('click_balance_transfer_card_select', { selectionId })
            },
            handleClickShowMore: function () {
                this.$router.push({
                    name: RouteNames.BALANCE_TRANSFER_REQUEST,
                    params: {
                        viewingInstantBTScreen: true,
                    },
                })

                this.$logEvent('click_balance_transfer_card_show_more')
            },
        },
    }
</script>

<style lang="scss" scoped>
    .show-more-btn {
        color: $black;
        text-decoration: none;
        background: none;
        border: none;
        padding: 0;
        font-weight: 300;
    }
</style>
