import { NATIVE_MESSAGES, sendMessageToNativeApp } from '@/utils/sharedLogic'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { AvenDeviceRuntimeType } from '@/utils/deviceUtils'

export const isIOS = () => {
    const runtimeType = useDeviceInfoStore().runtimeType
    return runtimeType === AvenDeviceRuntimeType.IOS
}

export const isAndroid = () => {
    const runtimeType = useDeviceInfoStore().runtimeType
    return runtimeType === AvenDeviceRuntimeType.ANDROID
}

export const showNativeNavBar = async () => {
    await toggleNativeNavBar(false)
}

export const hideNativeNavBar = async () => {
    await toggleNativeNavBar(true)
}

const toggleNativeNavBar = async (isHidden: boolean) => {
    // Only iOS native apps supports this command
    if (!(isIOS() && useDeviceInfoStore().isWebView)) {
        return
    }

    await sendMessageToNativeApp(NATIVE_MESSAGES.UPDATE_TAB_BAR, `isHidden=${isHidden ? 'true' : 'false'}`)
}

export const togglePullToRefresh = async (isEnabled: boolean) => {
    // Only Android native apps supports this command
    const deviceInfoStore = useDeviceInfoStore()
    if (isIOS() || !deviceInfoStore.isWebView || !deviceInfoStore.canTogglePullToRefresh) {
        return
    }

    await sendMessageToNativeApp(NATIVE_MESSAGES.TOGGLE_PULL_TO_REFRESH, `status=${isEnabled ? 'enabled' : 'disabled'}`)
}
