import { logger } from '@/utils/logger'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/browser'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { submitLogRocketSessionUrl } from '@/services/api'
import { openReplay } from './openReplay'

export const initializeLogRocket = (sessionId: string, userTraits: any) => {
    if (process.env.VUE_APP_NODE_ENV === 'development') {
        logger.info(`skip init logrocket for env: ${process.env.VUE_APP_NODE_ENV}`)
        return
    }
    LogRocket.init(process.env.VUE_APP_LOGROCKET_ID)
    LogRocket.identify(sessionId, userTraits)
    LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
            scope.setExtra('sessionURL', sessionURL)
        })
        logger.info(`logrocket session url: ${sessionURL}`)
        appSessionStorage.setItem(localStorageKey.sessionRecordingUrl, sessionURL)
        submitLogRocketSessionUrl(sessionId, sessionURL)
    })
}

export const initializeSessionRecording = (sessionId: string, userTraits: any) => {
    if (process.env.VUE_APP_LOGROCKET_ID) {
        logger.info(`init logRocket session with traits: ${JSON.stringify(userTraits)}`)
        initializeLogRocket(sessionId, userTraits)
    } else if (process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
        logger.info(`init openReplay session with traits: ${JSON.stringify(userTraits)}`)
        openReplay.init(sessionId, userTraits)
    }
}
