<template>
    <div
        v-if="shouldShowBanner"
        class="banner pt-1 pb-1"
        data-testid="account-activation-banner-container"
    >
        <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
            <div>
                <h5 class="banner-heading fw-bold mb-1">
                    {{ title }}
                </h5>
                <p>
                    {{ description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import format, { toFormattedUSDNoCents } from '@/mixins/format'
    import { useOverviewStore } from '@/store/overviewStore'
    import { navigationTabRouteNames } from '@/utils/navigationTabs'

    export default {
        name: 'CashV2ActivationBanner',
        mixins: [format],
        computed: {
            shouldShowBanner: function () {
                if (!useOverviewStore().cashOutV2BannerParams) {
                    return
                }
                if (!navigationTabRouteNames.includes(this.$route.name)) {
                    return
                }
                this.$emit('show', 'CashV2ActivationBanner', 'show')
                this.$logEvent('view_cash_out_v2_aven_my_banner', {
                    ...this.bannerParams,
                })
                return true
            },
            bannerParams: function () {
                return useOverviewStore().cashOutV2BannerParams
            },
            title: function () {
                // If they submitted fast funding but got denied, pretend fast-funding never existed
                if (this.bannerParams.isCashOutSubmitted) {
                    return i18n.t(`components.cashV2Banner.cashOutTransferring.title`, {
                        formattedCashOutCompletionDate: this.bannerParams.formattedTransferDate,
                    })
                }
                return i18n.t(`components.cashV2Banner.cashOutSubmitted.title`)
            },
            description: function () {
                const cashOutUpfrontAmount = toFormattedUSDNoCents(this.bannerParams.cashOutAmount)
                if (this.bannerParams.isCashOutSubmitted) {
                    return i18n.t(`components.cashV2Banner.cashOutTransferring.subTitle`, {
                        cashOutUpfrontAmount,
                        formattedCashOutCompletionDate: this.bannerParams.formattedTransferDate,
                    })
                }

                return i18n.t(`components.cashV2Banner.cashOutSubmitted.subTitle`, {
                    cashOutUpfrontAmount,
                    formattedCashOutCompletionDate: this.bannerParams.formattedSubmissionDate,
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/banner';

    .banner {
        max-width: 640px;
        margin: 0;
        background-color: $gray-100;
    }
</style>
