<template>
    <div class="d-flex justify-content-around md:tw-px-4">
        <common-action-button
            v-for="button in buttons"
            :key="button.text + button.iconSrc"
            :icon-src="button.iconSrc"
            :text="button.text"
            :invert-icon-color="button.invertIconColor"
            @click="handleButtonClick(button)"
            :data-testid="button.dataTestId"
            v-show="showCondition(button.title)"
        />
    </div>
</template>
<script lang="ts">
    import CommonActionButton from '@/components/CommonActionButton.vue'
    import { useOverviewStore } from '@/store/overviewStore'
    import { RoutePaths } from '@/routes/router.types'

    enum ButtonTitle {
        MakePayment = 'MakePayment',
        BalanceTransfer = 'BalanceTransfer',
        CashOut = 'CashOut',
    }

    export default {
        name: 'CommonActions',
        components: {
            CommonActionButton,
        },
        data() {
            return {
                buttons: [
                    {
                        title: ButtonTitle.MakePayment,
                        text: this.$t('components.commonActions.makePayment'),
                        iconSrc: 'images/components/commonActions/Payment.svg',
                        invertIconColor: false,
                        goto: RoutePaths.MAKE_PAYMENT,
                        dataTestId: 'common-action-button-make-payment',
                    },
                    {
                        title: ButtonTitle.BalanceTransfer,
                        text: this.$t('components.commonActions.cardBalance'),
                        iconSrc: 'images/components/commonActions/BalanceTransfer.svg',
                        invertIconColor: false,
                        goto: RoutePaths.BALANCE_TRANSFER_REQUEST,
                        dataTestId: 'common-action-button-balance-transfer',
                    },
                    {
                        title: ButtonTitle.CashOut,
                        text: this.$t('components.commonActions.cashOut'),
                        iconSrc: 'images/components/commonActions/Cash.svg',
                        invertIconColor: false,
                        goto: RoutePaths.CASH_OUT_HISTORY,
                        dataTestId: 'common-action-button-cash-out',
                    },
                ],
            }
        },
        computed: {
            showCashOutFeature() {
                return useOverviewStore().showCashOutFeature
            },
            showBalanceTransferFeature() {
                return useOverviewStore().showBalanceTransferFeature
            },
        },
        methods: {
            handleButtonClick(button) {
                this.$logEvent('click_button_common_action', { button })
                const { onClick, goto } = button
                // Not used right now but allows grater flexibility for buttons to do anything,
                // not just change routes
                if (typeof onClick === 'function') {
                    onClick(...arguments)
                }
                // Go to the specified route
                if (typeof goto === 'string') {
                    this.$router.push(goto)
                }
            },
            showCondition(buttonTitle) {
                switch (buttonTitle) {
                    case ButtonTitle.MakePayment:
                        return true
                    case ButtonTitle.BalanceTransfer:
                        return this.showBalanceTransferFeature
                    case ButtonTitle.CashOut:
                        return this.showCashOutFeature
                    default:
                        return false
                }
            },
        },
    }
</script>
