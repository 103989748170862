"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hmdaRaceValues = exports.hmdaEthnicityValues = exports.hmdaSexValues = void 0;
exports.hmdaSexValues = {
    none: 'none',
    female: 'female',
    male: 'male',
};
exports.hmdaEthnicityValues = {
    none: 'none',
    hispanic: 'hispanic',
    hispanicMexican: 'mexican',
    hispanicPuertoRican: 'puertoRican',
    hispanicCuban: 'cuban',
    hispanicOther: 'hispanic-other',
    notHispanic: 'notHispanic',
};
exports.hmdaRaceValues = {
    none: 'none',
    americanIndian: 'americanIndian',
    americanIndianOther: 'americanIndian-other',
    asian: 'asian',
    asianIndian: 'asianIndian',
    asianChinese: 'chinese',
    asianFilipino: 'filipino',
    asianJapanese: 'japanese',
    asianKorean: 'korean',
    asianVietnamese: 'vietnamese',
    asianOther: 'asian-other',
    black: 'black',
    hawaiian: 'hawaiian',
    hawaiianNative: 'nativeHawaiian',
    hawaiianGuamanian: 'guamanian',
    hawaiianSamoan: 'samoan',
    hawaiianOther: 'hawaiian-other',
    white: 'white',
};
