import assert from 'assert'

/**
 * Rounds a number to a certain number of decimal places
 */
export const roundToNDecimalPlaces = (numberToRound: number, decimalPlaces: number): number => {
    assert(decimalPlaces >= 0, `decimalPlaces must be 0 or more for roundToNDecimalPlaces`)
    const adjuster = 10 ** decimalPlaces
    return Math.round(numberToRound * adjuster) / adjuster
}
