export enum BalanceTransferType {
    CreditCard = 'CreditCard',
    PersonalLoan = 'PersonalLoan',
    CashOut = 'CashOut',
    InstantLiability = 'InstantLiability',
}

export enum BalanceTransferStatus {
    HumanInvestigate = 'HumanInvestigate', // Register BT and wait for a manual update on this record.
    AciSubmissionPending = 'AciSubmissionPending', // Pre-registering BT, BT API call to ACI is pending
    AciSubmissionSuccess = 'AciSubmissionSuccess', // BT API call to ACI is successful
    AciSubmissionFailed = 'AciSubmissionFailed', // BT API call to ACI failed
    CoreCardSubmissionPending = 'CoreCardSubmissionPending', // Post single transaction successful
    CoreCardSubmissionSuccess = 'CoreCardSubmissionSuccess', // Post single transaction successful
    CoreCardSubmissionFailed = 'CoreCardSubmissionFailed', // Post Single transaction failed
    Completed = 'Completed',
    Canceled = 'Canceled',
    OnHoldDueToRescissionPeriod = 'OnHoldDueToRescissionPeriod',
    AmountPlusFeesExceedsAvailableCreditLimit = 'AmountPlusFeesExceedsAvailableCreditLimit',
    AmountExceedsMaxAllowedAfterPayment = 'AmountExceedsMaxAllowedAfterPayment',
    TotalAmountOfCashOutsAndBalanceTransfersExceedDailyLimit = 'TotalAmountOfCashOutsAndBalanceTransfersExceedDailyLimit',
    MethodFiSubmissionPending = 'MethodFiSubmissionPending', // Pre-registering BT, BT API call to MethodFi is pending
    MethodFiSubmissionSuccess = 'MethodFiSubmissionSuccess', // BT API call to MethodFi is successful
    MethodFiSubmissionFailed = 'MethodFiSubmissionFailed', // BT API call to MethodFi failed
}

export enum AciPaymentTransactionType {
    PAYMENT = 'PAYMENT',
    REIMBURSEMENT = 'REIMBURSEMENT',
    REVERSAL = 'REVERSAL', // Cancelled or failed payments
}

export interface IBalanceTransferAccount {
    accountId: number
    accountName: string
    isAutoBalanceTransferEnabled: boolean
}

// TODO: better typings
export interface IBalanceTransfer {
    existingTransactions: {}
    activeBalanceTransferAccountsEligibleForRecurrence: IBalanceTransferAccount[]
    availableCreditLimit: number
    apr: number
    selectedAccount: object
    mostRecentRecurringBalanceTransfer: IInitiatedBalanceTransfer
}

export interface IInitiatedBalanceTransfer {
    amount: number
    token: string
    accountName: string
}

// https://docs.methodfi.com/api/core/payments/object
// Prepended the MethodFi prefix to avoid name collisions with other enums
export enum MethodFiPaymentStatus {
    MethodFiPending = 'pending',
    MethodFiProcessing = 'processing',
    MethodFiSent = 'sent',
    MethodFiFailed = 'failed',
    MethodFiCanceled = 'canceled',
    MethodFiReversalRequired = 'reversal_required',
    MethodFiReversalProcessing = 'reversal_processing',
    MethodFiReversed = 'reversed',
}
