import { abTestExperimentsFactory, ExperimentSpecGroupName, ExperimentType } from 'aven_shared/src/utils/abExperimentsUtils'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'

const abExperimentUtils = abTestExperimentsFactory({
    appSessionStorage,
    sessionStorageOverridesKey: sessionStorageKey.experimentsOverrides,
    getLogEventFn: () => window.logEvent,
})

export const isExperimentEnabled = abExperimentUtils.isExperimentEnabled
export const EXPERIMENT_TYPE = ExperimentType
export const EXPERIMENT_SPEC_GROUP_NAME = ExperimentSpecGroupName
