"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradelineTypeName = void 0;
var TradelineTypeName;
(function (TradelineTypeName) {
    TradelineTypeName["creditCards"] = "Credit Cards";
    TradelineTypeName["studentLoans"] = "Student Loans";
    TradelineTypeName["personalLoans"] = "Personal Loans";
    TradelineTypeName["autoLoans"] = "Auto Loans";
    TradelineTypeName["homeLoans"] = "Home Loans";
    TradelineTypeName["other"] = "Other";
})(TradelineTypeName = exports.TradelineTypeName || (exports.TradelineTypeName = {}));
