"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditScoreBand = exports.UserApprovalChance = exports.ApprovalChance = void 0;
var ApprovalChance;
(function (ApprovalChance) {
    /**
     * @deprecated replaced by unlikely/likely
     */
    ApprovalChance["Excellent"] = "excellent";
    /**
     * @deprecated replaced by unlikely/likely
     */
    ApprovalChance["VeryGood"] = "very_good";
    /**
     * @deprecated replaced by unlikely/likely
     */
    ApprovalChance["Good"] = "good";
    /**
     * @deprecated replaced by unlikely/likely
     */
    ApprovalChance["Fair"] = "fair";
    /**
     * @deprecated replaced by unlikely/likely
     */
    ApprovalChance["Poor"] = "poor";
})(ApprovalChance = exports.ApprovalChance || (exports.ApprovalChance = {}));
var UserApprovalChance;
(function (UserApprovalChance) {
    UserApprovalChance["Likely"] = "likely";
    UserApprovalChance["Unlikely"] = "unlikely";
})(UserApprovalChance = exports.UserApprovalChance || (exports.UserApprovalChance = {}));
var CreditScoreBand;
(function (CreditScoreBand) {
    CreditScoreBand["Exceptional"] = "exceptional";
    CreditScoreBand["VeryGood"] = "very_good";
    CreditScoreBand["Good"] = "good";
    CreditScoreBand["Fair"] = "fair";
    CreditScoreBand["Poor"] = "poor";
})(CreditScoreBand = exports.CreditScoreBand || (exports.CreditScoreBand = {}));
