"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanApplicationDebtProtectionEnrollmentStatus = void 0;
/**
 * Mutually Exclusive Collectively Exhaustive status for Debt Protection Enrollment
 *
 * Partition (disjoint and complete) of statuses we are trying to capture:
 *   - Not rendered
 *   - Rendered (included in the experiment, not necessarily shown to the user if not eligible)
 *     - Not eligible
 *     - Eligible
 *       - Didn't opt in
 *       - Opted in:
 *         - Awaiting signature in notary
 *         - Declined during notary (didn't sign)
 *         - Signed during notary (completed enrollment)
 */
var LoanApplicationDebtProtectionEnrollmentStatus;
(function (LoanApplicationDebtProtectionEnrollmentStatus) {
    /**
     * @deprecated
     *
     * This is a legacy status, do not use this
     * Previously, this could mean not eligible, not opted in, or declined in notary
     */
    LoanApplicationDebtProtectionEnrollmentStatus["notEnrolled"] = "notEnrolled";
    /**
     * Not rendered for the user - either because they haven't reached the page yet, or they weren't included in the experiment
     *
     * Default status for new LAs
     */
    LoanApplicationDebtProtectionEnrollmentStatus["notRendered"] = "notRendered";
    /**
     * Included in the experiment, not eligible
     *
     * Eligibility criteria is: 1) Not self-employed; 2) Under 70 yrs
     */
    LoanApplicationDebtProtectionEnrollmentStatus["notEligible"] = "notEligible";
    /**
     * Included in the experiment, eligible, not opted in (yet)
     */
    LoanApplicationDebtProtectionEnrollmentStatus["didNotOptIn"] = "didNotOptIn";
    /**
     * Included in the experiment, eligible, opted in, haven't had notary meeting yet
     */
    LoanApplicationDebtProtectionEnrollmentStatus["optedIn"] = "optedIn";
    /**
     * Included in the experiment, eligible, opted in, declined to sign in notary
     */
    LoanApplicationDebtProtectionEnrollmentStatus["declinedInNotary"] = "declinedInNotary";
    /**
     * Included in the experiment, eligible, opted in, signed in notary to complete enrollment
     *
     * Previously, this status could mean opted in and haven't had notary meeting yet, or signed in notary
     */
    LoanApplicationDebtProtectionEnrollmentStatus["enrolled"] = "enrolled";
})(LoanApplicationDebtProtectionEnrollmentStatus = exports.LoanApplicationDebtProtectionEnrollmentStatus || (exports.LoanApplicationDebtProtectionEnrollmentStatus = {}));
