"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeaserPromoType = void 0;
/** @TranslateToPython */
var TeaserPromoType;
(function (TeaserPromoType) {
    TeaserPromoType["NO_TEASER_PROMO"] = "NO_TEASER_PROMO";
    TeaserPromoType["TEASER_ZERO_APR_REVOLVING"] = "TEASER_ZERO_APR_REVOLVING";
    TeaserPromoType["TEASER_PROMO_BALANCE_TRANSFER_REVOLVING"] = "TEASER_PROMO_BALANCE_TRANSFER_REVOLVING";
})(TeaserPromoType = exports.TeaserPromoType || (exports.TeaserPromoType = {}));
