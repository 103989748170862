"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WALKING_MINS_PER_MILE = exports.AvenAdvisorPropertyListingType = exports.RentcastPropertyType = void 0;
// https://developers.rentcast.io/reference/property-types
var RentcastPropertyType;
(function (RentcastPropertyType) {
    RentcastPropertyType["singleFamily"] = "Single Family";
    RentcastPropertyType["condo"] = "Condo";
    RentcastPropertyType["townhouse"] = "Townhouse";
    RentcastPropertyType["manufactured"] = "Manufactured";
    RentcastPropertyType["multiFamily"] = "Multi-Family";
    RentcastPropertyType["Appartment"] = "Appartment";
    RentcastPropertyType["land"] = "Land";
})(RentcastPropertyType = exports.RentcastPropertyType || (exports.RentcastPropertyType = {}));
var AvenAdvisorPropertyListingType;
(function (AvenAdvisorPropertyListingType) {
    AvenAdvisorPropertyListingType["forSale"] = "forSale";
    AvenAdvisorPropertyListingType["recentlySold"] = "recentlySold";
    AvenAdvisorPropertyListingType["rental"] = "rental";
})(AvenAdvisorPropertyListingType = exports.AvenAdvisorPropertyListingType || (exports.AvenAdvisorPropertyListingType = {}));
exports.WALKING_MINS_PER_MILE = 15; // Assuming 15 mins per mile at a brisk walking pace according to https://www.verywellfit.com/convert-miles-to-kilometers-and-walking-time-3876685
