<template>
    <div class="d-flex justify-content-between mb-0">
        <p :class="indentRow ? 'fixedPlanDetail mb-0' : 'mb-0'">
            {{ paymentRowName }}
        </p>
        <p class="mb-0">
            {{ formatBreakdownAmount(amount) }}
        </p>
    </div>
</template>

<script>
    import generic from '@/utils/generic'

    export default {
        name: 'BasePaymentRow',
        inheritAttrs: false,
        props: {
            paymentRowName: {
                type: String,
                required: true,
            },
            amount: {
                type: String,
                required: true,
            },
            indentRow: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            formatBreakdownAmount(amount) {
                return '+ ' + generic.prettyPrintDollarAmount(amount)
            },
        },
    }
</script>

<style>
    .fixedPlanDetail {
        margin-left: 20px;
    }
</style>
