import Help from '@/components/Help'

export const helpModalMixin = {
    components: {
        help: Help,
    },
    data: function () {
        return {
            isModalVisible: false,
        }
    },
    methods: {
        showHelpModal: function () {
            this.isModalVisible = true
        },
        hideHelpModal: function () {
            this.isModalVisible = false
        },
    },
}
