"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CitizenshipResidencyType = exports.StatedUrgencyType = exports.StatedUsageType = exports.ResidenceType = exports.MaritalStatus = exports.ApplicantType = void 0;
/** @TranslateToPython */
var ApplicantType;
(function (ApplicantType) {
    ApplicantType["primary"] = "primary";
    ApplicantType["coApplicant"] = "coApplicant";
    ApplicantType["coOwner"] = "coOwner";
    ApplicantType["livingTrustMember"] = "livingTrustMember";
})(ApplicantType = exports.ApplicantType || (exports.ApplicantType = {}));
// Keep in sync with enum in aven_backend/src/entity/applicant.ts
var MaritalStatus;
(function (MaritalStatus) {
    MaritalStatus["MARRIED"] = "MARRIED";
    MaritalStatus["UNMARRIED"] = "UNMARRIED";
    MaritalStatus["SEPARATED"] = "SEPARATED";
})(MaritalStatus = exports.MaritalStatus || (exports.MaritalStatus = {}));
// Keep in sync with enum in aven_backend/src/entity/home.ts
var ResidenceType;
(function (ResidenceType) {
    ResidenceType["PRIMARY"] = "PRIMARY";
    ResidenceType["SECONDARY"] = "SECONDARY";
    ResidenceType["INVESTMENT"] = "INVESTMENT";
})(ResidenceType = exports.ResidenceType || (exports.ResidenceType = {}));
// Mirrored in aven_backend and aven_python
var StatedUsageType;
(function (StatedUsageType) {
    StatedUsageType["HOME_IMPROVEMENT"] = "Home Improvement";
    StatedUsageType["BALANCE_TRANSFER"] = "Balance Transfer";
    StatedUsageType["OTHER"] = "Other";
})(StatedUsageType = exports.StatedUsageType || (exports.StatedUsageType = {}));
var StatedUrgencyType;
(function (StatedUrgencyType) {
    StatedUrgencyType["WEEK"] = "Week";
    StatedUrgencyType["MONTH"] = "Month";
    StatedUrgencyType["YEAR"] = "Year";
})(StatedUrgencyType = exports.StatedUrgencyType || (exports.StatedUrgencyType = {}));
var CitizenshipResidencyType;
(function (CitizenshipResidencyType) {
    CitizenshipResidencyType["CITIZEN"] = "CITIZEN";
    CitizenshipResidencyType["NON_PERMANENT_RESIDENT_ALIEN"] = "NON_PERMANENT_RESIDENT_ALIEN";
    CitizenshipResidencyType["PERMANENT_RESIDENT_ALIEN"] = "PERMANENT_RESIDENT_ALIEN";
})(CitizenshipResidencyType = exports.CitizenshipResidencyType || (exports.CitizenshipResidencyType = {}));
