import { defineStore } from 'pinia'
import { AvenDeviceRuntimeType, DeviceCapabilitiesMap, DeviceInfo } from '@/utils/deviceUtils'
import * as Sentry from '@sentry/browser'
import { DeviceCapabilities } from '@/utils/deviceCapabilities'
import { openReplay } from '@/utils/openReplay'
import { useSessionStorage } from '@vueuse/core'
import { logger } from '@/utils/logger'

export const useDeviceInfoStore = () => {
    const deviceInfoStore = defineStore('deviceInfo', {
        state: (): DeviceInfo => {
            // We need to persist all values in session storage otherwise they'll get lost when the app navigates
            // to a non-Aven My page in the same tab (for example when we navigate to the Ascenda rewards portal).
            // Other stores don't need to do this because they re-populate themselves with API calls.
            return {
                deviceGuid: useSessionStorage('deviceGuid', undefined) as unknown as string | undefined,
                deviceName: useSessionStorage('deviceName', undefined) as unknown as string | undefined,
                deviceModel: useSessionStorage('deviceModel', undefined) as unknown as string | undefined,
                browser: useSessionStorage('browser', undefined) as unknown as string | undefined,
                resolution: useSessionStorage('resolution', undefined) as unknown as string | undefined,
                os: useSessionStorage('os', undefined) as unknown as string | undefined,
                appVersion: useSessionStorage('appVersion', undefined) as unknown as string | undefined,
                runtimeType: useSessionStorage('runtimeType', undefined) as unknown as AvenDeviceRuntimeType | undefined,
                platform: useSessionStorage('platform', undefined) as unknown as string | undefined,
                deviceCapabilities: useSessionStorage('deviceCapabilities', {}) as unknown as DeviceCapabilitiesMap | undefined,
                webHasAskedForPushPermission: useSessionStorage('webHasAskedForPushPermission', false) as unknown as boolean,
                isWebView: useSessionStorage('isWebView', false) as unknown as boolean,
                isSingleWebView: useSessionStorage('isSingleWebView', false) as unknown as boolean,
            }
        },
        getters: {
            canComposeSms: (state) => state.deviceCapabilities?.[DeviceCapabilities.ComposeSms],
            canUseSmsEventSignaling: (state) => state.deviceCapabilities?.[DeviceCapabilities.SmsEventsSignaling],
            biometricsEnabled: (state) => state.deviceCapabilities?.[DeviceCapabilities.Biometrics],
            nativeCanProvideAndRequestPushPermission: (state) => state.deviceCapabilities?.[DeviceCapabilities.CanProvideAndRequestPushPermission],
            canTogglePullToRefresh: (state) => state.deviceCapabilities?.[DeviceCapabilities.CanTogglePullToRefresh],
            canAskForOriginationPushNotifs: (state) => state.deviceCapabilities?.[DeviceCapabilities.CanAskForOriginationPushNotifs],
            canAskForAppReview: (state) => state.deviceCapabilities?.[DeviceCapabilities.CanAskForAppReview],
            doesNativeAppHandleContactsProminentDisclosure: (state) => state.deviceCapabilities?.[DeviceCapabilities.NativeAppHandlesContactsProminentDisclosure],
            canHandleAscendaDeepLinks: (state) => state.deviceCapabilities?.[DeviceCapabilities.CanHandleAscendaDeepLinks],
            shouldUseWebNavigationBar: (state) => state.deviceCapabilities?.[DeviceCapabilities.ShouldUseWebNavigationBar],
        },
        actions: {
            setDeviceInfo(payload: DeviceInfo) {
                this.$patch({
                    ...payload,
                })
                if ([AvenDeviceRuntimeType.IOS, AvenDeviceRuntimeType.ANDROID].includes(payload.runtimeType) && !payload.browser) {
                    //backwards compatibility. older native app versions do not include this
                    //field when deviceInfo is injected.
                    //session api requires this field now.
                    this.browser = 'mobileWebview'
                }
                Sentry.setTag('runtimeType', payload.runtimeType)
            },
            setDeviceCapabilities(deviceCapabilitiesJson: any) {
                const deviceCapabilities: DeviceCapabilitiesMap = {
                    [DeviceCapabilities.ComposeSms]: deviceCapabilitiesJson[DeviceCapabilities.ComposeSms],
                    [DeviceCapabilities.SmsEventsSignaling]: deviceCapabilitiesJson[DeviceCapabilities.SmsEventsSignaling],
                    [DeviceCapabilities.Biometrics]: deviceCapabilitiesJson[DeviceCapabilities.Biometrics],
                    [DeviceCapabilities.PifCanUseContacts]: deviceCapabilitiesJson[DeviceCapabilities.PifCanUseContacts],
                    [DeviceCapabilities.CanProvideAndRequestPushPermission]: deviceCapabilitiesJson[DeviceCapabilities.CanProvideAndRequestPushPermission],
                    [DeviceCapabilities.CanTogglePullToRefresh]: deviceCapabilitiesJson[DeviceCapabilities.CanTogglePullToRefresh],
                    [DeviceCapabilities.CanAskForOriginationPushNotifs]: deviceCapabilitiesJson[DeviceCapabilities.CanAskForOriginationPushNotifs],
                    [DeviceCapabilities.CanAskForAppReview]: deviceCapabilitiesJson[DeviceCapabilities.CanAskForAppReview],
                    [DeviceCapabilities.NativeAppHandlesContactsProminentDisclosure]: deviceCapabilitiesJson[DeviceCapabilities.NativeAppHandlesContactsProminentDisclosure],
                    [DeviceCapabilities.CanHandleAscendaDeepLinks]: deviceCapabilitiesJson[DeviceCapabilities.CanHandleAscendaDeepLinks],
                    [DeviceCapabilities.ShouldUseWebNavigationBar]: deviceCapabilitiesJson[DeviceCapabilities.ShouldUseWebNavigationBar],
                }
                logger.info(`Setting device capabilities on Aven My web ${JSON.stringify(deviceCapabilities)}`)
                this.deviceCapabilities = deviceCapabilities
            },
            disableBiometrics() {
                const current = this.deviceCapabilities
                this.deviceCapabilities = {
                    ...current,
                    [DeviceCapabilities.Biometrics]: false,
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(deviceInfoStore)
    return deviceInfoStore
}
