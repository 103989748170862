<template>
    <div
        class="text-center my-8"
        v-if="outstandingTransaction.length === 0 && settledTransaction.size === 0"
    >
        <p class="fw-bold mb-1">
            {{ $t('pages.activity.NoTransactionYet') }}
        </p>
        <p
            class="text-muted"
            v-html="$t('pages.activity.AnticipationHtml')"
        />
    </div>
    <div v-else>
        <loading-indicator v-if="loading" />
        <!-- Outstanding Transaction with heading "PENDING" & without DateGroup -->
        <div v-if="outstandingTransaction.size > 0">
            <section-header override-class="container mt-3 mb-1 text-left">
                Pending
            </section-header>
            <card-block
                :title="outstandingTransactionsHeading"
                class="mb-2"
            >
                <div
                    v-for="[key, value] of outstandingTransaction"
                    :key="key"
                    class="transaction-list-item"
                >
                    <div
                        v-for="LatestTransaction in value"
                        :key="LatestTransaction.transactionID"
                        class="transaction-list-item"
                    >
                        <transaction-row
                            :title="LatestTransaction.title"
                            :date="formatDateUtc(LatestTransaction.transactionDate)"
                            :highlight-color="LatestTransaction.isDeposit ? 'text-success' : 'text-primary'"
                            :value="LatestTransaction.absoluteAmount"
                            :value-sub-text="formattedRewardsPointsChange(LatestTransaction)"
                        />
                        <template>
                            <p
                                v-if="LatestTransaction.title === 'Cash Out'"
                                class="text-muted small"
                            >
                                {{ $t('pages.cashOut.pendingCashOutListSubtext') }}
                            </p>
                            <p
                                v-else-if="LatestTransaction.title === 'Balance Transfer'"
                                class="text-muted small"
                            >
                                {{ $t('components.balanceTransfer.pendingTransfer') }}
                            </p>
                        </template>
                    </div>
                </div>
            </card-block>
        </div>

        <!-- Settled Transactions -->
        <div v-if="settledTransaction.size > 0">
            <section-header override-class="container mt-3 mb-1 text-left">
                Transactions
            </section-header>
            <div
                v-for="[key, settledTransactions] of settledTransaction"
                :key="key"
            >
                <card-block
                    :title="key"
                    class="mb-2"
                >
                    <div
                        v-for="transaction in settledTransactions"
                        :key="transaction.transactionId ?? transaction.fullDrawRequestId"
                        class="transaction-list-item"
                    >
                        <transaction-row
                            v-if="transaction.transactionId"
                            :title="transaction.title"
                            :date="formatDateUtc(transaction.transactionDate)"
                            :highlight-color="transaction.isDeposit ? 'text-success' : 'text-primary'"
                            :value="transaction.absoluteAmount"
                            :value-sub-text="formattedRewardsPointsChange(transaction)"
                            :show-arrow="transaction.isEligibleForDispute && allowSubmitTransactionDisputeInApp"
                            @onClick="handleOnClickTransactionDetailsById(transaction.transactionId, transaction.isEligibleForDispute)"
                        />
                        <transaction-row
                            v-else
                            :title="transaction.title"
                            :date="formatDateUtc(transaction.transactionDate)"
                            :value="transaction.absoluteAmount"
                            :value-sub-text="formattedFullDrawCashOutFee(transaction)"
                        />
                        <template v-if="isWithinDaysRange(transaction.transactionDate, 10)">
                            <p
                                v-if="transaction.fullDrawRequestId"
                                class="text-muted small"
                            >
                                {{ $t('pages.cashOut.recentFullDrawCashOutSubtext', { formattedMaxDailyBtPlusCashOuts }) }}
                            </p>
                            <p
                                v-else-if="transaction.title === 'Cash Out'"
                                class="text-muted small"
                            >
                                {{ $t('pages.cashOut.pendingCashOutListSubtext') }}
                            </p>
                            <p
                                v-else-if="transaction.title === 'Balance Transfer'"
                                class="text-muted small"
                            >
                                {{ $t('components.balanceTransfer.pendingTransfer') }}
                            </p>
                        </template>
                    </div>
                </card-block>
            </div>
        </div>
    </div>
</template>

<script>
    import { logger } from '@/utils/logger'
    import CardBlock from '@/components/CardBlock.vue'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { transactionHistory } from '@/services/api'
    import { DISPLAY_DATE_FORMAT, MONTH_FORMAT, MONTH_YEAR_FORMAT, NYC_TIMEZONE, TransactionStatus } from '@/data/constants'
    import dayjs from 'dayjs'
    import generic from '@/utils/generic'
    import { RouteNames } from '@/routes/router.types'
    import TransactionRow from '@/components/TransactionRow.vue'
    import { useOverviewStore } from '@/store/overviewStore'
    import { i18n } from '@/utils/i18n'
    import LoadingIndicator from '@/components/LoadingIndicator.vue'
    import SectionHeader from '@/components/SectionHeader.vue'
    import { storeToRefs } from 'pinia'
    import { computed } from 'vue'
    import { toFormattedUSDStripTrailingZeroCents } from '@/mixins/format'

    export default {
        name: 'TransactionList',
        components: { SectionHeader, LoadingIndicator, CardBlock, TransactionRow },
        props: {
            showSettledTransactions: {
                type: Boolean,
                default: true,
            },
            showOutstandingTransactions: {
                type: Boolean,
                default: true,
            },
            outstandingTransactionsHeading: {
                type: String,
                default: i18n.t('pages.activity.Pending'),
            },
        },
        data() {
            return {
                loading: '',
                settledTransaction: '',
                outstandingTransaction: '',
            }
        },
        computed: {
            allowSubmitTransactionDisputeInApp() {
                return useOverviewStore().allowSubmitTransactionDisputeInApp
            },
        },
        setup() {
            const overviewStore = useOverviewStore()
            const { maxTotalDailyBtPlusCashOutInDollars } = storeToRefs(overviewStore)
            const formattedMaxDailyBtPlusCashOuts = computed(() => {
                if (Number.isNaN(maxTotalDailyBtPlusCashOutInDollars.value)) {
                    return ''
                }
                return toFormattedUSDStripTrailingZeroCents(maxTotalDailyBtPlusCashOutInDollars.value)
            })
            return {
                formattedMaxDailyBtPlusCashOuts,
            }
        },
        mounted: function () {
            this.loadTransactions()
        },
        methods: {
            loadTransactions: async function () {
                try {
                    this.loading = true
                    await this.getAllTransactions()
                } catch (error) {
                    ApiErrorHandler(error)
                } finally {
                    this.loading = false
                }
            },
            formatPendingBTAndCOTransactions(transaction) {
                let titlePrefix = ''

                if (transaction.type === this.TransactionType.CreditCard) {
                    titlePrefix = 'Balance Transfer '
                } else if (transaction.type === this.TransactionType.CashOut) {
                    titlePrefix = 'Cash Out '
                }
                return {
                    type: transaction.type,
                    title: `${titlePrefix}${transaction.lender}`,
                    amount: transaction.amount,
                    transactionDate: transaction.initiationDate,
                    transactionID: transaction.initiationDate,
                }
            },
            getAllTransactions: async function () {
                const settledTxnPromise = this.showSettledTransactions ? transactionHistory('0', TransactionStatus.Settled) : new Promise((resolve) => resolve(undefined))
                const outstandingTxnPromise = this.showOutstandingTransactions ? transactionHistory('0', TransactionStatus.Outstanding) : new Promise((resolve) => resolve(undefined))
                const [settledResponse, outstandingResponse] = await Promise.all([settledTxnPromise, outstandingTxnPromise])
                logger.info('settledResponse and outstandingResponse completed without errors')

                const allSettledTransactions = settledResponse?.data?.payload ?? []
                const allOutstandingTransactions = outstandingResponse?.data?.payload ?? []

                if (this.showSettledTransactions) {
                    this.settledTransaction = this.getTransactionsMap(allSettledTransactions)
                }

                if (this.showOutstandingTransactions) {
                    // It's possible for a chunked full draw cash out to have a chunk in settled transactions and
                    // a chunk in outstanding transactions, so we need to de-dup. If that's the case we'll show full
                    // draw cash outs in the settled transactions list and remove from outstanding transactions .
                    const settledFullDrawRequestIds = allSettledTransactions.map((transaction) => transaction.fullDrawRequestId).filter((fullDrawRequestId) => !!fullDrawRequestId)
                    const filteredOutstandingTransactions = allOutstandingTransactions.filter((transaction) => {
                        return !transaction.fullDrawRequestId || !settledFullDrawRequestIds.includes(transaction.fullDrawRequestId)
                    })

                    // Todo There's a bug here: this.existingCashOutTransfers & this.existingBalanceTransfers don't
                    //  exist on this component! The bug has been added to the scrum doc. To whomever fixes this, be
                    //  mindful that full draw cash outs might have some chunks that are complete & some that are
                    //  pending, but we *DO NOT* want to include them in both lists. We probably should not get cash outs
                    //  and balance transfers from a completely different code path from other transactions; we should
                    //  instead add COs and BTs to the transactions returned from the backend so they're all coming
                    //  from the same place and can be de-duped on the backend.
                    const pendingCashOuts = this.existingCashOutTransfers?.get('PENDING') || []
                    const pendingBalanceTransfers = this.existingBalanceTransfers?.get('PENDING') || []
                    const pendingBalanceTransfersAndCashOuts = [...pendingCashOuts, ...pendingBalanceTransfers]
                    const outstandingTransactions = [...pendingBalanceTransfersAndCashOuts.map(this.formatPendingBTAndCOTransactions), ...filteredOutstandingTransactions]

                    // order by transactionDate in descending order
                    outstandingTransactions.sort((a, b) => {
                        return dayjs(b.transactionDate).diff(dayjs(a.transactionDate))
                    })
                    this.outstandingTransaction = this.getTransactionsMap(outstandingTransactions)
                }
            },
            getTransactionsMap: function (transactionResponse) {
                let list = []
                let transactionMap = new Map()

                const formatTransaction = (tx) => ({
                    title: tx.formattedTitle,
                    absoluteAmount: toFormattedUSDStripTrailingZeroCents(Math.abs(tx.amount)),
                    fee: tx.fee ? toFormattedUSDStripTrailingZeroCents(Math.abs(tx.fee)) : null,
                    isDeposit: tx.amount < 0,
                    transactionDate: tx.transactionDate,
                    transactionId: tx.transactionId,
                    isEligibleForDispute: tx.isEligibleForDispute,
                    scaledRewardsPointsChange: tx.scaledRewardsPointsChange,
                    firstCashOutId: tx.firstCashOutId,
                    fullDrawRequestId: tx.fullDrawRequestId,
                })

                for (let index in transactionResponse) {
                    const formattedTransactionDate = generic.convertDateTimeFormat(transactionResponse[index].transactionDate)
                    const sectionHeaderFormat = generic.isCurrentYear(formattedTransactionDate) ? MONTH_FORMAT : MONTH_YEAR_FORMAT
                    const sectionHeader = generic.convertDateTimeFormat(transactionResponse[index].transactionDate, { outputFormat: sectionHeaderFormat })
                    if (transactionMap.has(sectionHeader)) {
                        list = transactionMap.get(sectionHeader)
                        list.push(formatTransaction(transactionResponse[index]))
                        transactionMap.set(sectionHeader, list)
                    } else {
                        transactionMap.set(sectionHeader, [formatTransaction(transactionResponse[index])])
                    }
                }
                return transactionMap
            },
            handleOnClickTransactionDetailsById: function (transactionId, isEligibleForDispute) {
                if (!this.allowSubmitTransactionDisputeInApp) {
                    logger.info(`transactionId: ${transactionId}. allowSubmitTransactionDisputeInApp = ${this.allowSubmitTransactionDisputeInApp}. Returning`)
                    return
                }

                if (!isEligibleForDispute) {
                    logger.info(`transactionId: ${transactionId}. This transaction is not eligible to be disputed. Return`)
                    return
                }

                logger.info(`Passing transactionId: ${transactionId} to /transactionId`)
                this.$router.push({
                    name: RouteNames.TRANSACTION_BY_ID,
                    params: {
                        transactionId,
                    },
                })
            },
            formatDateUtc(date) {
                return generic.utcToTimeZone(date, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
            },
            isWithinDaysRange(date, range) {
                return dayjs().diff(date, 'day') <= range
            },
            formattedRewardsPointsChange(transaction) {
                if (!transaction.scaledRewardsPointsChange) {
                    return null
                }
                let formatted
                if (transaction.scaledRewardsPointsChange > 0) {
                    formatted = `+ ${transaction.scaledRewardsPointsChange}`
                } else {
                    // Remove the "-" that comes with the number, so we can add a space
                    // between it and the number.
                    formatted = `- ${Math.abs(transaction.scaledRewardsPointsChange)}`
                }
                return this.$tc('pages.activity.points', Math.abs(transaction.scaledRewardsPointsChange), { formattedPoints: formatted })
            },
            formattedFullDrawCashOutFee(transaction) {
                return this.$t('pages.cashOut.includingFeeSubText', { formattedFee: transaction.fee })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .transaction-list-item {
        margin-bottom: $spacer;

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
