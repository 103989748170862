import { useOverviewStore } from '@/store/overviewStore'
import { getPayItForwardLinks } from '@/services/avenAppApi'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { logger } from '@/utils/logger'
import { PifRewardType } from '@/store/overviewStore.types'

export enum PifScreen {
    ACTIVITY = 'my-activity',
    STATEMENTS = 'my-statements',
    CONTACT_LIST = 'my-view-pif-contact-list',
    CONTACT_LIST_NATIVE = 'my-view-pif-contact-list-native',
    TWITTER_SHARE = 'my-twitter-share',
    FACEBOOK_SHARE = 'my-facebook-share',
    BALANCE_TRANSFER = 'my-balance-transfer-complete',
    HEADER = 'my-header',
    ACCOUNT = 'my-account',
    CARD = 'my-card',
    REWARDS = 'my-rewards',
    CASH_OUT_CONFIRMATION = 'my-cash-out-confirmation',
    PIF_PAGE = 'my-view-pif-link',
    PIF_PAGE_NATIVE = 'my-view-pif-link-native',
    MY_SEND_EMAIL = 'my-send-email',
    ESTIMATED_SAVINGS_CARD = 'my-estimated-savings-card',
}

// See https://developers.facebook.com/apps/345904737788247
const FB_APP_ID = '345904737788247'

class PifManager {
    public async getPayItForwardLink(screenName: PifScreen): Promise<string | undefined> {
        await this.tryPreloadPifData()
        return useOverviewStore().pifData?.linksPerScreen[screenName]
    }

    public async tryPreloadPifData() {
        try {
            logger.info(`Trying to preload PIF data`)
            const overviewStore = useOverviewStore()
            if (overviewStore.pifData) {
                logger.info(`Already have PIF data, no need to preload again`)
                return
            }
            if (!overviewStore.isHomeProduct) {
                logger.info(`Product is not a home product, no need to preload PIF data`)
                return
            }

            const screenList = Object.values(PifScreen)
            const pifDataResponse = await this.getPayItForwardData(screenList)

            const tipAmount = pifDataResponse?.maxAmount
            const pifData = {
                tipAmount: tipAmount,
                linksPerScreen: pifDataResponse.payItForwardShareTrackedLinks.reduce((acc, curr) => {
                    acc[curr.screenName] = curr.link
                    return acc
                }, {} as { [K in PifScreen]: string }),
                rewardType: pifDataResponse.rewardType,
            } as { tipAmount: number; linksPerScreen: { [K in PifScreen]: string }; rewardType: PifRewardType | null }

            overviewStore.pifData = pifData
            logger.info(`Preloaded PIF data ${JSON.stringify(pifData)}`)
        } catch (e) {
            logger.fatal(`Error preloading PIF data`, e)
        }
    }

    private async getPayItForwardData(
        screenNames: PifScreen[]
    ): Promise<{ payItForwardShareTrackedLinks: { screenName: string; link: string }[]; maxAmount: number; rewardType: PifRewardType | null } | undefined> {
        const response = await getPayItForwardLinks(screenNames)

        if (!response.data.success) {
            const globalUiStore = useGlobalUiStore()
            globalUiStore.error = response.data.error
            return
        }

        const payItForwardShareTrackedLinks = response.data.payload?.shareLinks
        const maxAmount = response.data.payload?.maxAmount
        const rewardType = response.data.payload?.rewardType

        return { payItForwardShareTrackedLinks, maxAmount, rewardType }
    }

    public async shareOnTwitterAndLogEvent(text: string) {
        const link = await this.getPayItForwardLink(PifScreen.TWITTER_SHARE)

        window.logEvent('click_pay_it_forward_twitter_share', {
            text,
            link,
        })

        const uriEncodedPifText = encodeURIComponent(text)
        const twitterShareLink = `https://twitter.com/intent/tweet?url=${link}&text=${uriEncodedPifText}`

        window.open(twitterShareLink, '_blank')
    }

    public async shareOnFacebookAndLogEvent() {
        let link = await this.getPayItForwardLink(PifScreen.FACEBOOK_SHARE)
        // localhost domains aren't allowed. For ease of testing, we'll go ahead
        // and replace that domain w/ https://aven.com
        link = link.replace('localhost:8081', 'aven.com').replace('http://', 'https://')

        window.logEvent('click_pay_it_forward_facebook_share', {
            link,
        })

        const uriEncodedPifLink = encodeURIComponent(link)
        const facebookShareLink = `https://www.facebook.com/dialog/feed?app_id=${FB_APP_ID}&link=${uriEncodedPifLink}`

        window.open(facebookShareLink, '_blank')
    }
}

export const pifManager = new PifManager()
