<template>
    <card-block
        class="mb-2"
        :title="$t('pages.refiSurvey.cardTitle')"
    >
        <div class="d-flex flex-column">
            <base-button
                class="mt-1"
                @click="goToRefiSurvey"
            >
                Continue
            </base-button>
        </div>
    </card-block>
</template>

<script>
    import CardBlock from '@/components/CardBlock.vue'
    import BaseButton from '@/components/base/BaseButton.vue'
    import { RoutePaths } from '@/routes/router.types'

    export default {
        name: 'RefiSurveyCard',
        components: { CardBlock, BaseButton },
        async mounted() {},
        methods: {
            goToRefiSurvey: async function () {
                this.$logEvent('click_button_refi_survey_card')
                await this.$router.push(RoutePaths.REFI_SURVEY)
            },
        },
    }
</script>
