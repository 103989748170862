import { useCustomerInfoStore } from '@/store/customerInfoStore'

export default {
    methods: {
        showWebWidget: function () {
            const customerInfoStore = useCustomerInfoStore()
            this.$logEvent('view_support_form_button')
            if (!window.zE) {
                return
            }
            window.zE('webWidget', 'show')
            window.zE('webWidget', 'open')
            window.zE('webWidget', 'prefill', {
                name: {
                    value: customerInfoStore.fullName,
                },
                email: {
                    value: customerInfoStore.email,
                },
            })
        },
    },
}
