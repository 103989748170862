"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SweepstakesV2AttemptedEntryErrorType = exports.SweepstakesType = exports.SweepstakesV2Type = exports.SweepstakesOriginalType = exports.SweepstakesStatus = void 0;
var SweepstakesStatus;
(function (SweepstakesStatus) {
    SweepstakesStatus["OPEN"] = "OPEN";
    SweepstakesStatus["CLOSED"] = "CLOSED";
})(SweepstakesStatus = exports.SweepstakesStatus || (exports.SweepstakesStatus = {}));
exports.SweepstakesOriginalType = {
    CASH: 'CASH',
    MORTGAGE: 'MORTGAGE',
    PLAID_TRANSACTION: 'PLAID_TRANSACTION', // means we are paying for people's plaid transactions up to some cap
};
exports.SweepstakesV2Type = {
    MORTGAGE_PAYMENT: 'MORTGAGE_PAYMENT',
    HOME_PAYMENT: 'HOME_PAYMENT',
};
exports.SweepstakesType = {
    ...exports.SweepstakesOriginalType,
    ...exports.SweepstakesV2Type,
};
var SweepstakesV2AttemptedEntryErrorType;
(function (SweepstakesV2AttemptedEntryErrorType) {
    SweepstakesV2AttemptedEntryErrorType["NO_VALID_SWEETSTAKES"] = "NO_VALID_SWEETSTAKES";
    SweepstakesV2AttemptedEntryErrorType["NO_PLAID_CONNECTED"] = "NO_PLAID_CONNECTED";
    SweepstakesV2AttemptedEntryErrorType["NO_HOME_OWNERSHIP"] = "NO_HOME_OWNERSHIP";
    SweepstakesV2AttemptedEntryErrorType["INELIGIBLE_STATE"] = "INELIGIBLE_STATE";
    SweepstakesV2AttemptedEntryErrorType["UNKNOWN"] = "UNKNOWN";
})(SweepstakesV2AttemptedEntryErrorType = exports.SweepstakesV2AttemptedEntryErrorType || (exports.SweepstakesV2AttemptedEntryErrorType = {}));
