<template>
    <div v-if="isDelinquent && !isAccountChargedOff">
        <div
            class="banner banner-delinquent m-0 mb-2"
            data-testid="what-is-due-banner"
        >
            <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
                <p class="mb-0">
                    {{ $t(`pages.WhatIsDue.banner.mainContent`, { amountPastDue: toFormattedUSD(this.amountPastDue) }) }}
                    <router-link
                        class="text-underline"
                        :to="{
                            name: RouteNames.MAKE_PAYMENT,
                            query: {
                                schedulePayment: true,
                            },
                        }"
                        data-testid="what-is-due-banner-pay-now-link"
                    >
                        {{ $t(`pages.WhatIsDue.banner.payNow`) }}
                    </router-link>
                </p>
            </div>
        </div>

        <!-- DUE DATE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.dueBy') }}
            </p>
            <p class="fw-bold mb-0">
                {{ paymentDueDateFormatted }}
            </p>
        </div>

        <!-- MINIMUM DUE (incl. past due) -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.dqMinimumDue') }}
            </p>
            <p class="fw-bold mb-0">
                {{ minimumDue | dollar }}
            </p>
        </div>

        <!-- SCHEDULED PAYMENT / AUTOPAY CARD -->
        <div
            v-if="nextAutopayOrScheduledPayment"
            class="gray-div mb-1 mt-2"
        >
            <p
                v-if="nextAutopayOrScheduledPayment.isAutopay"
                class="mb-0 text-gray-400"
            >
                {{
                    nextAutopayOrScheduledPayment.paymentAmount > 0
                        ? $t('pages.WhatIsDue.nextAutopayPayment', {
                            autopayAmount: nextAutopayOrScheduledPayment.paymentAmount,
                            formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                        })
                        : $t('pages.WhatIsDue.nextAutopayPaymentNoAmount', { formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate })
                }}
            </p>
            <p
                v-else
                class="mb-0 text-gray-400"
            >
                {{
                    $t('pages.WhatIsDue.nextScheduledPayment', {
                        scheduledPaymentAmount: nextAutopayOrScheduledPayment.paymentAmount,
                        formattedPaymentDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                    })
                }}
            </p>
        </div>

        <!-- DETAILS AND PAY NOW BUTTONS -->
        <div class="row g-2">
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    :to="{
                        name: RouteNames.MAKE_PAYMENT,
                        query: {
                            schedulePayment: true,
                        },
                    }"
                    class="btn btn-secondary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.details') }}
                </router-link>
            </div>
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    :to="{
                        name: RouteNames.MAKE_PAYMENT,
                        query: {
                            schedulePayment: true,
                        },
                    }"
                    class="btn btn-primary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.payNow') }}
                </router-link>
            </div>
        </div>
    </div>
    <div v-else-if="hasMinimumDue">
        <!-- DUE DATE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.dueBy') }}
            </p>
            <p class="fw-bold mb-0">
                {{ paymentDueDateFormatted }}
            </p>
        </div>

        <!-- MINIMUM DUE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.minimumDue') }}
            </p>
            <p class="fw-bold mb-0">
                {{ minimumDue | dollar }}
            </p>
        </div>

        <!-- STATEMENT BALANCE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.statementBalance') }}
            </p>
            <p class="fw-bold mb-0">
                {{ statementRemainingBalance | dollar }}
            </p>
        </div>

        <!-- SCHEDULED PAYMENT / AUTOPAY CARD -->
        <div
            v-if="nextAutopayOrScheduledPayment"
            class="gray-div mb-1 mt-2"
        >
            <p
                v-if="nextAutopayOrScheduledPayment.isAutopay"
                class="mb-0 text-gray-400"
            >
                {{
                    nextAutopayOrScheduledPayment.paymentAmount > 0
                        ? $t('pages.WhatIsDue.nextAutopayPayment', {
                            autopayAmount: nextAutopayOrScheduledPayment.paymentAmount,
                            formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                        })
                        : $t('pages.WhatIsDue.nextAutopayPaymentNoAmount', { formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate })
                }}
            </p>
            <p
                v-else
                class="mb-0 text-gray-400"
            >
                {{
                    $t('pages.WhatIsDue.nextScheduledPayment', {
                        scheduledPaymentAmount: nextAutopayOrScheduledPayment.paymentAmount,
                        formattedPaymentDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                    })
                }}
            </p>
        </div>

        <!-- DETAILS AND PAY NOW BUTTONS -->
        <div class="row g-2">
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    to="makePayment"
                    class="btn btn-secondary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.details') }}
                </router-link>
            </div>
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    to="makePayment"
                    class="btn btn-primary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.payNow') }}
                </router-link>
            </div>
        </div>
    </div>
    <div v-else>
        <!-- STATEMENT BALANCE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.remainingStatementBalance') }}
            </p>
            <p class="fw-bold mb-0">
                {{ statementRemainingBalance | dollar }}
            </p>
        </div>

        <!-- NEXT STATEMENT DATE -->
        <div class="d-flex justify-content-between mb-1">
            <p class="mb-0">
                {{ $t('pages.WhatIsDue.nextStatementDate') }}
            </p>
            <p class="fw-bold mb-0">
                {{ nextStateDateFormatted }}
            </p>
        </div>

        <!-- SCHEDULED PAYMENT / AUTOPAY CARD -->
        <div
            v-if="nextAutopayOrScheduledPayment"
            class="gray-div mb-1 mt-2"
        >
            <p
                v-if="nextAutopayOrScheduledPayment.isAutopay"
                class="mb-0 text-gray-400"
            >
                {{
                    nextAutopayOrScheduledPayment.paymentAmount > 0
                        ? $t('pages.WhatIsDue.nextAutopayPayment', {
                            autopayAmount: nextAutopayOrScheduledPayment.paymentAmount,
                            formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                        })
                        : $t('pages.WhatIsDue.nextAutopayPaymentNoAmount', { formattedAutopayDate: nextAutopayOrScheduledPayment.scheduledPaymentDate })
                }}
            </p>
            <p
                v-else
                class="mb-0 text-gray-400"
            >
                {{
                    $t('pages.WhatIsDue.nextScheduledPayment', {
                        scheduledPaymentAmount: nextAutopayOrScheduledPayment.paymentAmount,
                        formattedPaymentDate: nextAutopayOrScheduledPayment.scheduledPaymentDate,
                    })
                }}
            </p>
        </div>

        <!-- DETAILS AND PAY NOW BUTTONS -->
        <div class="row g-2">
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    to="statements"
                    class="btn btn-secondary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.details') }}
                </router-link>
            </div>
            <div class="col pe-1 position-relative d-flex">
                <router-link
                    to="makePayment"
                    class="btn btn-secondary mt-1 w-100"
                >
                    {{ $t('pages.WhatIsDue.makePayment') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { logger } from '../utils/logger'
    import generic from '../utils/generic'
    import { DISPLAY_DATE_FORMAT, NYC_TIMEZONE } from '../data/constants'
    import { toFormattedUSD } from '../mixins/format'
    import { useOverviewStore } from '@/store/overviewStore'
    import { RouteNames } from '@/routes/router.types'
    import { AutopayCategory } from '@/store/overviewStore.types'

    export default {
        name: 'WhatIsDue',
        methods: {
            toFormattedUSD,
            formatDateUtc(date) {
                return generic.utcToTimeZone(date, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
            },
        },
        computed: {
            paymentDueDateFormatted() {
                if (this.paymentDueDate) {
                    logger.info(`convert payment due date from ${this.paymentDueDate}`)
                    return generic.utcToTimeZone(this.paymentDueDate, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
                }
                return ''
            },
            nextStateDateFormatted() {
                if (this.nextStatementDate) {
                    logger.info('convert next statement date')
                    return generic.utcToTimeZone(this.nextStatementDate)
                }
                return ''
            },
            hasMinimumDue() {
                return this.minimumDue > 0
            },
            amountPastDue() {
                return useOverviewStore().amountPastDue
            },
            minimumDue() {
                return useOverviewStore().minimumDue
            },
            statementRemainingBalance() {
                return useOverviewStore().statementRemainingBalance
            },
            paymentDueDate() {
                return useOverviewStore().paymentDueDate
            },
            autopayNextPaymentDisplayDate() {
                return useOverviewStore().autopayNextPaymentDisplayDate
            },
            nextStatementDate() {
                return useOverviewStore().nextStatementDate
            },
            isDelinquent() {
                return useOverviewStore().daysDelinquent > 0
            },
            isAccountChargedOff() {
                return useOverviewStore().isAccountChargedOff
            },
            scheduledPayments() {
                return useOverviewStore().scheduledPayments
            },
            autoPaySetting() {
                return useOverviewStore().autoPaySetting
            },
            nextAutopayPayment() {
                if (!this.autoPaySetting.isEnabled) {
                    return null
                }
                let paymentAmount
                if (this.autoPaySetting.autoPayDetails.autoPayCategory === AutopayCategory.MINIMUM_DUE) {
                    paymentAmount = this.minimumDue
                } else if (this.autoPaySetting.autoPayDetails.autoPayCategory === AutopayCategory.STATEMENT_BALANCE) {
                    paymentAmount = this.statementRemainingBalance
                } else if (this.autoPaySetting.autoPayDetails.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT) {
                    paymentAmount = this.autoPaySetting.autoPayDetails.customAmount
                }
                return {
                    paymentAmount,
                    scheduledPaymentDate: this.formatDateUtc(this.autopayNextPaymentDisplayDate),
                    isAutopay: true,
                }
            },
            nextScheduledPayment() {
                if (this.scheduledPayments.length === 0) {
                    return null
                }
                return {
                    paymentAmount: this.scheduledPayments[0].paymentAmount,
                    scheduledPaymentDate: this.formatDateUtc(this.scheduledPayments[0].scheduledPaymentDate),
                    isAutopay: false,
                }
            },
            nextAutopayOrScheduledPayment() {
                if (!this.nextScheduledPayment && !this.nextAutopayPayment) {
                    return null
                } else if (!this.nextScheduledPayment) {
                    return this.nextAutopayPayment
                } else if (!this.nextAutopayPayment) {
                    return this.nextScheduledPayment
                } else {
                    if (this.nextAutopayPayment.scheduledPaymentDate < this.nextScheduledPayment.scheduledPaymentDate) {
                        return this.nextAutopayPayment
                    } else {
                        return this.nextScheduledPayment
                    }
                }
            },
            RouteNames() {
                return RouteNames
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/transactionRow';
    @import 'node_modules/aven_shared/src/styles/components/banner';

    .banner {
        max-width: 640px;
        margin: 0;
    }

    .gray-div {
        background-color: $gray-100;
        border-radius: 8px;
        padding: 16px;
    }
</style>
