"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenAdvisorLienReportStatus = void 0;
var AvenAdvisorLienReportStatus;
(function (AvenAdvisorLienReportStatus) {
    AvenAdvisorLienReportStatus["FOUND"] = "FOUND";
    AvenAdvisorLienReportStatus["NOT_FOUND"] = "NOT_FOUND";
    AvenAdvisorLienReportStatus["ERROR"] = "ERROR";
    AvenAdvisorLienReportStatus["PENDING"] = "PENDING";
})(AvenAdvisorLienReportStatus = exports.AvenAdvisorLienReportStatus || (exports.AvenAdvisorLienReportStatus = {}));
