import Fingerprint2 from 'fingerprintjs2'

export const generateFingerprint = async (): Promise<string> => {
    const options = {
        // https://github.com/fingerprintjs/fingerprintjs2/wiki/Browser-independent-components
        excludes: {
            plugins: true,
            localStorage: true,
            adBlock: true,
            screenResolution: true,
            availableScreenResolution: true,
            enumerateDevices: true,
            pixelRatio: true,
            doNotTrack: true,
            userAgent: true,
        },
    }
    const components = await Fingerprint2.getPromise(options)
    const values = components.map((component) => component.value)
    return Fingerprint2.x64hash128(values.join(''), 31)
}
