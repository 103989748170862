"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringTransactionFrequency = void 0;
// copied from Plaid's NodeJS type
var RecurringTransactionFrequency;
(function (RecurringTransactionFrequency) {
    RecurringTransactionFrequency["Unknown"] = "UNKNOWN";
    RecurringTransactionFrequency["Weekly"] = "WEEKLY";
    RecurringTransactionFrequency["Biweekly"] = "BIWEEKLY";
    RecurringTransactionFrequency["SemiMonthly"] = "SEMI_MONTHLY";
    RecurringTransactionFrequency["Monthly"] = "MONTHLY";
    RecurringTransactionFrequency["Annually"] = "ANNUALLY";
})(RecurringTransactionFrequency = exports.RecurringTransactionFrequency || (exports.RecurringTransactionFrequency = {}));
