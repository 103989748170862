<template>
    <div class="text-center">
        <div class="justify-content-md-center">
            <loading-indicator
                :title="loadingTitle"
                :content="loadingSubtitle"
                v-if="loading"
            />
            <div
                v-else
                class="mt-3"
            >
                <img
                    :src="require(`../assets/${this.imagePathInAssets}`)"
                    :width="imageWidth"
                    class="mb-3"
                    :alt="$t('accessibility.error')"
                >
                <h5
                    v-show="showTitle"
                    class="mb-2"
                >
                    {{ title }}
                </h5>
                <h5
                    class="mb-3 mx-lg-3"
                    v-show="showDescription"
                    v-html="description"
                />
                <base-button
                    v-if="cta"
                    type="submit"
                    :submitting="false"
                    @click="$emit('click')"
                    data-testid="error-view-submit-button"
                >
                    {{ cta }}
                </base-button>
                <div class="mt-1">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import BaseButton from '@/components/base/BaseButton'
    import LoadingIndicator from '@/components/LoadingIndicator.vue'

    export default {
        name: 'ErrorView',
        components: {
            'loading-indicator': LoadingIndicator,
            BaseButton,
        },
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            loadingTitle: {
                type: String,
                required: false,
            },
            loadingSubtitle: {
                type: String,
                required: false,
            },
            imagePathInAssets: {
                type: String,
                default: 'error-icon.svg',
            },
            imageWidth: {
                type: Number,
                default: 112,
            },
            showTitle: {
                type: Boolean,
                default: true,
            },
            title: {
                type: String,
                default: i18n.t('global.errors.title'),
            },
            showDescription: {
                type: Boolean,
                default: true,
            },
            description: {
                type: String,
                default: i18n.t('global.errors.generic'),
            },
            cta: {
                type: String,
            },
        },
    }
</script>
