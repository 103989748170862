import VueI18n from 'vue-i18n'
import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'

export const formInputMixin = {
    props: {
        name: { type: String, required: true },
        inputType: { type: String, default: 'text' },
        inputmode: { type: String, default: 'text' },
        maxLength: { type: Number, default: 128 },
        value: { type: String, default: null },
        validation: { type: String, default: null },
        label: { type: String | typeof VueI18n.prototype.t, default: null },
        formClasses: { type: String, default: null },
        placeholder: { type: String, default: null },
        focusPlaceholder: { type: String, default: null },
        autocomplete: { type: String, default: null },
        autofocus: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        filter: {
            type: String,
            validator: function (value) {
                return ['uppercase', 'lowercase'].indexOf(value) !== -1
            },
        },
        validationMode: {
            type: String,
            default: 'lazy',
            validator: function (value) {
                return ['eager', 'aggressive', 'passive', 'lazy'].indexOf(value) !== -1
            },
        },
    },
    components: {
        'validation-provider': ValidationProvider,
    },
    data: function () {
        return {
            dynamicPlaceholder: this.placeholder,
        }
    },
    mounted: function () {
        // Shows a different placeholder on focus
        if (this.focusPlaceholder) {
            const _this = this
            document.getElementById(this.name).onfocus = function () {
                _this.dynamicPlaceholder = _this.focusPlaceholder
            }

            document.getElementById(this.name).onblur = function () {
                _this.dynamicPlaceholder = _this.placeholder
            }
        }

        if (this.autofocus) {
            this.setFocus()
        }
    },
    methods: {
        onInput: function (value) {
            let filteredValue = value
            if (this.filter === 'uppercase') {
                filteredValue = filteredValue.toUpperCase()
            }
            if (this.filter === 'lowercase') {
                filteredValue = filteredValue.toLowerCase()
            }
            this.$emit('input', filteredValue)
        },
        blur: function () {
            this.$refs.formFieldInput.blur()
        },
        setFocus: function () {
            this.$refs.formFieldInput.focus()
        },
        applyError: function (errorMessage) {
            this.$refs.provider.applyResult({
                errors: [errorMessage], // array of string errors
                valid: false, // boolean state
                failedRules: {}, // should be empty since this is a manual error.
            })
        },
        onFocus(eventTarget) {
            this.$emit('focus', eventTarget.value)
        },
        onBlur(eventTarget) {
            this.$emit('blur', eventTarget.value)
            const logEvent = this.$logEvent
            logEvent('event_field_interaction', {
                type: 'blur',
                value: eventTarget.value || '',
                name: eventTarget.name || '',
            })
        },
        onClick(event) {
            this.$emit('click', event)
        },
    },
}

export const formInputCleaveMixin = {
    props: {
        name: { type: String, required: true },
        value: { type: String, default: null },
        validation: { type: String, default: null },
        label: { type: String, default: null },
        formClasses: { type: String, default: null },
        placeholder: { type: String, default: null },
        focusPlaceholder: { type: String, default: null },
        autocomplete: { type: String, default: null },
        loading: { type: Boolean, default: false },
        validationMode: {
            type: String,
            default: 'lazy',
            validator: function (value) {
                return ['eager', 'aggressive', 'passive', 'lazy'].indexOf(value) !== -1
            },
        },
    },
    components: {
        cleave: Cleave,
        'validation-provider': ValidationProvider,
    },
    created: function () {
        this.cleaveValue = this.value
    },
    data: function () {
        return {
            cleaveValue: null,
            dynamicPlaceholder: this.placeholder,
        }
    },
    watch: {
        cleaveValue: function (value) {
            this.$emit('input', value)
        },
    },
    methods: {
        setFocus: function () {
            this.$refs.formFieldInput.$el.focus()
        },
        clearValue: function () {
            this.cleaveValue = null
        },
    },
}
