"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppsFlyerRawDataReportAdditionalField = exports.AppsFlyerApiType = exports.AppsFlyerAppId = void 0;
var AppsFlyerAppId;
(function (AppsFlyerAppId) {
    AppsFlyerAppId["avenAdvisorAndroid"] = "com.aven.advisor";
    AppsFlyerAppId["avenAdvisorIos"] = "id1597186683";
})(AppsFlyerAppId = exports.AppsFlyerAppId || (exports.AppsFlyerAppId = {}));
var AppsFlyerApiType;
(function (AppsFlyerApiType) {
    AppsFlyerApiType["installsReport"] = "installs_report";
    AppsFlyerApiType["inAppEventsReport"] = "in_app_events_report";
    AppsFlyerApiType["organicInstallsReport"] = "organic_installs_report";
    AppsFlyerApiType["organicInAppEventsReport"] = "organic_in_app_events_report";
})(AppsFlyerApiType = exports.AppsFlyerApiType || (exports.AppsFlyerApiType = {}));
var AppsFlyerRawDataReportAdditionalField;
(function (AppsFlyerRawDataReportAdditionalField) {
    AppsFlyerRawDataReportAdditionalField["conversionType"] = "conversion_type";
    AppsFlyerRawDataReportAdditionalField["gpClickTime"] = "gp_click_time";
    AppsFlyerRawDataReportAdditionalField["matchType"] = "match_type";
    AppsFlyerRawDataReportAdditionalField["gpInstallBegin"] = "gp_install_begin";
    AppsFlyerRawDataReportAdditionalField["campaignType"] = "campaign_type";
    AppsFlyerRawDataReportAdditionalField["deviceDownloadTime"] = "device_download_time";
    AppsFlyerRawDataReportAdditionalField["deviceModel"] = "device_model";
    AppsFlyerRawDataReportAdditionalField["segment"] = "segment";
    AppsFlyerRawDataReportAdditionalField["isLat"] = "is_lat";
    AppsFlyerRawDataReportAdditionalField["gpReferrer"] = "gp_referrer";
    AppsFlyerRawDataReportAdditionalField["deviceCategory"] = "device_category";
    AppsFlyerRawDataReportAdditionalField["networkAccountId"] = "network_account_id";
})(AppsFlyerRawDataReportAdditionalField = exports.AppsFlyerRawDataReportAdditionalField || (exports.AppsFlyerRawDataReportAdditionalField = {}));
