import { logger } from '@/utils/logger'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { RoutePaths } from '@/routes/router.types'
import payItForwardMixin from '@/mixins/payItForwardMixin'
import { isAndroid, isIOS } from '@/utils/mobileUtils'

export enum GoToPayItForwardCaller {
    PifPill = 'PifPill',
    PifCardArrow = 'PifCardArrow',
    SuperPifCard = 'SuperPifCard',
    PifHomeCardItem = 'PifHomeCardItem',
}

const callerNameToEventNameMap = {
    [GoToPayItForwardCaller.PifPill]: 'click_pif_pill',
    [GoToPayItForwardCaller.PifCardArrow]: 'click_pif_card_arrow',
    [GoToPayItForwardCaller.SuperPifCard]: 'click_super_pif_card',
    [GoToPayItForwardCaller.PifHomeCardItem]: 'click_pif_home_card_item',
} as const

export default {
    mixins: [payItForwardMixin],
    methods: {
        goToPayItForward: async function (callerName: GoToPayItForwardCaller, pageName?: string) {
            window.logEvent(callerNameToEventNameMap[callerName], { pageName })
            logger.log(`Aven My in webview: ${useDeviceInfoStore().isWebView}, routing to ${RoutePaths.PAY_IT_FORWARD_CONTACT_LIST}`)

            if (isAndroid() || isIOS()) {
                await this.$router.push(RoutePaths.PAY_IT_FORWARD_CONTACT_LIST)
            } else {
                await this.$router.push(RoutePaths.PAY_IT_FORWARD)
            }
        },
    },
}
