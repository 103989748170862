import Vue from 'vue'
import throttle from 'lodash/throttle'

export default Vue.extend({
    methods: {
        throttleLogging: throttle((inputValue, logPrefix = ''): any => {
            if (inputValue) {
                // @ts-ignore TODO: figure this out, should throw if it doesn't exist. should mock if in storybook.
                if (this && this.$logger) {
                    // @ts-ignore
                    this.$logger.info(`${logPrefix}: ${inputValue}`)
                }
            }
        }, 1000),
        throttleAnalytics: throttle((eventName, eventProps): any => {
            if (eventName) {
                // @ts-ignore
                window.logEvent(eventName, eventProps)
            }
        }, 4000),
    },
})
