"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.guaranteedForeclosureProtectionStatusProgression = exports.GuaranteedForeclosureProtectionStatus = void 0;
/**
 * Mutually Exclusive Collectively Exhaustive status for Guaranteed Foreclosure Protection
 *     - Not eligible (they don't fulfill requirements)
 *     - Eligible (they fulfill requirements)
 *       - Opted out
 *       - Opted in
 *         - Lost job
 *           - Denied
 *           - Active
 */
var GuaranteedForeclosureProtectionStatus;
(function (GuaranteedForeclosureProtectionStatus) {
    GuaranteedForeclosureProtectionStatus["notEligible"] = "notEligible";
    GuaranteedForeclosureProtectionStatus["eligible"] = "eligible";
    GuaranteedForeclosureProtectionStatus["optedOut"] = "optedOut";
    GuaranteedForeclosureProtectionStatus["optedIn"] = "optedIn";
    GuaranteedForeclosureProtectionStatus["lostJob"] = "lostJob";
    GuaranteedForeclosureProtectionStatus["denied"] = "denied";
    GuaranteedForeclosureProtectionStatus["active"] = "active";
})(GuaranteedForeclosureProtectionStatus = exports.GuaranteedForeclosureProtectionStatus || (exports.GuaranteedForeclosureProtectionStatus = {}));
exports.guaranteedForeclosureProtectionStatusProgression = [
    GuaranteedForeclosureProtectionStatus.notEligible,
    GuaranteedForeclosureProtectionStatus.eligible,
    GuaranteedForeclosureProtectionStatus.optedOut,
    GuaranteedForeclosureProtectionStatus.optedIn,
    GuaranteedForeclosureProtectionStatus.lostJob,
    GuaranteedForeclosureProtectionStatus.denied,
    GuaranteedForeclosureProtectionStatus.active,
];
