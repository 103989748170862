import { defineStore } from 'pinia'
import { loadInstantBalanceTransferFmpSweepOffer, getAvailableInstantBalanceTransfers } from '@/services/api'
import { logger } from '@/utils/logger'
import { openReplay } from '@/utils/openReplay'
import { InstantBalanceTransferOption } from 'aven_types'

export const usePersonalFinancialLiabilityStore = () => {
    const personalFinancialLiabilityStore = defineStore('personalFinancialLiabilityStore', {
        state: () => {
            return {
                instantBalanceTransfer: {
                    instantBalanceTransferOptions: [],
                    instantBalanceTransferFmpSweepOffer: null,
                },
            }
        },
        getters: {
            instantBalanceTransferOptions: (state) => state.instantBalanceTransfer.instantBalanceTransferOptions,
            instantBalanceTransferFmpSweepOffer: (state) => state.instantBalanceTransfer.instantBalanceTransferFmpSweepOffer,
        },
        actions: {
            updateInstantBalanceTransferOption(updatedOption: InstantBalanceTransferOption) {
                logger.info(`Updating instant balance transfer option w/ personal financial liability ID ${updatedOption.methodfiAccountId} to: ${JSON.stringify(updatedOption)}`)
                this.instantBalanceTransfer.instantBalanceTransferOptions = this.instantBalanceTransfer.instantBalanceTransferOptions.map((option) => {
                    if (updatedOption.methodfiAccountId === option.methodfiAccountId) {
                        return updatedOption
                    }
                    return option
                })
            },
            async getInstantBalanceTransferOptions() {
                try {
                    logger.info(`Getting instant balance transfer options`)
                    const response = await getAvailableInstantBalanceTransfers()
                    if (!response.data.success) {
                        logger.error(`getAvailableInstantBalanceTransfers failed with ${response.data.error}`)
                        return
                    }
                    this.instantBalanceTransfer.instantBalanceTransferOptions = response.data.payload
                    logger.info(`Got instant balance transfer options ${JSON.stringify(this.instantBalanceTransfer.instantBalanceTransferOptions)}`)
                } catch (e) {
                    logger.error(`Failed to get instant balance transfer options: ${e.message}`)
                    this.instantBalanceTransfer.instantBalanceTransferOptions = []
                }
            },
            getInstantBalanceTransferOption(methodfiAccountId: string) {
                return this.instantBalanceTransfer.instantBalanceTransferOptions.find((option) => {
                    return option.methodfiAccountId === methodfiAccountId
                })
            },
            async loadInstantBalanceTransferFmpSweepOffer() {
                this.instantBalanceTransfer.instantBalanceTransferFmpSweepOffer = null
                try {
                    logger.info(`Loading instant BT FMP sweep offer`)
                    const response = await loadInstantBalanceTransferFmpSweepOffer()
                    if (!response.data.success || !response.data.payload) {
                        logger.info(`loadInstantBalanceTransferFmpSweepOffer did not return an offer`)
                        return
                    }
                    this.instantBalanceTransfer.instantBalanceTransferFmpSweepOffer = response.data.payload
                    logger.info(`Loaded instant balance transfer FMP sweep offer ${JSON.stringify(this.instantBalanceTransfer.instantBalanceTransferFmpSweepOffer)}`)
                } catch (e) {
                    logger.error(`Failed to get instant balance transfer FMP sweep options: ${e.message}`)
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(personalFinancialLiabilityStore)
    return personalFinancialLiabilityStore
}
