import { useGlobalUiStore } from '@/store/globalUiStore'
import * as Sentry from '@sentry/browser'
import inspect from 'object-inspect'

/** When the API returns success = false */
export const API_ERROR = 'API_ERROR'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const SERVER_RESPONSE_ERROR = 'SERVER_RESPONSE_ERROR'

export const parseError = (error) => {
    const globalUiStore = useGlobalUiStore()
    if (error.response) {
        globalUiStore.error = SERVER_RESPONSE_ERROR
    } else if (error.request) {
        globalUiStore.error = NETWORK_ERROR
    } else {
        Sentry.captureException(inspect(error))
    }
}
