<template>
    <div class="small-loading-indicator">
        <svg
            class="spinner"
            viewBox="0 0 24 24"
        >
            <circle
                class="path"
                cx="12"
                cy="12"
                r="10"
                fill="none"
                stroke-width="2"
            />
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'SmallLoadingIndicator',
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/smallLoadingIndicator.scss';
</style>
