<template>
    <div class="">
        <div
            v-if="visibility"
            class="modal fade px-3 questionModal"
            :class="{ show: visibility }"
            :style="{ display: visibility ? 'block' : 'none' }"
        >
            <div
                class="modal-dialog modal-dialog-centered"
                role="document"
            >
                <div class="modal-content position-relative p-3">
                    <div class="position-relative p-0">
                        <img
                            class="mclose"
                            src="../assets/images/global/close.svg"
                            data-dismiss="modal"
                            @click="handleXOutModal"
                            @keydown.enter="handleXOutModal"
                            :alt="$t('accessibility.close')"
                        >
                        <img
                            src="@/assets/images/components/modal/star.svg"
                            class="justify-content-center align-items-center d-flex mx-auto my-3"
                            alt="star"
                        >
                        <h5 class="text-center mb-3">
                            {{ $t('appStoreReviewModal.title') }}
                        </h5>
                        <a
                            class="btn btn-primary w-100"
                            @click="handlePositiveClick"
                            @keydown.enter="handlePositiveClick"
                        >
                            <span style="color: white">{{ $t('appStoreReviewModal.allow') }}</span>
                        </a>
                        <a
                            class="btn btn-secondary w-100 mt-2"
                            @click="handleNegativeClick"
                            @keydown.enter="handleNegativeClick"
                        >
                            <span>{{ $t('appStoreReviewModal.deny') }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { javascriptNativeInterop } from '@/services/javascriptNativeInterop'
    import { logger } from '@/utils/logger'

    export default {
        name: 'AppStoreReviewModal',
        props: {
            visibility: {
                type: Boolean,
                required: true,
                default: false,
            },
        },
        watch: {
            visibility: async function (newVal, oldVal) {
                if (oldVal === false && newVal === true) {
                    this.$logEvent('view_app_store_review_modal')
                    await javascriptNativeInterop.setHasAskedForAppStoreReview()
                }
            },
        },
        methods: {
            closeModal() {
                logger.info(`Closing app store review modal`)
                this.$emit('close')
            },
            handleXOutModal() {
                logger.info(`X-ing out of app store review modal`)
                this.$logEvent('click_button_app_store_review_x')
                this.closeModal()
            },
            async handlePositiveClick() {
                logger.info(`Asking for app store review`)
                this.$logEvent('click_button_app_store_review_yes')
                await javascriptNativeInterop.askForAppStoreReview()
                this.closeModal()
            },
            handleNegativeClick() {
                logger.info(`Not asking for app store review`)
                this.$logEvent('click_button_app_store_review_no')
                this.closeModal()
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appHeader.scss';
    @import 'node_modules/aven_shared/src/styles/components/appBase';
</style>
