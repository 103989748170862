"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenCardProductType = void 0;
var AvenCardProductType;
(function (AvenCardProductType) {
    AvenCardProductType["_NONE"] = "NONE";
    AvenCardProductType["CRYPTO"] = "CRYPTO";
    AvenCardProductType["AUTO"] = "AUTO";
    AvenCardProductType["AUTO_REVOLVING_FIRST"] = "AUTO_REVOLVING_FIRST";
    AvenCardProductType["HOME"] = "HOME";
    AvenCardProductType["HOME_NO_LOYALTY"] = "HOME_NO_LOYALTY";
    AvenCardProductType["HOME_LIMITED_MODE"] = "HOME_LIMITED_MODE";
    AvenCardProductType["HOME_SECURED_PLUS"] = "HOME_SECURED_PLUS";
    AvenCardProductType["ASSET_ATTESTATION"] = "ASSET_ATTESTATION";
})(AvenCardProductType = exports.AvenCardProductType || (exports.AvenCardProductType = {}));
