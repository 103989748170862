import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { logger } from '@/utils/logger'
import { getCreditCardMarketData } from '@/services/avenAppApi'
import assert from 'assert'

interface CreditCardMarketData {
    dateUpdatedString: string
    sourceArticleName: string
    sourceWebsiteLinkText: string
    sourceWebsiteUrl: string
    avgGoodCreditApr: number
    avgNewOffersApr: number
}

export const useCreditCardMarketDataStore = () => {
    const creditCardMarketDataStore = defineStore('creditCardMarketData', {
        state: (): CreditCardMarketData & { updatedOn: Date | null } => {
            // Todo The below are the defaults, they should be updated when
            //  aven_backend/src/manager/creditCardMarketDataManager.ts is updated
            return {
                updatedOn: null,
                dateUpdatedString: 'Jan 15, 2024',
                sourceArticleName: 'Average Credit Card Interest Rates',
                sourceWebsiteLinkText: 'wallethub.com',
                sourceWebsiteUrl: 'https://wallethub.com/edu/cc/average-credit-card-interest-rate/50841',
                avgGoodCreditApr: 0.241,
                avgNewOffersApr: 0.2292,
            }
        },
        actions: {
            async tryLoadCreditCardMarketData() {
                if (this.updatedOn) {
                    logger.info(`Already retrieved credit card market data, not fetching again`)
                    return
                }
                try {
                    logger.info(`Trying to retrieve credit card market data`)
                    const response = await getCreditCardMarketData()
                    assert(response.data.success, 'Unsuccessful calling getCreditCardMarketData')
                    this.$patch(response.data.payload.data)
                    this.updatedOn = new Date()
                    logger.info(`Successfully retrieved credit card market data at ${this.updatedOn}: ${JSON.stringify(this.$state)}`)
                } catch (e) {
                    logger.fatal(`Error loading credit card market data`, e)
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(creditCardMarketDataStore)
    return creditCardMarketDataStore
}
