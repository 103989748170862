export interface PayoffDetails {
    payoffDateInNycTime: Date
    totalCurrentBalance: number
    interestAndFees: number
    totalPayoffAmount: number
    hasCountyRecordingFee: boolean
}

// sync with enum AccountClosureType in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureType {
    PERMANENT_CLOSE = 'permanent_close',
    KEEP_CARD = 'keep_card',
}

// sync with enum AccountClosureRequestSource in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureRequestSource {
    SELF_SERVE = 'self_serve',
    CSR_RETOOL = 'csr_retool',
}

// sync with enum AccountClosureRequester in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureRequester {
    USER = 'user',
    TITLE = 'title',
    ESCROW = 'escrow',
    LENDER = 'lender',
    AVEN = 'aven',
}

// sync with enum AccountClosureReason in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureReason {
    LOWER_MONTHLY_PAYMENTS = 'lower_monthly_payment',
    LOWER_APR = 'lower_apr',
    ACCESS_MORE_HOME_EQUITY = 'access_more_home_equity',
    SELLING_HOME = 'selling_home',
    NOT_HAPPY = 'not_happy',
    NO_REASON_STATED = 'no_reason_stated',
    LINE_SIZE_TOO_LOW = 'line_size_too_low',
    NOT_USE = 'not_use',
    HIGHER_INCOME = 'higher_income',
    OTHER = 'other',
    DEPRECATED_REFI = 'refi',
    DEPRECATED_CASH_OUT_REFI = 'cash_out_refi',

    // for account being detected as fraud, we still need to go through the regular account closure procedure (eg reconveyance, etc)
    FRAUD = 'fraud',
    // account is being closed by aven because contingency was not cleared
    CONTINGENCY_REJECTED = 'contingency_rejected',
}
