"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RebalanceFailureFlag = void 0;
/* @TranslateToPython */
var RebalanceFailureFlag;
(function (RebalanceFailureFlag) {
    RebalanceFailureFlag["reversal"] = "reversal";
    RebalanceFailureFlag["multipleRebalanceableTransactions"] = "multipleRebalanceableTransactions";
    RebalanceFailureFlag["backdatedTransaction"] = "backdatedTransaction";
    RebalanceFailureFlag["existingRebalance"] = "existingRebalance";
    RebalanceFailureFlag["untouchedPlan"] = "untouchedPlan";
    RebalanceFailureFlag["payoffTransaction"] = "payoffTransaction";
})(RebalanceFailureFlag = exports.RebalanceFailureFlag || (exports.RebalanceFailureFlag = {}));
