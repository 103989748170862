import { i18n } from '@/utils/i18n'
import { RouteNames, RoutePaths } from '@/routes/router.types'

export const navigationTabs = [
    {
        tabName: i18n.t('pages.dashboard.Activity'),
        tabRoutePath: RoutePaths.ACTIVITY,
        tabRouteName: RouteNames.ACTIVITY,
        tabIconActive: require('@/assets/images/layouts/dashboard/Activity_Active.svg'),
        tabIconInactive: require('@/assets/images/layouts/dashboard/Activity_Inactive.svg'),
        tabDataTestId: 'activity-button',
    },
    {
        tabName: i18n.t('pages.dashboard.Rewards'),
        tabRoutePath: RoutePaths.REWARDS,
        tabRouteName: RouteNames.REWARDS,
        tabIconActive: require('@/assets/images/layouts/dashboard/Rewards_Active.svg'),
        tabIconInactive: require('@/assets/images/layouts/dashboard/Rewards_Inactive.svg'),
        tabDataTestId: 'rewards-button',
    },
    {
        tabName: i18n.t('pages.dashboard.Statements'),
        tabRoutePath: RoutePaths.STATEMENTS,
        tabRouteName: RouteNames.STATEMENTS,
        tabIconActive: require('@/assets/images/layouts/dashboard/Statements_Active.svg'),
        tabIconInactive: require('@/assets/images/layouts/dashboard/Statements_Inactive.svg'),
        tabDataTestId: 'statements-button',
    },
    {
        tabName: i18n.t('pages.dashboard.Card'),
        tabRoutePath: RoutePaths.CARD,
        tabRouteName: RouteNames.CARD,
        tabIconActive: require('@/assets/images/layouts/dashboard/Card_Active.svg'),
        tabIconInactive: require('@/assets/images/layouts/dashboard/Card_Inactive.svg'),
        tabDataTestId: 'account-button',
    },
]

export const navigationTabRoutePaths = navigationTabs.map((tab) => tab.tabRoutePath)
export const navigationTabRouteNames = navigationTabs.map((tab) => tab.tabRouteName)
