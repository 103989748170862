"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditScoreFactorImpactLevel = exports.CreditScoreFactorRating = void 0;
var CreditScoreFactorRating;
(function (CreditScoreFactorRating) {
    CreditScoreFactorRating["Excellent"] = "Excellent";
    CreditScoreFactorRating["Good"] = "Good";
    CreditScoreFactorRating["Fair"] = "Fair";
    CreditScoreFactorRating["NeedsImprovement"] = "Needs Improvement";
})(CreditScoreFactorRating = exports.CreditScoreFactorRating || (exports.CreditScoreFactorRating = {}));
var CreditScoreFactorImpactLevel;
(function (CreditScoreFactorImpactLevel) {
    CreditScoreFactorImpactLevel["High"] = "High Impact";
    CreditScoreFactorImpactLevel["Medium"] = "Medium Impact";
    CreditScoreFactorImpactLevel["Low"] = "Low Impact";
})(CreditScoreFactorImpactLevel = exports.CreditScoreFactorImpactLevel || (exports.CreditScoreFactorImpactLevel = {}));
