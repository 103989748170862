import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'

export const useSingleUseTokenStore = () => {
    const singleUseTokenStore = defineStore('singleUseToken', {
        state: (): { singleUseToken: string | null } => {
            return {
                singleUseToken: null,
            }
        },
        getters: {},
        actions: {},
    })()
    openReplay.setUpPiniaStoreTracking(singleUseTokenStore)
    return singleUseTokenStore
}
