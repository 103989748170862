import { useOverviewStore } from '@/store/overviewStore'
import { computed } from 'vue'
import { AvenCardProductType } from 'aven_types'

export const useIsUccProduct = () => {
    const isUccProduct = computed(() => {
        const store = useOverviewStore()
        const productType = store.productType
        return productType === AvenCardProductType.ASSET_ATTESTATION
    })

    return { isUccProduct }
}
