// Keep in sync with DeviceCapabilities on the native platforms:
// Android: aven_android/app/src/main/java/com/aven/util/PreferenceHelper.kt
// iOS: aven_ios/avenApp/avenApp/auth/AuthService.swift
export enum DeviceCapabilities {
    ComposeSms = 'ComposeSms',
    SmsEventsSignaling = 'SmsEventsSignaling',
    Biometrics = 'Biometrics',
    PifCanUseContacts = 'PifCanUseContacts',
    CanProvideAndRequestPushPermission = 'CanProvideAndRequestPushPermission',
    CanTogglePullToRefresh = 'CanTogglePullToRefresh',
    CanAskForOriginationPushNotifs = 'CanAskForOriginationPushNotifs',
    CanAskForAppReview = 'CanAskForAppReview',
    NativeAppHandlesContactsProminentDisclosure = 'NativeAppHandlesContactsProminentDisclosure',
    CanHandleAscendaDeepLinks = 'CanHandleAscendaDeepLinks',
    ShouldUseWebNavigationBar = 'ShouldUseWebNavigationBar',
}
