import { useSingleUseTokenStore } from '@/store/singleUseTokenStore'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'

export const startNewCashOutWithCleanSlate = () => {
    const localStorageKeysToClear = [
        localStorageKey.amortizationLoanId,
        localStorageKey.uploadBankStatementId,
        localStorageKey.cashOutId,
        localStorageKey.last4AccountNumber,
        localStorageKey.cashOutAmount,
        localStorageKey.cashOutFeeRatio,
    ]
    logger.info(`Starting new cash out with a clean slate. Resetting single use token & clearing appSessionStorage keys ${JSON.stringify(localStorageKeysToClear)}`)
    useSingleUseTokenStore().$reset()
    for (const localStorageKey of localStorageKeysToClear) {
        appSessionStorage.setItem(localStorageKey, null)
    }
}
