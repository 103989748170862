"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestLinkTokenError = void 0;
var RequestLinkTokenError;
(function (RequestLinkTokenError) {
    /**
     * Aven backend could not communicate w/ Plaid's service due to a socket hang up
     */
    RequestLinkTokenError["socketHangUp"] = "socketHangUp";
    /**
     * If the request to Plaid to retrieve a link token succeeds, but there is no token
     * provided in the response
     */
    RequestLinkTokenError["tokenNotProvided"] = "tokenNotProvided";
    /**
     * An error that we don't explicitly handle yet
     */
    RequestLinkTokenError["genericError"] = "genericError";
})(RequestLinkTokenError = exports.RequestLinkTokenError || (exports.RequestLinkTokenError = {}));
