"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RebalanceType = void 0;
/* @TranslateToPython */
var RebalanceType;
(function (RebalanceType) {
    RebalanceType["Debit"] = "Debit";
    RebalanceType["Credit"] = "Credit";
    RebalanceType["DebitReversal"] = "Debit Reversal";
    RebalanceType["CreditReversal"] = "Credit Reversal";
})(RebalanceType = exports.RebalanceType || (exports.RebalanceType = {}));
