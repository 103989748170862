import { defineStore } from 'pinia'
import { trackClick } from '@/services/avenAppApi'
import { logger } from '@/utils/logger'
import { openReplay } from '@/utils/openReplay'

export const useClickTrackingStore = () => {
    const clickTrackingStore = defineStore('clickTracking', {
        state: () => {
            return {
                link: null as string | null,
            }
        },
        getters: {},
        actions: {
            async maybeTryTrackClick() {
                if (!this.link) {
                    logger.info(`Click tracking store tried to track click, but no link to track`)
                    return
                }
                try {
                    logger.info(`Click tracking store is tracking click on link ${this.link}`)
                    // This is just a "fire and forget" call. Nothing to do with the result
                    await trackClick(this.link)
                    logger.info(`Click tracking store finished tracking click on link ${this.link}`)
                } catch (e) {
                    logger.fatal(`Error tracking Aven My click w/ link ${this.link}`, e)
                } finally {
                    this.$reset()
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(clickTrackingStore)
    return clickTrackingStore
}
