import { defineStore } from 'pinia'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { customerNoticeList, delinquencyNoticeList, statementList, taxDocumentList } from '@/services/avenAppApi'
import { NETWORK_ERROR } from '@/store/storeUtils'
import { openReplay } from '@/utils/openReplay'

export const useDocumentsStore = () => {
    const documentStore = defineStore('documents', {
        state: () => {
            return {
                statements: [],
                taxDocuments: [],
                delinquencyNotices: [],
                customerNotices: [],
            }
        },
        getters: {},
        actions: {
            async getStatements() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await statementList()
                    if (data.success) {
                        this.statements = data.payload
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async getTaxDocuments() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await taxDocumentList()
                    if (data.success) {
                        this.taxDocuments = data.payload
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async getDelinquencyNotices() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await delinquencyNoticeList()
                    if (data.success) {
                        this.delinquencyNotices = data.payload
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async getCustomerNotices() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await customerNoticeList()
                    if (data.success) {
                        this.customerNotices = data.payload
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(documentStore)
    return documentStore
}
