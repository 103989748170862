<template>
    <card-block
        v-if="showPaymentBreakdown"
        :title="$t('pages.PaymentBreakdown.minimumDue')"
    >
        <base-payment-row
            :payment-row-name="$t('pages.PaymentBreakdown.purchases')"
            :amount="paymentBreakdownByPlan.revolvingPlanBreakdown.minimumPrincipalDue"
        />
        <base-payment-row
            :payment-row-name="shouldDisplayFixedPlans ? $t('pages.PaymentBreakdown.interestExcludingFixed') : $t('pages.PaymentBreakdown.interest')"
            :amount="paymentBreakdownByPlan.revolvingPlanBreakdown.interestCharge"
        />
        <base-payment-row
            :payment-row-name="$t('pages.PaymentBreakdown.fees')"
            :amount="paymentBreakdownByPlan.revolvingPlanBreakdown.fees"
        />

        <div v-if="shouldDisplayFixedPlans">
            <hr class="w-100">
            <p class="text-muted mb-1 section-header">
                {{ $t('pages.PaymentBreakdown.fixedMonthlyPayments') }}
            </p>
            <div
                v-for="(siPlan, index) in paymentBreakdownByPlan.simpleInterestPlanBreakdowns"
                :key="index"
            >
                <div class="d-flex mb-0">
                    {{ getPlanNameFromPaymentBreakdown(siPlan) }}
                </div>
                <base-payment-row
                    :payment-row-name="$t('pages.PaymentBreakdown.fixedPlanPrincipal')"
                    :amount="siPlan.minimumPrincipalDue"
                    :indent-row="true"
                />
                <base-payment-row
                    :payment-row-name="$t('pages.PaymentBreakdown.fixedPlanInterest')"
                    :amount="siPlan.interestCharge"
                    :indent-row="true"
                />
            </div>
            <div
                v-for="(ffcPlan, index) in paymentBreakdownByPlan.fixedFinanceChargePlanBreakdowns"
                :key="index"
            >
                <div class="d-flex mb-0 mt-1">
                    {{ getPlanNameFromPaymentBreakdown(ffcPlan) }}
                </div>
                <base-payment-row
                    :payment-row-name="$t('pages.PaymentBreakdown.fixedPlanPrincipal')"
                    :amount="ffcPlan.minimumPrincipalDue"
                    :indent-row="true"
                />
                <base-payment-row
                    :payment-row-name="$t('pages.PaymentBreakdown.fixedFinanceCharge')"
                    :amount="ffcPlan.fixedFinanceCharge"
                    :indent-row="true"
                />
            </div>
            <div
                v-for="(recordingPlan, index) in paymentBreakdownByPlan.stateRecordingChargePlanBreakdown"
                :key="index"
            >
                <div class="d-flex justify-content-between mb-0 mt-1">
                    {{ $t('pages.PaymentBreakdown.stateRecordingCharge') }}
                </div>
                <base-payment-row
                    :payment-row-name="$t('pages.PaymentBreakdown.fixedPlanPrincipal')"
                    :amount="recordingPlan.minimumPrincipalDue"
                    :indent-row="true"
                />
            </div>
        </div>
        <hr class="w-100">
        <div class="d-flex justify-content-between mb-0">
            <p class="mb-0 fw-bold">
                {{ $t('pages.PaymentBreakdown.totalMinimumDue') }}
            </p>
            <p class="mb-0">
                {{ formatAmount(totalMinimumDue) }}
            </p>
        </div>
    </card-block>
</template>

<script>
    import CardBlock from '@/components/CardBlock'
    import generic from '@/utils/generic'
    import BasePaymentRow from '@/components/BasePaymentRow.vue'

    export default {
        name: 'PaymentBreakdownCard',
        components: { BasePaymentRow, CardBlock },
        mixins: [],
        props: {
            paymentBreakdownByPlan: {
                type: Object,
                require: true,
            },
            totalMinimumDue: {
                type: Number,
                default: 0,
                require: true,
            },
        },
        computed: {
            showPaymentBreakdown() {
                return !!this.paymentBreakdownByPlan && this.totalMinimumDue > 0
            },
            shouldDisplayFixedPlans() {
                return (
                    this.paymentBreakdownByPlan.simpleInterestPlanBreakdowns.length > 0 ||
                    this.paymentBreakdownByPlan.fixedFinanceChargePlanBreakdowns.length > 0 ||
                    this.paymentBreakdownByPlan.stateRecordingChargePlanBreakdown.length > 0
                )
            },
        },
        methods: {
            formatAmount(amount) {
                return generic.prettyPrintDollarAmount(amount)
            },
            formatBreakdownAmount(amount) {
                return '+ ' + generic.prettyPrintDollarAmount(amount)
            },

            getPlanNameFromPaymentBreakdown(paymentBreakdown) {
                const amount = this.formatAmount(paymentBreakdown.initialTransactionAmount)
                return amount + ' ' + paymentBreakdown.amortizationLoanPlanType
            },
        },
    }
</script>

<style>
    .fixedPlanDetail {
        margin-left: 20px;
    }
</style>
