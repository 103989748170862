import { defineStore } from 'pinia'
import groupBy from 'lodash/groupBy'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { getActiveBalanceTransferAccountsEligibleForRecurrence, getBalanceTransfers, initiateBalanceTransferForRecurringAccount, updateAutoBalanceTransferEnabledAccounts } from '@/services/api'
import { logger } from '@/utils/logger'
import { NETWORK_ERROR } from '@/store/storeUtils'
import {
    AciPaymentTransactionType,
    BalanceTransferStatus,
    BalanceTransferType,
    IBalanceTransfer,
    IBalanceTransferAccount,
    IInitiatedBalanceTransfer,
} from '@/store/cashOutAndBalanceTransferStore.types'
import { openReplay } from '@/utils/openReplay'

export const useCashOutAndBalanceTransferStore = () => {
    const cashOutAndBalanceTransferStore = defineStore('cashOutAndBalanceTransfer', {
        state: () => {
            return {
                balanceTransfer: {
                    existingTransactions: Object.create(null),
                    activeBalanceTransferAccountsEligibleForRecurrence: [],
                    availableCreditLimit: 0,
                    apr: 0,
                    selectedAccount: {},
                    mostRecentRecurringBalanceTransfer: {},
                } as IBalanceTransfer,
            }
        },
        getters: {
            existingBalanceTransfers: (state) => state.balanceTransfer.existingTransactions['BALANCE_TRANSFERS'],
            existingCashOutTransfers: (state) => state.balanceTransfer.existingTransactions['CASH_OUT'],
        },
        actions: {
            updateBalanceTransferTransactions(payload) {
                // create object group by type (currently only marking CashOuts on backend/everything else is a balance transfer)
                const transactionType = groupBy(payload, (transaction) => {
                    if (transaction.type === BalanceTransferType.CashOut) {
                        return 'CASH_OUT'
                    } else {
                        return 'BALANCE_TRANSFER'
                    }
                })

                // util func to group by year
                const groupObjByYear = (objToGroupBy) =>
                    groupBy(objToGroupBy, (transaction) => {
                        const isReversal = transaction.transactionType === AciPaymentTransactionType.REVERSAL
                        const wasSentToCoreCard = [BalanceTransferStatus.Completed, BalanceTransferStatus.CoreCardSubmissionSuccess].includes(transaction.status)
                        if (isReversal || wasSentToCoreCard) {
                            return new Date(transaction.initiationDate).getFullYear().toString()
                        } else {
                            return 'PENDING'
                        }
                    })

                // util func to ensure that PENDING items are listed first
                const createTransactionMap = (objToMap) =>
                    new Map(
                        [...Object.entries(objToMap)].sort((left, right) => {
                            if (left[0] === 'PENDING') {
                                return -1
                            } else if (right[0] === 'PENDING') {
                                return 1
                            } else {
                                return left[0] < right[0] ? 1 : left[0] > right[0] ? -1 : 0
                            }
                        })
                    )

                const balanceTransfersByYear = groupObjByYear(transactionType['BALANCE_TRANSFER'])
                const cashOutsByYear = groupObjByYear(transactionType['CASH_OUT'])

                this.balanceTransfer.existingTransactions = {
                    BALANCE_TRANSFERS: createTransactionMap(balanceTransfersByYear),
                    CASH_OUT: createTransactionMap(cashOutsByYear),
                }
            },
            async getExistingBalanceTransfers() {
                const globalUiStore = useGlobalUiStore()
                try {
                    const { data } = await getBalanceTransfers()
                    if (data.success) {
                        this.updateBalanceTransferTransactions(data.payload)
                    } else {
                        // TODO: add error state
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                }
            },
            async getActiveBalanceTransferAccountsEligibleForRecurrence() {
                try {
                    logger.info(`Getting active balance transfer accounts`)
                    const activeAccountsResponse = await getActiveBalanceTransferAccountsEligibleForRecurrence()
                    const accounts = activeAccountsResponse.data.payload.activeBalanceTransferAccounts
                    logger.info(`Got active balance transfer accounts ${JSON.stringify(accounts)}`)
                    this.balanceTransfer.activeBalanceTransferAccountsEligibleForRecurrence = accounts
                } catch (e) {
                    logger.error(`Failed to get active balance transfer accounts: ${e.message}`)
                    this.balanceTransfer.activeBalanceTransferAccountsEligibleForRecurrence = []
                }
            },
            async updateAutoBalanceTransferEnabledForAccounts(payload: IBalanceTransferAccount[]) {
                logger.info(`Updating auto balance transfer enabled for accounts: ${JSON.stringify(payload)}`)
                await updateAutoBalanceTransferEnabledAccounts(payload)
                logger.info(`Updated auto balance transfer enabled`)
                await this.getActiveBalanceTransferAccountsEligibleForRecurrence()
            },
            async balanceTransferForRecurringAccount(payload: IInitiatedBalanceTransfer) {
                logger.info(`Initiating balance transfer for recurring account ${JSON.stringify(payload)}`)
                await initiateBalanceTransferForRecurringAccount({
                    token: payload.token,
                    amount: payload.amount,
                })
                logger.info(`Updating most recent recurring balance transfer to ${JSON.stringify(payload)}`)
                this.balanceTransfer.mostRecentRecurringBalanceTransfer = payload
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(cashOutAndBalanceTransferStore)
    return cashOutAndBalanceTransferStore
}
