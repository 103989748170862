<template>
    <div :class="['card', { 'py-3 py-md-4': !noVerticalPadding }, { 'px-3 px-md-4': !noHorizontalPadding }]">
        <h6
            v-if="title"
            class="fw-bold mb-2"
        >
            {{ title }}
        </h6>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'CardBlock',
        props: {
            title: {
                type: String,
                required: false,
            },
            noHorizontalPadding: {
                type: Boolean,
                required: false,
                default: false,
            },
            noVerticalPadding: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/cardBlock';
</style>
