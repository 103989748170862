import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { logger } from '@/utils/logger'
import { getEstimatedAvenSavings } from '@/services/avenAppApi'
import { useOverviewStore } from '@/store/overviewStore'
import { roundToNDecimalPlaces } from '@/utils/numberUtil'
import assert from 'assert'

/**
 * There's some min threshold of savings below which it doesn't make sense to display
 * the card celebrating the estimated savings. We've made the call to go with $20
 * as that minimum for now.
 */
const MIN_ESTIMATED_YEARLY_SAVINGS_WORTH_SHOWING_DOLLARS = 20

interface EstimatedSavings {
    currentBalanceDollars: number | null
    estimatedYearlyInterestAvgCardDollars: number | null
    avgCardApr: number | null
    estimatedYearlyInterestAvenCardDollars: number | null
    avenCardApr: number | null
    hasSavingsWorthShowing: boolean
    estimatedYearlyInterestSavingsDollars: number | null
    estimated4YearInterestSavingsDollars: number | null
}

export const useEstimatedSavingsStore = () => {
    const estimatedSavingsStore = defineStore('estimatedSavings', {
        state: (): EstimatedSavings => {
            return {
                currentBalanceDollars: null,
                estimatedYearlyInterestAvgCardDollars: null,
                avgCardApr: null,
                estimatedYearlyInterestAvenCardDollars: null,
                avenCardApr: null,
                hasSavingsWorthShowing: false,
                estimatedYearlyInterestSavingsDollars: null,
                estimated4YearInterestSavingsDollars: null,
            }
        },
        getters: {},
        actions: {
            setEstimatedYearlyInterestSavingsDollars() {
                if (!this.estimatedYearlyInterestAvgCardDollars || !this.estimatedYearlyInterestAvenCardDollars) {
                    this.estimatedYearlyInterestSavingsDollars = null
                } else {
                    this.estimatedYearlyInterestSavingsDollars = this.estimatedYearlyInterestAvgCardDollars - this.estimatedYearlyInterestAvenCardDollars
                }
                logger.info(`Set estimatedYearlyInterestSavingsDollars=${this.estimatedYearlyInterestSavingsDollars}`)
            },
            setEstimated4YearInterestSavingsDollars() {
                const estimatedYearlyInterestSavings = this.estimatedYearlyInterestSavingsDollars
                if (!estimatedYearlyInterestSavings) {
                    this.estimated4YearInterestSavingsDollars = null
                } else {
                    this.estimated4YearInterestSavingsDollars = estimatedYearlyInterestSavings * 4
                }
                logger.info(`Set estimated4YearInterestSavingsDollars=${this.estimated4YearInterestSavingsDollars}`)
            },
            setHasSavingsWorthShowing() {
                const isAvenEmployee = useOverviewStore().isInternalEmployee
                let minSavingsThreshold = MIN_ESTIMATED_YEARLY_SAVINGS_WORTH_SHOWING_DOLLARS
                // So Aven employees can effectively test this feature, we need to reduce the min savings threshold
                // to account for low card limits & balances.
                if (isAvenEmployee) {
                    minSavingsThreshold = 1
                }
                const estimatedYearlySavings = this.estimatedYearlyInterestSavingsDollars
                const worthShowing = !!estimatedYearlySavings && estimatedYearlySavings >= minSavingsThreshold
                logger.info(`$${estimatedYearlySavings} savings ${worthShowing ? 'is' : 'is not'} worth showing ${isAvenEmployee ? 'to Aven employee' : ''}`)
                this.hasSavingsWorthShowing = worthShowing
            },
            // Todo This method is not good practice ... we *should* be able to define getters for each of the 3 variables
            //  set in the methods below. But after a few hours of debugging, I can't figure out why the getters aren't
            //  reactive! Observationally, the getters do update on the first $patch() call in tryGetEstimatedSavings(),
            //  but every subsequent $patch() call (or direct set on this.variableName) *does not* cause the getter to
            //  update, so they provide the incorrect value. I've tried updating Pinia to version 2.1.7, using $patch(),
            //  setting variables directly (e.g. this.variableName = value), using getters that are arrow functions,
            //  using getters that are regular functions, removing the call to openReplay.setUpPiniaStoreTracking(),
            //  even making the getter a simple pointer to a state variable. None of this worked :-(
            //  Luckily, state variables remain reactive, so I can get reactivity using the methods below, but obviously
            //  that suffers from the potential future problem that setting any state variable directly *does not* update
            //  dependent state.
            updateDependentVariables() {
                this.setEstimatedYearlyInterestSavingsDollars()
                this.setEstimated4YearInterestSavingsDollars()
                this.setHasSavingsWorthShowing()
            },
            async tryGetEstimatedSavings(skipCache: boolean = false) {
                if (!skipCache && this.currentBalanceDollars !== null) {
                    logger.info(`Estimated savings already loaded, not fetching again`)
                    return
                }
                try {
                    logger.info(`Getting estimated savings from API`)
                    const response = await getEstimatedAvenSavings()
                    if (!response.data.success) {
                        // PD alerts thrown from the backend, no need to do that here too
                        logger.info(`Response from getEstimatedAvenSavings() was not successful`)
                        return
                    }
                    if (!response.data.payload) {
                        logger.fatal(`Response from getEstimatedAvenSavings() was successful, but contains no response payload`)
                        return
                    }

                    logger.info(`Successfully got estimated savings from API: ${JSON.stringify(response.data.payload)}`)
                    this.$patch({
                        currentBalanceDollars: response.data.payload.currentAccountBalanceDollars,
                        estimatedYearlyInterestAvgCardDollars: response.data.payload.estimatedYearlyInterestAvgCardDollars,
                        avgCardApr: response.data.payload.avgCardApr,
                        estimatedYearlyInterestAvenCardDollars: response.data.payload.estimatedYearlyInterestAvenCardDollars,
                        avenCardApr: response.data.payload.avenCardApr,
                    })
                } catch (e) {
                    logger.fatal(`Error getting estimates savings`, e)
                } finally {
                    this.updateDependentVariables()
                }
            },
            tryAddHypotheticalBalanceAmountDollars(amountDollars: number) {
                try {
                    assert(
                        this.avgCardApr && this.avenCardApr,
                        `Can't tryAddHypotheticalBalanceAmountDollars of ${amountDollars}, because one of the APRs is null: avgCardApr=${this.avgCardApr}, avenCardApr=${this.avenCardApr}`
                    )
                    const newCurrentBalanceDollars = (this.currentBalanceDollars ?? 0) + amountDollars
                    this.$patch({
                        currentBalanceDollars: newCurrentBalanceDollars,
                        estimatedYearlyInterestAvgCardDollars: roundToNDecimalPlaces(newCurrentBalanceDollars * this.avgCardApr, 2),
                        estimatedYearlyInterestAvenCardDollars: roundToNDecimalPlaces(newCurrentBalanceDollars * this.avenCardApr, 2),
                    })
                } catch (e) {
                    logger.fatal(`Error tryAddHypotheticalBalanceAmountDollars of ${amountDollars} to estimated savings`, e)
                } finally {
                    this.updateDependentVariables()
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(estimatedSavingsStore)
    return estimatedSavingsStore
}
