"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotarySessionRole = void 0;
var NotarySessionRole;
(function (NotarySessionRole) {
    NotarySessionRole["notary"] = "Notary";
    NotarySessionRole["applicant"] = "Applicant";
    NotarySessionRole["witness"] = "Witness";
    NotarySessionRole["attorney"] = "Attorney";
    NotarySessionRole["plotter"] = "Plotter";
    NotarySessionRole["visitor"] = "Visitor";
    NotarySessionRole["mlo"] = "MLO";
})(NotarySessionRole = exports.NotarySessionRole || (exports.NotarySessionRole = {}));
