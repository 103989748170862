<template>
    <div>
        <validation-provider
            ref="validationProvider"
            mode="eager"
            v-slot="{ valid, errors }"
            :vid="name"
            :rules="validationRules"
        >
            <div class="input form-floating">
                <input
                    type="tel"
                    inputmode="tel"
                    ref="formFieldInput"
                    class="form-control"
                    v-mask="'###-###-####'"
                    :id="name"
                    :class="{ 'is-invalid': errors[0], 'is-valid': valid }"
                    :name="name"
                    :placeholder="dynamicPlaceholder"
                    :data-testid="dataTestid"
                    v-model="phoneValue"
                    @blur="onBlur($event.target)"
                    @focus="onFocus($event.target)"
                    v-bind="$attrs"
                >
                <label
                    v-if="label"
                    :for="name"
                    v-html="label"
                />
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormFieldPhone',
        inheritAttrs: false,
        mixins: [formInputMixin],
        props: {
            validationRules: { type: String, default: 'required|phoneNumberRule' },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                phoneValue: this.value || '',
            }
        },
        watch: {
            phoneValue: function (newValue, oldValue) {
                logger.info(`phoneValue: ${oldValue} -> ${newValue}`)
                const filteredValue = newValue?.replace(/[()-.]/gi, '')
                this.$emit('input', filteredValue)
            },
        },
    }
</script>
