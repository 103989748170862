<template>
    <div
        class="transaction-row"
        :class="[{ clickable: showArrow }]"
        @click="handleClick"
        @keydown.enter="handleClick"
    >
        <div
            v-if="icon"
            class="icon"
        >
            <img
                :src="require(`@/assets/images/components/transactionRow/${icon}`)"
                alt=""
                width="32"
                height="32"
            >
        </div>

        <div class="left">
            <span
                class="title"
                :class="highlightColor"
            >
                {{ title }}
            </span>
            <span
                v-if="date"
                class="date text-muted me-2"
            >
                {{ date }}
            </span>
        </div>
        <div class="right">
            <p :class="highlightColor">
                {{ value }}
                <span
                    v-if="valueSubText"
                    class="value-sub-text text-muted"
                >
                    <br>
                    {{ valueSubText }}
                </span>
            </p>
            <img
                v-show="showArrow"
                class="arrow ms-1"
                src="@/assets/images/components/transactionRow/Arrow.svg"
                alt=""
            >
        </div>
    </div>
</template>

<script>
    import { logger } from '@/utils/logger'

    export default {
        name: 'TransactionRow',
        props: {
            title: {
                type: String,
                required: true,
            },
            date: {
                type: String,
                required: false,
            },
            showArrow: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                required: true,
            },
            valueSubText: {
                type: String,
                required: false,
            },
            icon: {
                type: String,
                required: false,
            },
            highlightColor: {
                type: String,
                required: false,
            },
        },
        methods: {
            handleClick() {
                if (!this.showArrow) {
                    logger.info(`Transaction row is not allowed to be clicked`)
                    return
                }

                logger.info(`Emitting onClick event`)
                this.$emit('onClick')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/transactionRow';
</style>
