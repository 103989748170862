import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { useOverviewStore } from '@/store/overviewStore'
import { logger } from '@/utils/logger'

export enum PifContactListDefaultShareNumberTreatment {
    control = 'control',
    sevenContactsSelected = 'sevenContactsSelected',
}

export enum CreatorChallengeExperimentBucket {
    control = 'control',
    variant = 'variant',
}

export const useExperimentStore = () => {
    const experimentStore = defineStore('experiment', {
        getters: {
            getPifContactListDefaultShareNumberTreatment: () => {
                const treatmentGroups = useOverviewStore().experimentOverrides?.pifContactListDefaultShareNumber
                if (!treatmentGroups) {
                    logger.info(`No treatment groups for pifContactListDefaultShareNumber, user will see ${PifContactListDefaultShareNumberTreatment.control}`)
                    return PifContactListDefaultShareNumberTreatment.control
                }

                if (treatmentGroups.includes(PifContactListDefaultShareNumberTreatment.sevenContactsSelected)) {
                    logger.info(
                        `Treatment groups for pifContactListDefaultShareNumber are ${JSON.stringify(treatmentGroups)}, user will see ${PifContactListDefaultShareNumberTreatment.sevenContactsSelected}`
                    )
                    return PifContactListDefaultShareNumberTreatment.sevenContactsSelected
                } else {
                    logger.info(`Treatment groups for pifContactListDefaultShareNumber are ${JSON.stringify(treatmentGroups)}, user will see ${PifContactListDefaultShareNumberTreatment.control}`)
                    return PifContactListDefaultShareNumberTreatment.control
                }
            },
            getCreatorChallengeExperimentBucket: () => {
                const groups = useOverviewStore().experimentOverrides?.creatorChallengeCard
                const isInExperiment = groups?.includes(CreatorChallengeExperimentBucket.variant)

                if (isInExperiment) {
                    logger.info(`User is in experiment, user will see ${CreatorChallengeExperimentBucket.variant}`)
                    return CreatorChallengeExperimentBucket.variant
                } else {
                    logger.info(`User is not in experiment, user will see ${CreatorChallengeExperimentBucket.control}`)
                    return CreatorChallengeExperimentBucket.control
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(experimentStore)
    return experimentStore
}
