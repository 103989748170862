import { CustomerInfo } from './customerInfoStore.types'
import { defineStore } from 'pinia'
import { getConsents } from '@/services/api'
import { openReplay } from '@/utils/openReplay'

export const useCustomerInfoStore = () => {
    const customerInfoStore = defineStore('customerInfo', {
        state: (): CustomerInfo => {
            return {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                existingConsents: undefined,
            }
        },
        getters: {
            fullName: (state) => `${state.firstName} ${state.lastName}`,
        },
        actions: {
            async refreshConsentsIfNecessary(forceRefresh: boolean) {
                if (forceRefresh || !this.existingConsents) {
                    const resp = await getConsents()
                    this.existingConsents = resp.data.payload.consents
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(customerInfoStore)
    return customerInfoStore
}
