"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExperianInstantUnlockStatus = exports.GetInstantUnlockWidgetUrlHandlerError = void 0;
var GetInstantUnlockWidgetUrlHandlerError;
(function (GetInstantUnlockWidgetUrlHandlerError) {
    GetInstantUnlockWidgetUrlHandlerError["RETRIABLE_ERROR"] = "RETRIABLE_ERROR";
    GetInstantUnlockWidgetUrlHandlerError["NON_RETRIABLE_ERROR"] = "NON_RETRIABLE_ERROR";
})(GetInstantUnlockWidgetUrlHandlerError = exports.GetInstantUnlockWidgetUrlHandlerError || (exports.GetInstantUnlockWidgetUrlHandlerError = {}));
var ExperianInstantUnlockStatus;
(function (ExperianInstantUnlockStatus) {
    ExperianInstantUnlockStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ExperianInstantUnlockStatus["FAILED"] = "FAILED";
    ExperianInstantUnlockStatus["PROFILE_READY"] = "PROFILE_READY";
    ExperianInstantUnlockStatus["COMPLETED"] = "COMPLETED";
    ExperianInstantUnlockStatus["EXPIRED"] = "EXPIRED";
    ExperianInstantUnlockStatus["PROFILE_ALREADY_UNLOCKED_UNFROZEN"] = "PROFILE_ALREADY_UNLOCKED_UNFROZEN";
})(ExperianInstantUnlockStatus = exports.ExperianInstantUnlockStatus || (exports.ExperianInstantUnlockStatus = {}));
