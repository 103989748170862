"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountActivationRequirementType = exports.INCOMPLETE_ACCOUNT_ACTIVATION_REQUIREMENT_STATUSES = exports.AccountActivationRequirementStatus = void 0;
var AccountActivationRequirementStatus;
(function (AccountActivationRequirementStatus) {
    AccountActivationRequirementStatus["pending"] = "pending";
    AccountActivationRequirementStatus["inProgress"] = "inProgress";
    AccountActivationRequirementStatus["complete"] = "complete";
    AccountActivationRequirementStatus["bypassed"] = "bypassed";
    AccountActivationRequirementStatus["canceled"] = "canceled";
})(AccountActivationRequirementStatus = exports.AccountActivationRequirementStatus || (exports.AccountActivationRequirementStatus = {}));
exports.INCOMPLETE_ACCOUNT_ACTIVATION_REQUIREMENT_STATUSES = [AccountActivationRequirementStatus.pending, AccountActivationRequirementStatus.inProgress];
var AccountActivationRequirementType;
(function (AccountActivationRequirementType) {
    AccountActivationRequirementType["fixedTermCashOutUpfront"] = "fixedTermCashOutUpfront";
    AccountActivationRequirementType["cashOutUpfrontV1"] = "cashOutUpfrontV1";
    AccountActivationRequirementType["cashOutUpfrontV2"] = "cashOutUpfrontV2";
})(AccountActivationRequirementType = exports.AccountActivationRequirementType || (exports.AccountActivationRequirementType = {}));
