"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenPaymentAllocationRequestStatus = exports.AvenPaymentAllocationRequestType = exports.AvenPaymentAllocationPattern = void 0;
var AvenPaymentAllocationPattern;
(function (AvenPaymentAllocationPattern) {
    AvenPaymentAllocationPattern["coreCardDefault"] = "coreCardDefault";
    AvenPaymentAllocationPattern["avenGracePeriodSupport"] = "avenGracePeriodSupport";
    AvenPaymentAllocationPattern["payDownAllPlans"] = "payDownAllPlans";
    AvenPaymentAllocationPattern["TESTING_ONLY_payAllPlansEqually"] = "TESTING_ONLY_payAllPlansEqually";
})(AvenPaymentAllocationPattern = exports.AvenPaymentAllocationPattern || (exports.AvenPaymentAllocationPattern = {}));
var AvenPaymentAllocationRequestType;
(function (AvenPaymentAllocationRequestType) {
    AvenPaymentAllocationRequestType["payment"] = "PAYMENT";
    AvenPaymentAllocationRequestType["reversal"] = "REVERSAL";
})(AvenPaymentAllocationRequestType = exports.AvenPaymentAllocationRequestType || (exports.AvenPaymentAllocationRequestType = {}));
var AvenPaymentAllocationRequestStatus;
(function (AvenPaymentAllocationRequestStatus) {
    AvenPaymentAllocationRequestStatus["requested"] = "REQUESTED";
    AvenPaymentAllocationRequestStatus["fulfilled"] = "FULFILLED";
    AvenPaymentAllocationRequestStatus["bailRequested"] = "BAIL_REQUESTED";
    AvenPaymentAllocationRequestStatus["bailing"] = "BAILING";
    AvenPaymentAllocationRequestStatus["bailed"] = "BAILED";
})(AvenPaymentAllocationRequestStatus = exports.AvenPaymentAllocationRequestStatus || (exports.AvenPaymentAllocationRequestStatus = {}));
