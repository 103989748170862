import { inspect, logger } from '@/utils/logger'
import { getCanOfferPastDueAcccountReage, getCanOfferLateFeeReversal, offerPastDueAcccountReage, offerLateFeeReversal } from '@/services/api'

export default {
    methods: {
        getCanOfferPastDueAcccountReage: async function () {
            try {
                logger.info(`checking if canOfferPastDueAcccountReage`)
                const response = await getCanOfferPastDueAcccountReage()
                logger.info(`got a response ${JSON.stringify(response)}`)
                if (!response.data.success) {
                    logger.error(`getCanOfferPastDueAcccountReage failed: ${response.data.error}`)
                    return false
                }
                return response.data.payload.canOfferPastDueAcccountReage
            } catch (error) {
                logger.error(`Failed to getCanOfferPastDueAcccountReage, error: ${inspect(error.message)}`)
                return false
            }
        },
        offerPastDueAcccountReage: async function () {
            try {
                logger.info(`creating account reage offer`)
                const response = await offerPastDueAcccountReage()
                logger.info(`got a response ${JSON.stringify(response)}`)
                if (!response.data.success) {
                    logger.error(`offerPastDueAcccountReage failed: ${response.data.error}`)
                    return false
                }
                return true
            } catch (error) {
                logger.error(`Failed to offerPastDueAcccountReage, error: ${inspect(error.message)}`)
                return false
            }
        },
        getCanOfferLateFeeReversal: async function () {
            try {
                logger.info(`checking if canOfferLateFeeReversal`)
                const response = await getCanOfferLateFeeReversal()
                logger.info(`got a response ${JSON.stringify(response)}`)
                if (!response.data.success) {
                    logger.error(`getCanOfferLateFeeReversal failed: ${response.data.error}`)
                    return false
                }
                return response.data.payload.canOfferLateFeeReversal
            } catch (error) {
                logger.error(`Failed to getCanOfferLateFeeReversal, error: ${inspect(error.message)}`)
                return false
            }
        },
        offerLateFeeReversal: async function () {
            try {
                logger.info(`customer clicked banner cta, creating late fee reversal offer`)
                const response = await offerLateFeeReversal()
                logger.info(`got a response ${JSON.stringify(response)}`)
                if (!response.data.success) {
                    logger.error(`offerLateFeeReversal failed: ${response.data.error}`)
                    return false
                }
                return true
            } catch (error) {
                logger.error(`Failed to offerLateFeeReversal, error: ${inspect(error.message)}`)
                return false
            }
        },
    },
}
