import Vue from 'vue'

Vue.filter('dollar', function (value) {
    let convertedAmount = value.toString()
    if (convertedAmount == null) {
        return '$0'
    }
    if (convertedAmount.indexOf('-') > -1) {
        let removeMinus = convertedAmount.replace('-', '')
        return '-$'.concat(
            Number(removeMinus).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
            })
        )
    } else {
        return '$'.concat(
            Number(convertedAmount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
            })
        )
    }
})
