<template>
    <div class="auth-layout">
        <loading-indicator
            :title="loadingTitle"
            :content="loadingSubtitle"
            v-if="loading"
        />
        <main v-else>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3 pt-6">
                        <slot />
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    /**
 * Use as the base for all auth screens.

 */

    import LoadingIndicator from '@/components/LoadingIndicator'

    export default {
        name: 'AuthLayout',
        props: {
            loading: {
                type: Boolean,
                required: false,
            },
            loadingTitle: {
                type: String,
                required: false,
            },
            loadingSubtitle: {
                type: String,
                required: false,
            },
            withoutSpacing: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            'loading-indicator': LoadingIndicator,
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/layouts/auth';
</style>
