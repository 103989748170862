"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivationRequirementStatus = void 0;
/**
 * Useful to represent the status of any requirement for card activation. Meant to
 * be generic, not tied to any specific requirements or data structures.
 */
var ActivationRequirementStatus;
(function (ActivationRequirementStatus) {
    /** Cardholder needs to take action to fulfil this requirement */
    ActivationRequirementStatus["required"] = "required";
    /**
     * Aven systems are processing available information to determine whether
     * this requirement is met (e.g. ops is reviewing available documentation)
     */
    ActivationRequirementStatus["processing"] = "processing";
    /** The cardholder has passed this requirement */
    ActivationRequirementStatus["passed"] = "passed";
    /** The cardholder has failed this requirement */
    ActivationRequirementStatus["failed"] = "failed";
})(ActivationRequirementStatus = exports.ActivationRequirementStatus || (exports.ActivationRequirementStatus = {}));
