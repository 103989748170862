"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KbaStatus = void 0;
// Keep all the values in this enum <16 chars long to avoid issues with the database
var KbaStatus;
(function (KbaStatus) {
    KbaStatus["incomplete"] = "incomplete";
    KbaStatus["pass"] = "pass";
    KbaStatus["failAllowRetry"] = "failAllowRetry";
    KbaStatus["failNoRetry"] = "failNoRetry";
    KbaStatus["failNoRetrySsn"] = "failNoRetrySsn";
    KbaStatus["noQuestions"] = "noQuestions";
})(KbaStatus = exports.KbaStatus || (exports.KbaStatus = {}));
