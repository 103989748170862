<template>
    <card-block
        class="mb-2 text-start"
        :no-horizontal-padding="true"
        :no-vertical-padding="true"
    >
        <img
            v-if="!bonusEarned"
            src="@/assets/images/pages/mortgageCashbackV2/ActivityCardBackground.png"
            alt="Background"
        >
        <div
            v-if="!bonusEarned"
            class="py-3 px-3"
        >
            <div class="d-flex flex-row justify-content-between">
                <h6 class="fw-bold">
                    {{ $t('pages.MortgageCashbackV2.cardTitle') }}
                </h6>
                <p class="bg-dark text-white fw-bold px-1 rounded">
                    {{ $t('pages.MortgageCashbackV2.tagText') }}
                </p>
            </div>
            <p>
                {{ $t('pages.MortgageCashbackV2.contentText') }}
            </p>
            <div class="d-flex flex-column">
                <base-button
                    class="mt-2"
                    @click="goToMortgageCashbackLanding"
                >
                    {{ $t('pages.MortgageCashbackV2.buttonText') }}
                </base-button>
            </div>
        </div>
        <div
            v-else
            class="py-3 px-3"
        >
            <div class="d-flex flex-row justify-content-between">
                <h6 class="fw-bold">
                    {{ $t('pages.MortgageCashbackV2.cardTitle') }}
                </h6>
            </div>
            <p v-html="$t('pages.MortgageCashbackV2.pointsEarnedText', { points: this.bonusEarned })" />
            <base-button
                class="mt-2"
                button-classes="btn btn-tertiary"
                @click="handleShareButtonClick"
            >
                {{ $t('pages.MortgageCashbackV2.pointsEarnedButtonText') }}
            </base-button>
        </div>
    </card-block>
</template>

<script>
    import CardBlock from '@/components/CardBlock.vue'
    import BaseButton from '@/components/base/BaseButton.vue'
    import { RoutePaths } from '@/routes/router.types'
    import { useOverviewStore } from '@/store/overviewStore'
    import { sendSMS } from '@/utils/smsUtils'
    import { i18n } from '@/utils/i18n'

    export default {
        name: 'MortgageCashbackV2Card',
        components: { CardBlock, BaseButton },
        mounted: async function () {
            if (this.bonusEarned) {
                this.$logEvent('render_mortgage_cashback_v2_card_bonus_earned')
            } else {
                this.$logEvent('render_mortgage_cashback_v2_card')
            }
        },
        computed: {
            bonusEarned() {
                return useOverviewStore().mortgagePaymentRewardsEarned
            },
            cashBackV2AvailableSpots() {
                return useOverviewStore().mortgagePaymentRewardsV2AvailableSpots
            },
        },
        methods: {
            goToMortgageCashbackLanding: async function () {
                this.$logEvent('click_button_mortgage_cashback_v2_card')
                await this.$router.push(RoutePaths.MORTGAGE_CASHBACK_V2_Landing)
            },
            handleShareButtonClick: async function () {
                this.$logEvent('click_button_mortgage_cashback_v2_card_share')
                await sendSMS(i18n.t('pages.MortgageCashbackV2.smsMessage', { availableSpots: this.cashBackV2AvailableSpots }).toString())
            },
        },
    }
</script>
