<template>
    <div v-if="showFeature || !active">
        <slot />
    </div>
</template>

<script>
    import { appLocalStorage } from '@/utils/storage'
    // feature-flag takes a `name` prop
    // we look up `feature-${name}` in localstorage
    // and display the feature if true
    export default {
        name: 'FeatureFlag',
        props: {
            name: {
                type: String,
                default: '',
            },
            active: {
                // active means the feature should be hidden
                type: Boolean,
                default: true,
            },
        },
        computed: {
            showFeature: function () {
                const isDev = process.env.VUE_APP_NODE_ENV !== 'production' && process.env.VUE_APP_NODE_ENV !== 'staging'
                return isDev || appLocalStorage.getItem(`feature-${this.name}`)
            },
        },
    }
</script>
