import { logger } from '@/utils/logger'
import { AvenDeviceRuntimeType } from '@/utils/deviceUtils'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'

export const sendSMS = async (smsMessage: string, phoneNumber?: string): Promise<void> => {
    const runtimeType = useDeviceInfoStore().runtimeType
    if (runtimeType !== AvenDeviceRuntimeType.IOS && runtimeType !== AvenDeviceRuntimeType.ANDROID) {
        logger.error(`unsupported platform to send SMS. runtimeType ${runtimeType}`)
        return
    }

    logger.log(`opening SMS for runtimeType ${runtimeType}`)
    const body = encodeURIComponent(smsMessage)

    // Determine if we can compose an SMS in the native app
    const canComposeSms = useDeviceInfoStore().canComposeSms
    // We have a bug where the android compose sms will show localhost:// if no phone number is present,
    // so we need to use the SMS link if we are on android and no phone number is present
    // TODO: Bug should be fixed in the next release of the native app, so we can remove this check after a couple of months
    // TODO: Remove after 2023-09-15; maybe? Check adoption rate of new native app version before removing
    const shouldComposeSMS = runtimeType !== AvenDeviceRuntimeType.ANDROID || !!phoneNumber
    let sendSmsLink = ''
    if (canComposeSms && shouldComposeSMS) {
        sendSmsLink = `/composeSms?phones=${phoneNumber || ''}&body=${body}`
        logger.log(`Native app can compose SMS, so using link: ${sendSmsLink}`)
    } else {
        // These url schemas look weird. They are correct.
        // Strictly speaking, the sms:// url is correct, but it doesn't work on Android when we don't specify a phone
        // number, android tries to be super clever and adds // as the phone number, which is not what we want.
        const androidSmsUrl = `sms:${phoneNumber ? phoneNumber : ';'}`
        const iosSmsUrl = `sms://${phoneNumber ? phoneNumber : ''}`
        const smsUrl = runtimeType === AvenDeviceRuntimeType.IOS ? iosSmsUrl : androidSmsUrl
        sendSmsLink = `${smsUrl}?&body=${body}`
        logger.log(`Native app cannot compose SMS, so using link: ${sendSmsLink}`)
    }

    window.logEvent('event_sms_sent', { runtimeType, smsMessage, url: sendSmsLink })
    logger.info(`Redirecting to ${sendSmsLink}`)
    window.location.href = sendSmsLink
}
