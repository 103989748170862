"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanAssetType = void 0;
/** @TranslateToPython */
var LoanAssetType;
(function (LoanAssetType) {
    LoanAssetType["home"] = "home";
    LoanAssetType["home_no_loyalty"] = "home_no_loyalty";
    LoanAssetType["crypto"] = "crypto";
    LoanAssetType["auto"] = "auto";
    LoanAssetType["personalPropertyAttestation"] = "personalPropertyAttestation";
    LoanAssetType["homeMortgage"] = "homeMortgage";
})(LoanAssetType = exports.LoanAssetType || (exports.LoanAssetType = {}));
