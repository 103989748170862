import { logger } from '@/utils/logger'
import assert from 'assert'
import { ValidatedAddress } from '@/services/smarty'

export const initGooglePlacesClient = async () => {
    assert(process.env.VUE_APP_GOOGLE_MAPS_API_KEY, 'No google maps api key!')
    // @ts-ignore
    ;((g) => {
        // @ts-ignore
        let h,
            a,
            k,
            // eslint-disable-next-line prefer-const
            p = 'The Google Maps JavaScript API',
            // eslint-disable-next-line prefer-const
            c = 'google',
            // eslint-disable-next-line prefer-const
            l = 'importLibrary',
            // eslint-disable-next-line prefer-const
            q = '__ib__',
            // eslint-disable-next-line prefer-const
            m = document,
            b = window
        // @ts-ignore
        b = b[c] || (b[c] = {})
        // @ts-ignore
        const d = b.maps || (b.maps = {}),
            r = new Set(),
            e = new URLSearchParams(),
            u = () =>
                // @ts-ignore
                h ||
                // eslint-disable-next-line no-async-promise-executor
                (h = new Promise(async (f, n) => {
                    await (a = m.createElement('script'))
                    e.set('libraries', [...r] + '')
                    for (k in g)
                        e.set(
                            k.replace(/[A-Z]/g, (t) => '_' + t[0].toLowerCase()),
                            // @ts-ignore
                            g[k]
                        )
                    e.set('callback', c + '.maps.' + q)
                    a.src = `https://maps.${c}apis.com/maps/api/js?` + e
                    d[q] = f
                    a.onerror = (event, source, lineno, colno, error) => {
                        h = n(Error(p + ' could not load.'))
                        logger.error('Error loading google maps api!', { event, source, lineno, colno, error }, event)
                    }
                    // @ts-ignore
                    a.nonce = m.querySelector('script[nonce]')?.nonce || ''
                    m.head.append(a)
                }))
        // @ts-ignore
        d[l] ? console.warn(p + ' only loads once. Ignoring:', g) : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)))
    })({
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        v: 'weekly',
        libraries: 'places',
        language: 'en',
        region: 'us',
    })

    // cache libs
    await google.maps.importLibrary('places')
}

export const getGooglePlacesClient = async (inputElement?: HTMLInputElement) => {
    console.log(google.maps.version)
    const { AutocompleteService, AutocompleteSessionToken, PlacesService, PlacesServiceStatus, Autocomplete } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary

    class GooglePlacesClient {
        private autocompleteService: google.maps.places.AutocompleteService
        private placesService: google.maps.places.PlacesService
        private readonly autocompleteWidgetService: google.maps.places.Autocomplete | undefined
        public googleAttributionsDivId = 'google-attributions'

        constructor() {
            assert(process.env.VUE_APP_GOOGLE_MAPS_API_KEY, 'No google places api key!')
            const attributionsDiv = document.getElementById(this.googleAttributionsDivId) as HTMLDivElement | null
            assert(attributionsDiv, `No element with id ${this.googleAttributionsDivId} found, please add it to your html`)
            this.autocompleteService = new AutocompleteService()
            this.placesService = new PlacesService(attributionsDiv)

            if (inputElement) {
                this.autocompleteWidgetService = new Autocomplete(inputElement)
            }
        }

        public getSessionToken = (): google.maps.places.AutocompleteSessionToken => {
            return new AutocompleteSessionToken()
        }

        public getPredictions = async (input: string, maxResults: number = 5, sessionToken: google.maps.places.AutocompleteSessionToken): Promise<google.maps.places.AutocompletePrediction[]> => {
            logger.info(`Getting google places predictions for ${input}`)

            const autocompleteRequest: google.maps.places.AutocompletionRequest = {
                input,
                locationBias: 'IP_BIAS',
                language: 'en',
                region: 'us',
                types: ['address'],
                sessionToken: sessionToken,
                componentRestrictions: { country: ['us'] },
            }

            const autocompleteResponse = await new Promise<{ status: google.maps.places.PlacesServiceStatus; predictions: Array<google.maps.places.AutocompletePrediction> | null }>((resolve) => {
                this.autocompleteService.getPlacePredictions(autocompleteRequest, (predictions, status) => {
                    resolve({ status, predictions })
                })
            })

            if (autocompleteResponse.status !== PlacesServiceStatus.OK) {
                logger.error(`Error getting google places predictions for ${input}: ${autocompleteResponse.status}`)
                return []
            }

            const predictionsWithService = (autocompleteResponse.predictions?.slice(0, maxResults) || []).map((prediction) => {
                return {
                    ...prediction,
                    service: 'google',
                }
            })
            return predictionsWithService
        }

        public getPlaceCallback = (): ValidatedAddress | null => {
            if (!this.autocompleteWidgetService) {
                return null
            }
            const place = this.autocompleteWidgetService.getPlace()
            if (place.geometry) {
                return this.getValidatedAddressFromPlaceDetails(place)
            }
            return null
        }

        public getPlaceDetails = async (placeId: string, sessionToken: google.maps.places.AutocompleteSessionToken, addressUnit?: string): Promise<ValidatedAddress> => {
            logger.info(`Getting google places details for ${placeId}`)

            const placesResponse = await new Promise<{ status: google.maps.places.PlacesServiceStatus; result: google.maps.places.PlaceResult | null }>((resolve) => {
                this.placesService.getDetails(
                    {
                        placeId,
                        language: 'en',
                        region: 'us',
                        sessionToken,
                        fields: ['address_components', 'formatted_address', 'geometry'],
                    },
                    (result, status) => {
                        resolve({ status, result })
                    }
                )
            })

            if (placesResponse.status !== PlacesServiceStatus.OK || !placesResponse.result || !placesResponse.result.address_components) {
                return new ValidatedAddress({ addressValidationError: placesResponse.status })
            }

            return this.getValidatedAddressFromPlaceDetails(placesResponse.result, addressUnit)
        }

        public getValidatedAddressFromPlaceDetails = (placeDetails: google.maps.places.PlaceResult, addressUnit?: string): ValidatedAddress => {
            assert(placeDetails.address_components, 'No address components in place details')
            const streetNumber = placeDetails.address_components.find((component) => component.types.includes('street_number'))?.short_name
            const streetName = placeDetails.address_components.find((component) => component.types.includes('route'))?.short_name
            let city = placeDetails.address_components.find((component) => component.types.includes('locality'))?.long_name
            if (!city) {
                city = placeDetails.address_components.find((component) => component.types.includes('sublocality_level_1'))?.long_name
            }
            const state = placeDetails.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.short_name
            const zipcode = placeDetails.address_components.find((component) => component.types.includes('postal_code'))?.short_name
            const secondary = placeDetails.address_components.find((component) => component.types.includes('room') || component.types.includes('subpremise'))?.short_name || addressUnit

            return new ValidatedAddress({
                street: [streetNumber, streetName].filter((x) => x).join(' '), // join with space if both street number and name are present
                city,
                state,
                zipcode,
                secondary,
            })
        }
    }

    return new GooglePlacesClient()
}
