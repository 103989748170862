import { extend, configure } from 'vee-validate'
import { required, email, min, max, numeric, length } from 'vee-validate/dist/rules'
import { i18n } from './i18n'
import generic from '@/utils/generic'
import { logger } from '@/utils/logger'
import { MAX_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS, AciAccountSubType } from '@/data/constants'
import startCase from 'lodash/startCase'
import round from 'lodash/round'
import { toFormattedUSD } from '@/mixins/format'

configure({
    defaultMessage: (field, values) => {
        return i18n.t(`validation.${values._rule_}`, values)
    },
})

// Install required rule and message.
extend('required', required)

// Install required rule and message.
extend('length', length)
// Install email rule and message.
extend('email', email)

// Install min rule and message.
extend('min', min)

// Install max rule and message.
extend('max', max)

extend('numeric', numeric)

// date validation rule
extend('phoneNumberRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        // Replace any extra chars (i.e. () / . / - )
        value = value?.replace(/[-.() ]/gi, '')

        if (value.length === 12 && value.startsWith('+1')) {
            return true
        }
        if (value.length === 11 && value.startsWith('1')) {
            return true
        }
        if (value.length === 10 && !(value.startsWith('1') || value.startsWith('+'))) {
            return true
        }

        return i18n.t('customValidationRules.notValidPhoneNumber')
    },
})

extend('twilioCode', {
    validate: (value) => {
        if (isNaN(value)) {
            return i18n.t('customValidationRules.onlyNumbersAllowed')
        } else if (value.length != 6) {
            return i18n.t('customValidationRules.twilioCode')
        }
        return true
    },
})

// Todo We can remove this validator once nobody is using iOS v1.0.39 or lower and Android v1.1.53
//  or lower. At that point, all apps will be able to handle these 'prohibited' characters.
extend('prohibited_characters_in_password', {
    validate: (value) => {
        // if single or double occurs in string then show error
        if (/['"]+/.test(value)) {
            return i18n.t('customValidationRules.noQuotesAllowed')
        }
        return true
    },
})

extend('phoneOrEmail', {
    validate: (value) => {
        // Check for either of these to return true
        if (generic.validEmail(value) || generic.validPhoneNumber(value)) {
            return true
        }

        return `must be either a valid phone number or e-mail`
    },
})

extend('currency', {
    validate: (value, { min, max }) => {
        value = value?.replace(/[$,]/gi, '')
        logger.info(`min: ${min}, max: ${max}, value: ${value}`)

        const minAmount = min !== undefined ? min : -Infinity
        const maxAmount = max !== undefined ? max : Infinity
        if (isNaN(parseFloat(value)) || value.endsWith('.')) {
            return i18n.t('customValidationRules.invalidCurrency')
        } else if (parseFloat(value) < minAmount) {
            return i18n.t('customValidationRules.currencyTooSmall', { minAmount: generic.prettyPrintDollarAmount(minAmount) })
        } else if (parseFloat(value) > maxAmount) {
            return i18n.t('customValidationRules.currencyTooLarge', { maxAmount: generic.prettyPrintDollarAmount(maxAmount) })
        }
        return true
    },
    params: ['min', 'max'],
})

extend('points', {
    validate: (value, { min, max }) => {
        value = value?.replace(/,/gi, '')
        const minAmount = min !== undefined ? min : -Infinity
        const maxAmount = max !== undefined ? max : Infinity
        if (isNaN(parseFloat(value))) {
            return i18n.t('customValidationRules.onlyNumbersAllowed')
        } else if (parseFloat(value) < minAmount) {
            return i18n.t('customValidationRules.pointsTooSmall', { minAmount: generic.prettyPrintPoints(minAmount) })
        } else if (parseFloat(value) > maxAmount) {
            return i18n.t('customValidationRules.pointsTooLarge', { maxAmount: generic.prettyPrintPoints(maxAmount) })
        }
        return true
    },
    params: ['min', 'max'],
})

extend('confirmAccountNumber', {
    params: ['target'],
    validate(value, { target }) {
        return value === target
    },
    message: i18n.t('pages.AddBankAccount.ValidationErrorMessage'),
})

extend('confirmPassword', {
    params: ['target'],
    validate(value, { target }) {
        return value === target
    },
    message: i18n.t('customValidationRules.passwordMismatch'),
})

extend('confirmPaymentAmount', {
    validate: (value, { amount }) => {
        value = value?.replace(/[$,]/gi, '')
        const inputAmount = parseFloat(value)
        const expected = parseFloat(amount)
        if (isNaN(inputAmount)) {
            logger.error(`Incorrect implementation of input field, amount entered is not a number, ${value}`)
            return false
        }
        if (isNaN(expected)) {
            logger.error(`Incorrect implementation of input field, expected amount is not a number, ${amount}`)
            return false
        }
        if (inputAmount - expected !== 0) {
            return i18n.t('pages.MakePayment.ConfirmAmountValidationMessage', { amount: generic.prettyPrintDollarAmount(amount) })
        }
        return true
    },
    params: ['amount'],
})

extend('maxAllowedByACI', {
    validate: (customPaymentValue, { totalPaymentByAciAlreadyToday, todayIndex, paymentDayIndex }) => {
        const selectedPaymentDayIsToday = todayIndex === paymentDayIndex
        customPaymentValue = customPaymentValue?.replace(/[$,]/gi, '')
        if (customPaymentValue > MAX_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS) {
            return i18n.t('customValidationRules.maxAllowedByACI', {
                maxAllowedPerDay: toFormattedUSD(MAX_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS),
            })
        }
        const maxPaymentAllowedTodayCentPrecision = round(MAX_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS - totalPaymentByAciAlreadyToday, 2)
        if (selectedPaymentDayIsToday && customPaymentValue > maxPaymentAllowedTodayCentPrecision) {
            return i18n.t('customValidationRules.maxAllowedByACITodayMultiPayments', {
                maxAllowedPerDay: toFormattedUSD(MAX_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS),
                amountRemainingToday: toFormattedUSD(maxPaymentAllowedTodayCentPrecision),
            })
        }
        return true
    },
    params: ['totalPaymentByAciAlreadyToday', 'todayIndex', 'paymentDayIndex'],
})

extend('confirmNoBusinessAccountManualCashout', {
    validate: (value) => {
        value = value?.replace(/[$,]/gi, '')
        if (value === AciAccountSubType.PERSONAL_CHECKING || value === AciAccountSubType.PERSONAL_SAVINGS) {
            return true
        }
        return i18n.t('customValidationRules.confirmNoBusinessAccountManualCashout')
    },
})

extend('dateRule', {
    validate: (value) => {
        if (/(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d/.test(value)) {
            return true
        } else {
            return i18n.t('customValidationRules.invalidDateFormat')
        }
    },
})

extend('addressAutoCompleteRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        logger.info(`addressAutoCompleteRule validation on: ${value}`)
        const addressComponents = value.split(',')
        if (addressComponents.length < 3) {
            return i18n.t('customValidationRules.incompleteAddress')
        }
        const addressStreet = startCase(addressComponents[0].toLowerCase())
        const addressCity = startCase(addressComponents[1].toLowerCase())
        const addressState = addressComponents[2].toUpperCase()

        if (addressStreet && addressCity && addressState) {
            return true
        }
        return i18n.t('customValidationRules.incompleteAddress')
    },
})
