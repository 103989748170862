"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OFFER_CONTINGENCY_STATES = exports.statesToRoundImgMap = exports.StateFullNames = exports.StateTwoLetterCodes = void 0;
var StateTwoLetterCodes;
(function (StateTwoLetterCodes) {
    StateTwoLetterCodes["alabama"] = "AL";
    StateTwoLetterCodes["alaska"] = "AK";
    StateTwoLetterCodes["americanSamoa"] = "AS";
    StateTwoLetterCodes["arizona"] = "AZ";
    StateTwoLetterCodes["arkansas"] = "AR";
    StateTwoLetterCodes["california"] = "CA";
    StateTwoLetterCodes["colorado"] = "CO";
    StateTwoLetterCodes["connecticut"] = "CT";
    StateTwoLetterCodes["delaware"] = "DE";
    StateTwoLetterCodes["districtOfColumbia"] = "DC";
    StateTwoLetterCodes["florida"] = "FL";
    StateTwoLetterCodes["georgia"] = "GA";
    StateTwoLetterCodes["guam"] = "GU";
    StateTwoLetterCodes["hawaii"] = "HI";
    StateTwoLetterCodes["idaho"] = "ID";
    StateTwoLetterCodes["illinois"] = "IL";
    StateTwoLetterCodes["indiana"] = "IN";
    StateTwoLetterCodes["iowa"] = "IA";
    StateTwoLetterCodes["kansas"] = "KS";
    StateTwoLetterCodes["kentucky"] = "KY";
    StateTwoLetterCodes["louisiana"] = "LA";
    StateTwoLetterCodes["maine"] = "ME";
    StateTwoLetterCodes["marshallIslands"] = "MH";
    StateTwoLetterCodes["maryland"] = "MD";
    StateTwoLetterCodes["massachusetts"] = "MA";
    StateTwoLetterCodes["michigan"] = "MI";
    StateTwoLetterCodes["minnesota"] = "MN";
    StateTwoLetterCodes["mississippi"] = "MS";
    StateTwoLetterCodes["missouri"] = "MO";
    StateTwoLetterCodes["montana"] = "MT";
    StateTwoLetterCodes["nebraska"] = "NE";
    StateTwoLetterCodes["nevada"] = "NV";
    StateTwoLetterCodes["newHampshire"] = "NH";
    StateTwoLetterCodes["newJersey"] = "NJ";
    StateTwoLetterCodes["newMexico"] = "NM";
    StateTwoLetterCodes["newYork"] = "NY";
    StateTwoLetterCodes["northCarolina"] = "NC";
    StateTwoLetterCodes["northDakota"] = "ND";
    StateTwoLetterCodes["ohio"] = "OH";
    StateTwoLetterCodes["oklahoma"] = "OK";
    StateTwoLetterCodes["oregon"] = "OR";
    StateTwoLetterCodes["palau"] = "PW";
    StateTwoLetterCodes["pennsylvania"] = "PA";
    StateTwoLetterCodes["rhodeIsland"] = "RI";
    StateTwoLetterCodes["southCarolina"] = "SC";
    StateTwoLetterCodes["southDakota"] = "SD";
    StateTwoLetterCodes["tennessee"] = "TN";
    StateTwoLetterCodes["texas"] = "TX";
    StateTwoLetterCodes["utah"] = "UT";
    StateTwoLetterCodes["vermont"] = "VT";
    StateTwoLetterCodes["virginia"] = "VA";
    StateTwoLetterCodes["washington"] = "WA";
    StateTwoLetterCodes["westVirginia"] = "WV";
    StateTwoLetterCodes["wisconsin"] = "WI";
    StateTwoLetterCodes["wyoming"] = "WY";
})(StateTwoLetterCodes = exports.StateTwoLetterCodes || (exports.StateTwoLetterCodes = {}));
var StateFullNames;
(function (StateFullNames) {
    StateFullNames["alabama"] = "ALABAMA";
    StateFullNames["alaska"] = "ALASKA";
    StateFullNames["americanSamoa"] = "AMERICAN SAMOA";
    StateFullNames["arizona"] = "ARIZONA";
    StateFullNames["arkansas"] = "ARKANSAS";
    StateFullNames["california"] = "CALIFORNIA";
    StateFullNames["colorado"] = "COLORADO";
    StateFullNames["connecticut"] = "CONNECTICUT";
    StateFullNames["delaware"] = "DELAWARE";
    StateFullNames["districtOfColumbia"] = "DISTRICT OF COLUMBIA";
    StateFullNames["florida"] = "FLORIDA";
    StateFullNames["georgia"] = "GEORGIA";
    StateFullNames["guam"] = "GUAM";
    StateFullNames["hawaii"] = "HAWAII";
    StateFullNames["idaho"] = "IDAHO";
    StateFullNames["illinois"] = "ILLINOIS";
    StateFullNames["indiana"] = "INDIANA";
    StateFullNames["iowa"] = "IOWA";
    StateFullNames["kansas"] = "KANSAS";
    StateFullNames["kentucky"] = "KENTUCKY";
    StateFullNames["louisiana"] = "LOUISIANA";
    StateFullNames["maine"] = "MAINE";
    StateFullNames["marshallIslands"] = "MARSHALL ISLANDS";
    StateFullNames["maryland"] = "MARYLAND";
    StateFullNames["massachusetts"] = "MASSACHUSETTS";
    StateFullNames["michigan"] = "MICHIGAN";
    StateFullNames["minnesota"] = "MINNESOTA";
    StateFullNames["mississippi"] = "MISSISSIPPI";
    StateFullNames["missouri"] = "MISSOURI";
    StateFullNames["montana"] = "MONTANA";
    StateFullNames["nebraska"] = "NEBRASKA";
    StateFullNames["nevada"] = "NEVADA";
    StateFullNames["newHampshire"] = "NEW HAMPSHIRE";
    StateFullNames["newJersey"] = "NEW JERSEY";
    StateFullNames["newMexico"] = "NEW MEXICO";
    StateFullNames["newYork"] = "NEW YORK";
    StateFullNames["northCarolina"] = "NORTH CAROLINA";
    StateFullNames["northDakota"] = "NORTH DAKOTA";
    StateFullNames["ohio"] = "OHIO";
    StateFullNames["oklahoma"] = "OKLAHOMA";
    StateFullNames["oregon"] = "OREGON";
    StateFullNames["palau"] = "PALAU";
    StateFullNames["pennsylvania"] = "PENNSYLVANIA";
    StateFullNames["rhodeIsland"] = "RHODE ISLAND";
    StateFullNames["southCarolina"] = "SOUTH CAROLINA";
    StateFullNames["southDakota"] = "SOUTH DAKOTA";
    StateFullNames["tennessee"] = "TENNESSEE";
    StateFullNames["texas"] = "TEXAS";
    StateFullNames["utah"] = "UTAH";
    StateFullNames["vermont"] = "VERMONT";
    StateFullNames["virginia"] = "VIRGINIA";
    StateFullNames["washington"] = "WASHINGTON";
    StateFullNames["westVirginia"] = "WEST VIRGINIA";
    StateFullNames["wisconsin"] = "WISCONSIN";
    StateFullNames["wyoming"] = "WYOMING";
})(StateFullNames = exports.StateFullNames || (exports.StateFullNames = {}));
// Does not contain all states - only the rounded states found on the frontend
// See aven_frontend/aven/src/assets/images/origination/roundedStates
exports.statesToRoundImgMap = new Map([
    ['AR', 'https://aven.com/email/images/roundedStates/arkansas.png'],
    ['DE', 'https://aven.com/email/images/roundedStates/delaware.png'],
    ['CT', 'https://aven.com/email/images/roundedStates/connecticut.png'],
    ['DC', 'https://aven.com/email/images/roundedStates/district_of_columbia.png'],
    ['GA', 'https://aven.com/email/images/roundedStates/georgia.png'],
    ['HI', 'https://aven.com/email/images/roundedStates/hawaii.png'],
    ['ID', 'https://aven.com/email/images/roundedStates/idaho.png'],
    ['IN', 'https://aven.com/email/images/roundedStates/indiana.png'],
    ['LA', 'https://aven.com/email/images/roundedStates/louisiana.png'],
    ['ME', 'https://aven.com/email/images/roundedStates/maine.png'],
    ['MD', 'https://aven.com/email/images/roundedStates/maryland.png'],
    ['MA', 'https://aven.com/email/images/roundedStates/massachusetts.png'],
    ['MI', 'https://aven.com/email/images/roundedStates/michigan.png'],
    ['MN', 'https://aven.com/email/images/roundedStates/minnesota.png'],
    ['MS', 'https://aven.com/email/images/roundedStates/mississippi.png'],
    ['MO', 'https://aven.com/email/images/roundedStates/missouri.png'],
    ['MT', 'https://aven.com/email/images/roundedStates/montana.png'],
    ['NE', 'https://aven.com/email/images/roundedStates/nebraska.png'],
    ['NV', 'https://aven.com/email/images/roundedStates/nevada.png'],
    ['NJ', 'https://aven.com/email/images/roundedStates/new_jersey.png'],
    ['NM', 'https://aven.com/email/images/roundedStates/new_mexico.png'],
    ['NY', 'https://aven.com/email/images/roundedStates/new_york.png'],
    ['NC', 'https://aven.com/email/images/roundedStates/north_carolina.png'],
    ['ND', 'https://aven.com/email/images/roundedStates/north_dakota.png'],
    ['OK', 'https://aven.com/email/images/roundedStates/oklahoma.png'],
    ['OR', 'https://aven.com/email/images/roundedStates/oregon.png'],
    ['RI', 'https://aven.com/email/images/roundedStates/rhode_island.png'],
    ['SC', 'https://aven.com/email/images/roundedStates/south_carolina.png'],
    ['SD', 'https://aven.com/email/images/roundedStates/south_dakota.png'],
    ['TX', 'https://aven.com/email/images/roundedStates/texas.png'],
    ['UT', 'https://aven.com/email/images/roundedStates/utah.png'],
    ['VT', 'https://aven.com/email/images/roundedStates/vermont.png'],
    ['VA', 'https://aven.com/email/images/roundedStates/virginia.png'],
    ['WA', 'https://aven.com/email/images/roundedStates/washington.png'],
    ['WV', 'https://aven.com/email/images/roundedStates/west_virginia.png'],
    ['WI', 'https://aven.com/email/images/roundedStates/wisconsin.png'],
]);
exports.OFFER_CONTINGENCY_STATES = [StateTwoLetterCodes.texas];
