<template>
    <a
        class="list-row"
        :data-testid="dataTestid"
        :class="[{ hoverable: showArrow || linkTo || action, 'mx-0 px-0': contained }, containerClasses]"
        :href="hrefLink"
        @click="handleClick"
    >
        <div
            v-if="rowIcon"
            class="icon"
        >
            <img
                :src="require(`@/assets/${rowIcon}`)"
                alt=""
            >
        </div>
        <span
            v-if="prefix"
            class="prefix text-muted me-2"
        >{{ prefix }}</span>
        <div class="d-flex flex-column">
            <span
                class="title"
                :data-testid="dataTestid + '-title'"
                :class="[{ 'text-link': action }, titleClass]"
            ><slot name="title">{{ title }}<slot /></slot></span>
            <span
                v-if="subTitle"
                class="small text-muted"
                :data-testid="dataTestid + '-sub-title'"
            > {{ subTitle }}</span>
        </div>
        <div class="right">
            <span
                v-if="on"
                class="on-indicator align-middle"
            />
            <span
                :class="valueClass"
                :data-testid="dataTestid + '-value'"
            >{{ value }}</span>
            <span
                v-show="submitting"
                class="spinner-border spinner-border-sm"
            />
            <img
                v-show="(showArrow || action) && !submitting"
                class="arrow ms-1"
                src="@/assets/images/components/listRow/Arrow.svg"
                alt=""
            >
            <img
                v-show="linkTo && !submitting"
                class="arrow ms-1"
                src="@/assets/pp-shape.svg"
                alt=""
            >
        </div>
    </a>
</template>

<script>
    export default {
        name: 'ListRow',
        props: {
            prefix: {
                type: String,
            },
            // this prop will be used in case that the title slot is not provided
            title: {
                type: String,
            },
            hrefLink: {
                type: String,
                required: false,
            },
            showArrow: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
            },
            valueClass: {
                type: [String, Array],
                default: 'text-primary',
            },
            titleClass: {
                type: [String, Array],
                default: 'text-primary',
            },
            subTitle: {
                type: String,
                required: false,
            },
            /**
             * Indicates whether the list row is considered "on". If true, displays a circular green indicator.
             */
            on: {
                type: Boolean,
                required: false,
                default: false,
            },
            linkTo: {
                type: String,
            },
            rowIcon: {
                type: String,
            },
            contained: {
                // If TRUE Removes Padding and Margins on X avis
                type: Boolean,
                default: false,
            },
            action: {
                // Makes the text blue and shows the arrow by default
                type: Boolean,
                default: false,
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            containerClasses: {
                type: [String],
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        methods: {
            handleClick() {
                if (this.linkTo) {
                    window.open(this.linkTo, '_blank')
                } else {
                    this.$emit('onClick')
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/list-row';
</style>
