<template>
    <div>
        <validation-observer
            ref="observer"
            v-slot="{ invalid }"
        >
            <div
                v-if="errorMessage"
                class="alert alert-warning text-start"
            >
                {{ errorMessage }}
            </div>
            <div
                v-if="htmlErrorMessage"
                class="alert alert-warning text-start"
                v-html="htmlErrorMessage"
            />
            <form
                @submit.prevent="$emit('onSubmit', $event)"
                v-bind="$attrs"
            >
                <slot :invalid="invalid" />
            </form>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'

    export default {
        name: 'FormContainer',
        inheritAttrs: false,
        components: {
            'validation-observer': ValidationObserver,
        },
        data() {
            return {
                errorMessage: null,
                htmlErrorMessage: null,
            }
        },
        methods: {
            applyGeneralError: function (errorMessage) {
                this.errorMessage = errorMessage
            },
            applyHtmlError: function (errorMessage) {
                this.htmlErrorMessage = errorMessage
            },
            applyErrorOnField: function (errorObject) {
                this.$refs.observer.setErrors(errorObject)
            },
            validateAll: function () {
                return this.$refs.observer.validate()
            },
            clearErrorMessage: function () {
                this.errorMessage = ''
                this.htmlErrorMessage = ''
            },
        },
    }
</script>
