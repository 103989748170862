<template>
    <card-block>
        <div class="d-flex flex-column">
            <div class="d-flex">
                <div class="d-flex flex-column flex-grow-1 align-content-end">
                    <p class="mb-0 fw-bold">
                        Win {{ maxCreatorChallengeWinAmountText }} - Record a Video
                    </p>
                    <p class="mt-0 mb-0">
                        Show us why you love Aven. Weekly winner gets {{ maxCreatorChallengeWinAmountText }}.
                    </p>
                </div>
                <div class="flex-grow-0 align-self-center">
                    <base-button
                        class="continue-btn ms-2"
                        data-testid="creator-challenge-card-arrow-btn"
                        @click="openCreatorChallengePage"
                    >
                        <lottie-player
                            ref="player"
                            :autoplay="true"
                            :src="animation"
                            :loop="true"
                            :intermission="3000"
                            :speed="1"
                            mode="normal"
                            :style="{ height: '100%', background: '#00000000' }"
                        />
                    </base-button>
                </div>
            </div>
        </div>
    </card-block>
</template>

<script>
    import '@lottiefiles/lottie-player'
    import BaseButton from '@/components/base/BaseButton'
    import CardBlock from '@/components/CardBlock'
    import lottieArrowAnimation from '@/assets/animations/lottie/continue-arrow-loop.json'
    import { toFormattedUSDStripTrailingZeroCents } from '@/mixins/format'
    import { RouteNames } from '@/routes/router.types'

    export default {
        name: 'CreatorChallengeCard',
        components: { CardBlock, BaseButton },
        async mounted() {
            this.$logEvent('creator_challenge_card_view')
        },
        computed: {
            maxCreatorChallengeWinAmountText() {
                // Planning on just posting the amount to the /ops/postCourtesyCreditAmount endpoint so the amount
                // doesnt currently exist anywhere on the backend
                return toFormattedUSDStripTrailingZeroCents(1000)
            },
        },
        methods: {
            openCreatorChallengePage() {
                this.$router.push({
                    name: RouteNames.CREATOR_CHALLENGE,
                })
            },
        },
        data() {
            return {
                animation: JSON.stringify(lottieArrowAnimation),
            }
        },
    }
</script>

<style>
    .continue-btn {
        height: 64px;
        width: 64px;
    }

    .continue-btn button {
        padding: 5px;
    }
</style>
