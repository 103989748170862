import { submitSessionRecordingUrl } from '@/services/api'
import { httpClient } from '@/services/http-client'
import OpenReplayTracker from '@openreplay/tracker'
import { inspect, logger } from './logger'
import OpenReplayAxiosTracker from '@openreplay/tracker-axios'
import trackerVuex from '@openreplay/tracker-vuex'
import { _ActionsTree, _GettersTree, StateTree, Store } from 'pinia'
import { InputMode } from '@openreplay/tracker/lib/modules/input'

class OpenReplay {
    public openReplayTracker: OpenReplayTracker | undefined
    private readonly piniaTrackerPluginFunction: Function | undefined
    private readonly trackedPiniaStores: Set<string> = new Set()

    constructor() {
        try {
            if (process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
                this.openReplayTracker = new OpenReplayTracker({
                    projectKey: process.env.VUE_APP_OPENREPLAY_PROJECT_KEY,
                    ingestPoint: 'https://openreplay.aven.com/ingest',
                    captureIFrames: false, // don't capture very sensitive info
                    obscureInputEmails: false,
                    obscureInputNumbers: false,
                    obscureInputDates: false,
                    obscureTextEmails: false,
                    obscureTextNumbers: false,
                    defaultInputMode: InputMode.Plain,
                })
                this.piniaTrackerPluginFunction = this.openReplayTracker.use(trackerVuex())
            } else {
                console.log(`Skipping OpenReplayTracker instantiation on ${process.env.VUE_APP_NODE_ENV} env`)
            }
        } catch (error) {
            console.error(`OpenReplayTracker failed to instantiate`, null, error)
        }
    }

    public init = async (sessionId: string, userTraits: any) => {
        if (this.openReplayTracker && this.openReplayTracker.isActive()) {
            logger.info(`Tried to initialize openReplay twice in the same session, exiting instead`)
            return
        }

        logger.info(`OpenReplay with sessionId: ${sessionId}`)
        try {
            let sessionRecordingUrl: string
            if (process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
                // Note we're using OpenReplayAxiosTracker here instead of in the constructor because when we
                // construct OpenReplay, httpClient is undefined. OpenReplayAxiosTracker tries to access members
                // of httpClient during its instantiation, so that causes TypeErrors.
                this.openReplayTracker.use(OpenReplayAxiosTracker({ instance: httpClient, ignoreHeaders: ['sessionauthorization'] }))
                await this.openReplayTracker.start({
                    userID: sessionId,
                    sessionHash: sessionId, // enables cross domain linking of sessions
                    metadata: userTraits,
                })
                logger.info(`OpenReplay started`)

                logger.info(`Attempting to get openreplay sessionRecordingUrl for sessionID ${sessionId}`)
                sessionRecordingUrl = this.openReplayTracker.getSessionURL()
            } else {
                logger.info(`Skipping OpenReplayTracker initialization on ${process.env.VUE_APP_NODE_ENV} env`)
                sessionRecordingUrl = 'local dev test openreplay session url'
            }

            if (sessionRecordingUrl) {
                await submitSessionRecordingUrl(sessionId, sessionRecordingUrl)
            } else {
                logger.error(`Could not get session recording url for openreplay`)
            }
        } catch (error) {
            logger.error(`OpenReplayTracker failed to initialize for sessionId: ${sessionId}.`, error)
        }
    }

    public trySetMetadata = (metadata: Record<string, string>) => {
        if (!this.openReplayTracker) {
            logger.error(`Tried to set metadata with uninitialized openreplay exiting instead`)
            return
        }
        try {
            logger.log(`Trying to set metadata on openreplay: ${inspect(metadata)}`)
            for (const [trait, value] of Object.entries(metadata)) {
                this.openReplayTracker.setMetadata(trait, value)
            }
        } catch (error) {
            logger.error(`failed to setMetadata on openreplay: ${inspect(metadata)}.`, error)
        }
    }

    // NOTE: We can't use logger because calls to logger access certain stores, thus causing infinite loops
    public setUpPiniaStoreTracking = <Id extends string = string, S extends StateTree = StateTree, G = _GettersTree<S>, A = _ActionsTree>(store: Store<Id, S, G, A>) => {
        if (this.trackedPiniaStores.has(store.$id)) {
            // Specifically not logging since this log would be pretty spammy and we get logs on error or success
            return
        }
        // Localhost OpenReplay is not enabled, so we won't even try to register for Pinia tracking
        if (process.env.VUE_APP_NODE_ENV === 'development') {
            console.log(`Not tracking Pinia store ${store.$id} in development`)
            this.trackedPiniaStores.add(store.$id)
            return
        }
        if (!this.openReplayTracker) {
            // If OpenReplay.openReplayTracker is nullish, we can't track the Pinia store
            // Note we don't log to logger or console because there are some races between
            // the instantiation/initialization of OpenReplay and use of stores, so it's
            // likely a log would spam the console.
            return
        }
        if (!this.openReplayTracker.isActive()) {
            // OpenReplay.openReplayTracker must be active before trying to track the Pinia store
            // Note we don't log to logger or console because there are some races between
            // the instantiation/initialization of OpenReplay and use of stores, so it's
            // likely a log would spam the console.
            return
        }
        if (!this.piniaTrackerPluginFunction) {
            // OpenReplay.piniaTrackerPluginFunction must be instantiated before trying to track the Pinia store
            // Note we don't log to logger or console because there are some races between
            // the instantiation/initialization of OpenReplay and use of stores, so it's
            // likely a log would spam the console.
            return
        }
        console.log(`Setting up OpenReplay to track Pinia store ${store.$id}`)
        const piniaPlugin = this.piniaTrackerPluginFunction(store.$id)
        piniaPlugin(store)
        this.trackedPiniaStores.add(store.$id)
    }
}

export const openReplay = new OpenReplay()
