<template>
    <header>
        <div class="container">
            <div class="title-group">
                <h4>
                    {{ title }}
                </h4>
                <div
                    v-show="subtitle"
                    class="text-muted"
                >
                    {{ subtitle }}
                </div>
            </div>
            <!-- TODO: Enable mini account details selection -->
            <!--            <div class="d-flex">-->
            <!--                <img-->
            <!--                    src="@/assets/images/global/person-circle.svg"-->
            <!--                    width="24"-->
            <!--                    height="24"-->
            <!--                    @click="$router.push({ path: '/support', query: { label: label } })"-->
            <!--                    @keydown.enter="$router.push({ path: '/support', query: { label: label } })"-->
            <!--                    alt="Help"-->
            <!--                >-->
            <!--            </div>-->
        </div>
    </header>
</template>

<script>
    export default {
        name: 'ProductSelectionHeader',
        props: {
            title: {
                type: String,
                required: true,
            },
            subtitle: {
                type: String,
                required: false,
                default: null,
            },
        },
    }
</script>

<style lang="scss" scoped>
    .container {
        padding: 0;
        display: flex;
        justify-content: space-between;
    }

    h4 {
        margin: 0;
    }
</style>
