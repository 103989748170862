"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentRentVerificationApplicantStatus = exports.CurrentRentVerificationDocument = void 0;
var CurrentRentVerificationDocument;
(function (CurrentRentVerificationDocument) {
    CurrentRentVerificationDocument["BANK_STATEMENT"] = "BANK_STATEMENT";
    CurrentRentVerificationDocument["PROPERTY_MANAGEMENT_STATEMENT"] = "PROPERTY_MANAGEMENT_STATEMENT";
})(CurrentRentVerificationDocument = exports.CurrentRentVerificationDocument || (exports.CurrentRentVerificationDocument = {}));
var CurrentRentVerificationApplicantStatus;
(function (CurrentRentVerificationApplicantStatus) {
    CurrentRentVerificationApplicantStatus["NOT_ELIGIBLE"] = "NOT_ELIGIBLE";
    CurrentRentVerificationApplicantStatus["WAITING_FOR_APPLICANT_TO_UPLOAD_DOCS"] = "WAITING_FOR_APPLICANT_TO_UPLOAD_DOCS";
    CurrentRentVerificationApplicantStatus["WAITING_FOR_DOCS_TO_BE_PROCESSED"] = "WAITING_FOR_DOCS_TO_BE_PROCESSED";
    CurrentRentVerificationApplicantStatus["SUCCESSFULLY_PROCESSED"] = "SUCCESSFULLY_PROCESSED";
})(CurrentRentVerificationApplicantStatus = exports.CurrentRentVerificationApplicantStatus || (exports.CurrentRentVerificationApplicantStatus = {}));
