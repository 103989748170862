"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewSource = void 0;
// Used for /addReview flow in OG (aven.com)
var ReviewSource;
(function (ReviewSource) {
    ReviewSource["trustpilot"] = "trustpilot";
    ReviewSource["google"] = "google";
    ReviewSource["bankrate"] = "bankrate";
    ReviewSource["creditkarma"] = "creditkarma";
})(ReviewSource = exports.ReviewSource || (exports.ReviewSource = {}));
